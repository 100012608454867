<template>
  <Dropdown
    v-model="optionsModel"
    :invalid="props.invalid"
    editable
    @change="handleChange"
    :options="options"
    optionLabel="name"
    optionValue="id"
    :placeholder="props.placeholder"
    dataKey="id"
    class="w-full md:w-14rem"
    :showClear="canClear"
    @show="initInfiniteScroll"
    panelClass="overflow-y-auto scroll-hidden max-h-[300px]"
    :optionDisabled="
      (e) =>
        ((selected.length && selected.find((v) => v?.id === e.id)) ||
          selected.find((v) => v?.name === e.name)) &&
        optionsModel !== e.id
    "
    :panelProps="{
      id: 'dropdown-scroll',
    }"
    :pt="{ emptyMessage: '', list: '' }"
    :loading="loading">
    <template #header="{ value, options }">
      <AddItem
        v-if="value && typeof value === 'string' && !options.length && !loading"
        :value="value"
        :callback="handleAddCustom" />
    </template>
    <template #option="{ option }">
      <div class="flex gap-2 items-center">
        <p class="text-inherit">
          {{ option.name }}
        </p>
      </div>
    </template>
    <template #empty>
      <span></span>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import { vInfiniteScroll } from "@vueuse/components";
import { cn } from "@/utils/cn";
import NoCompanyImage from "@/assets/images/new_icons/company_dummy.svg";
import { useInfiniteScroll } from "@vueuse/core";
import _debounce from "lodash/debounce";
import { API } from "@/core/api";
import { usePage } from "@inertiajs/vue3";
import AddItem from "@/components/general/AddItem.vue";
import type { UserInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
const authStore = useAuthStore();
const SharedDataUser = computed(() => authStore.getUser);
const props = defineProps({
  selected: {
    type: Array,
    default: [],
  },
  placeholder: {
    type: String,
    default: "Select an option",
  },
  icon: {
    type: Object,
    default: null,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  clearCallback: {
    type: Function,
    default: () => {},
  },
  initialValues: {
    type: Array,
    default: null,
  },
  canClear: {
    default: true,
    type: Boolean,
  },
});
const rolesAPI = new API.ClientRoles();

/* ---------- REACTIVE DATA ---------- */
const loading = ref(false);
const model = defineModel();
const optionsModel = ref(
  typeof model.value === "object" ? model.value?.id : model.value,
);
const lazyLoad = reactive({
  start: 0,
  size: 25,
  keyword: "",
  no_more_results: false,
  initialValues: props.initialValues
    ? props.initialValues
    : SharedDataUser.value.roles
        .filter((i) => i.id === model.value?.id)
        .map((i) => ({
          id: i.id,
          name: i.name,
        })),
});
const options = ref([]);

/* ---------- FUNCTIONS ---------- */

// Clear is used when filtering so we clear previous results instead of pushing to them'
const getData = async (clear = false) => {
  if (lazyLoad.no_more_results) return;
  loading.value = true;
  try {
    const { data: response } = await rolesAPI.get(lazyLoad);
    if (response && response.length) {
      options.value = clear ? response : [...options.value, ...response];
      lazyLoad.start = options.value.length;
      lazyLoad.no_more_results = response.length < lazyLoad.size;
    } else {
      options.value = [];
      lazyLoad.no_more_results = true;
    }
    loading.value = false;
  } catch (error) {
    // TODO DECIDE LATER
  }
};
const debouncedGetData = _debounce((clear = false) => getData(clear), 333);

onMounted(() => getData(true));

const filter = (clear: boolean = true) => {
  loading.value = true;
  lazyLoad.no_more_results = false;
  lazyLoad.start = 0;
  if (clear) options.value = [];
  debouncedGetData(true);
};

const handleChange = (e) => {
  const { type } = e.originalEvent;
  const { value } = e;

  if (type === "input") {
    lazyLoad.keyword = value || null;
    if (!value) optionsModel.value = undefined;
    return filter();
  }
  if (type === "click") {
    lazyLoad.keyword = "";
    if (!value) {
      props.clearCallback(model.value);
    } else {
      lazyLoad.initialValues = [options.value.find((i) => i.id === value)];
      filter(false);
    }
  }
};

const initInfiniteScroll = () => {
  useInfiniteScroll(
    document.getElementById("dropdown-scroll"),
    () => getData(),
    {
      distance: 10,
    },
  );
};

const handleAddCustom = async () => {
  const custom = {
    id: -1,
    name: optionsModel.value,
  };
  lazyLoad.keyword = "";
  lazyLoad.initialValues = [custom];
  options.value.unshift(custom);
  model.value = custom;
  optionsModel.value = custom.id;
  filter();
};

/* ---------- WATCHERS ---------- */
watch(optionsModel, (value) => {
  const item = options.value.find((i) => i.id === value);
  if (item) model.value = item;
});

watch(model, (newVal) => {
  if (newVal === undefined) {
    optionsModel.value = undefined;
    filter();
  }
});
</script>

<style>
.dropdown-scroll {
  max-height: 200px;
  overflow-y: auto;
}
</style>
