<template>
  <Chip :label="label" :removable="false" :pt="preset">
    <div class="flex gap-2 text-md items-center">
      <h1 class="text-sm">{{ label }}</h1>

      <i
        v-if="removable"
        @click="$emit('remove')"
        class="fal fa-xmark text-sm cursor-pointer"></i>
    </div>
  </Chip>
</template>

<script lang="ts" setup>
import CustomChipPreset from "@/assets/tailwind-preset/chip/custom-chip";
import { cn } from "@/utils/cn";

const emit = defineEmits(["remove"]);
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  removable: {
    type: Boolean,
    default: false,
  },
  rootClass: {
    type: String,
  },
});

const preset = {
  ...CustomChipPreset,
  root: {
    ...CustomChipPreset.root,
    class: cn(CustomChipPreset.root.class, props.rootClass),
  },
};
</script>
