<template>
  <div class="shadow-default rounded-md">
    <section
      id="header"
      class="border-b border-main-divider bg-lightBg-600/20 py-3">
      <h1 class="text-center font-medium text-lg">Add a service</h1>
    </section>
    <section id="content" class="p-3 flex flex-col gap-4">
      <p class="text-sm text-center">
        You can add as many services as you'd like. Ensure descriptions are
        clear and contain key search terms for optimzal visibility.
      </p>
      <button
        class="py-2 text-white bg-switcherBlue-500 w-1/2 mx-auto rounded-md"
        @click="ManageServiceVisible = true">
        Add a Service
      </button>
    </section>
    <ManageService
      v-if="ManageServiceVisible"
      @close="ManageServiceVisible = false" />
  </div>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import ManageService from "@/components/dialogs/bizcard/services/ManageService.vue";
const ManageServiceVisible = ref(false);
</script>
