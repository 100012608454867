import { useAuthStore } from "@/store/Auth";
import { API } from "@/core/api";
import type {
  BizCardInterface,
  UserInterface,
  SocialPostInterface,
  ContentInterface,
  CollaborationContentInterface,
} from "@/core/interfaces";
import { defineStore } from "pinia";
import { router } from "@inertiajs/vue3";
import { useToastr } from "@/composables/useToastr";
const { success, error } = useToastr();

export enum FeedType {
  POST = "post",
  CONTENT = "content",
}

interface FeedInterface {
  data: SocialPostInterface[] | ContentInterface[];
  lazyLoad: {
    start: number;
    size: number;
    loading: boolean;
    no_more_results: boolean;
    filter?: {
      [key: string]: any;
    };
  };
}
interface State {
  isEditing: boolean;
  bizcard: BizCardInterface | null;
  user: UserInterface | null;
  [FeedType.POST]: FeedInterface;
  [FeedType.CONTENT]: FeedInterface;
  CollaborationContent: {
    loading: Boolean;
    updating: Boolean;
    data: Array<CollaborationContentInterface>;
  };
}

export const useUserBizcardStore = defineStore("userBizcardStore", {
  state: (): State => ({
    isEditing: false,
    bizcard: null,
    user: null,
    [FeedType.POST]: {
      data: [],
      lazyLoad: {
        start: 0,
        size: 10,
        loading: false,
        no_more_results: false,
      },
    },
    [FeedType.CONTENT]: {
      data: [],
      lazyLoad: {
        start: 0,
        size: 10,
        loading: false,
        no_more_results: false,
      },
    },
    CollaborationContent: {
      loading: false,
      updating: false,
      data: [],
    },
  }),
  getters: {
    own_profile: (state) => {
      const authStore = useAuthStore();
      return authStore.user?.id === state.user?.id;
    },
    getIsEditing(state): Boolean {
      return this.own_profile && state.isEditing;
    },
    homeFeed: (state): FeedInterface => state[FeedType.POST],
    portfolioFeed: (state): FeedInterface => state[FeedType.CONTENT],
    featured: (state): ContentInterface[] => {
      return state[FeedType.CONTENT].data.filter(
        (f: any) => f.is_featured,
      ) as ContentInterface[];
    },
    getHiddenCollabContent: (state) => {
      state.CollaborationContent.data.filter((c) => !c?.show_on_portfolio);
    },
    getShownCollabContent: (state) => {
      state.CollaborationContent.data.filter((c) => c?.show_on_portfolio);
    },
  },
  actions: {
    init(bizcard: BizCardInterface, user: UserInterface) {
      const authStore = useAuthStore();
      if (this.user?.id !== user.id) {
        this.resetFeed(FeedType.POST);
        this.resetFeed(FeedType.CONTENT);
      }
      this.bizcard = bizcard;
      this.user = user;

      if (authStore.user?.id === user.id) {
        this.setEditing(true);
      }
    },
    setEditing(isEditing: boolean) {
      this.isEditing = isEditing;
    },
    toggleEditing() {
      this.isEditing = !this.isEditing;
    },
    async loadFeed(
      userId: number,
      type: FeedType = FeedType.POST,
    ): Promise<void> {
      const feed = this[type];
      try {
        if (!feed.lazyLoad.loading && !feed.lazyLoad.no_more_results) {
          const userAPI = new API.Users();
          const bizcardAPI = new API.Bizcard();

          feed.lazyLoad.loading = true;
          const payload = { ...feed.lazyLoad };
          let response =
            type == FeedType.POST
              ? await userAPI.getPosts(userId, payload)
              : await bizcardAPI.getContents(userId, payload);
          if (response.data) {
            feed.data.push(...response.data);
            feed.lazyLoad.start = feed.data.length;
            if (response.data.length < feed.lazyLoad.size)
              feed.lazyLoad.no_more_results = true;
          } else {
            feed.lazyLoad.no_more_results = true;
          }
          Promise.resolve();
        }
      } catch (err) {
        console.error(err);
        Promise.reject(err);
      } finally {
        feed.lazyLoad.loading = false;
        Promise.resolve();
      }
    },
    resetFeed(type: FeedType) {
      this[type] = {
        data: [],
        lazyLoad: {
          start: 0,
          size: 10,
          loading: false,
          no_more_results: false,
        },
      };
    },
    async editPost(
      postId: number,
      payload: {
        title: string;
        is_network_only: boolean;
        mentions: Array<any>;
      },
    ) {
      let item: any = this[FeedType.POST].data.find(
        (item: any) => item.id === postId,
      );
      if (item) {
        item.title = payload.title;
        item.is_network_only = payload.is_network_only;
        item.mentions = payload.mentions;
      }
    },
    editContent(content: any, type: FeedType = FeedType.POST) {
      if (type === FeedType.POST) {
        let item: any = this[type].data.find(
          (item: any) => item.content.id === content.id,
        );
        if (item) item.content = content;
      } else if (type === FeedType.CONTENT) {
        let itemIdx: number = this[type].data.findIndex(
          (item: any) => item.id === content.id,
        );
        if (itemIdx !== -1) this[type].data[itemIdx] = content;
      }
    },
    removePost(postId: number) {
      const postIdx = this[FeedType.POST].data.findIndex(
        (f) => f.id === postId,
      );
      if (postIdx !== -1) this[FeedType.POST].data.splice(postIdx, 1);
    },
    isInitialized(type: FeedType): boolean {
      return this[type].data.length > 0;
    },
    setFilters(type: FeedType, filters: any) {
      this[type].lazyLoad.filter = filters;
    },
    async toggleFeatured(contentId: number, value: boolean) {
      const bizcardAPI = new API.Bizcard();
      const authStore = useAuthStore();
      try {
        const response = await bizcardAPI.toggleFeatured(contentId, value);
        if (response.code === "error_occurred") throw new Error(response.error);
        const content = this[FeedType.CONTENT].data.find(
          (c) => c.id === contentId,
        ) as ContentInterface;
        if (content) {
          content.is_featured = value;
          if (value) authStore.editFeaturedCount("increment");
          else authStore.editFeaturedCount("decrement");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async setCollaborationContent() {
      try {
        if (this.CollaborationContent.data.length) return;
        const bizcardAPI = new API.Bizcard();
        this.CollaborationContent.loading = true;
        let response = await bizcardAPI.getManageCollaborations();
        if (response.error) throw new Error(response.error);
        this.CollaborationContent.data = response.data;
        this.CollaborationContent.loading = false;
      } catch (err) {
        error(err.message);
        this.CollaborationContent.loading = false;
      }
    },
    async toggleShowCollabContent(content: ContentInterface) {
      try {
        const collab = this.CollaborationContent.data.find(
          (c) => c.content.id === content.id,
        ) as CollaborationContentInterface;

        if (collab) {
          const api = new API.Bizcard();
          const action = collab.show_on_portfolio ? "hide" : "show";
          const response = await api.toggleCollaborationContent(
            collab.id,
            action,
          );
          if (response.error) throw new Error(response.error);
          collab.show_on_portfolio = !collab.show_on_portfolio;

          // REMOVE FROM PORTFOLIO FEED
          if (action === "hide") {
            const feedIdx = this[FeedType.CONTENT].data.findIndex(
              (f) => f.id === content.id,
            );
            if (feedIdx !== -1) this[FeedType.CONTENT].data.splice(feedIdx, 1);
            success("Content is now hidden from portfolio");
          } else if (action === "show") {
            this[FeedType.CONTENT].data.unshift(content);
            success("Content is now displayed on portfolio");
          }
        }
      } catch (err) {
        console.error(err);
        error(err.message);
      }
    },
    async removeAsCollaborator(contentId: number) {
      try {
        const collabIdx = this.CollaborationContent.data.findIndex(
          (c) => c.content.id === contentId,
        );
        if (collabIdx !== -1) {
          const api = new API.Bizcard();

          const response = await api.toggleCollaborationContent(
            this.CollaborationContent.data[collabIdx].id,
            "remove",
          );

          if (response.error) throw new Error(response.error);
          // REMOVE FROM LIST
          this.CollaborationContent.data.splice(collabIdx, 1);
          // REMOVE FROM PORTFOLIO FEED
          const feedIdx = this[FeedType.CONTENT].data.findIndex(
            (f) => f.id === contentId,
          );
          if (feedIdx !== -1) this[FeedType.CONTENT].data.splice(feedIdx, 1);
          success("Content is now removed from collaborations");
        }
      } catch (err) {
        console.error(err);
        error(err.message);
      }
    },
    async massUpdateCollaborations() {
      try {
        this.CollaborationContent.updating = true;
        const api = new API.Bizcard();
        const collaborations = this.CollaborationContent.data.map((c) => ({
          id: c.id,
          show_on_portfolio: c.show_on_portfolio,
        }));

        const response = await api.massUpdateCollaborations(collaborations);
        if (response.error) throw new Error(response.error);

        success("Collaboration content updated successfully!");
        this.CollaborationContent.updating = false;
      } catch (err) {
        error(err.message);
      }
    },

    deleteContent(contentId: number) {
      const contentIdx = this.content?.data?.findIndex(
        (c) => c?.id === contentId,
      );
      if (contentIdx && contentIdx !== -1) {
        this.content.data.splice(contentIdx, 1);
      }
    },

    massUpdateFeatured(form: any) {
      [...form.featured, ...form.unfeatured].forEach((feature) => {
        const content = this[FeedType.CONTENT].data.find(
          (c) => c.id === feature.id,
        );
        console.log("CONTENT :: ", content);

        if (content) content.is_featured === feature.is_featured;
      });
    },
  },
});
