<template>
  <div class="flex flex-col w-25rem w-[350px]">
    <div class="bg-lightBg-500 border-b border-b-input-divider rounded-t-md">
      <div class="flex items-center justify-between p-4">
        <div class="flex items-center gap-2">
          <Avatar shape="circle" size="large">
            <template #default>
              <div
                class="size-[50px] rounded-full overflow-hidden cursor-pointer">
                <img
                  :src="user.avatar_image"
                  alt="User Avatar"
                  class="object-cover" />
              </div>
            </template>
          </Avatar>
          <div class="flex flex-col flex-1 gap-0.5">
            <span class="text-primary text-base font-medium leading-none">
              {{ user.full_name }}
            </span>
            <span
              class="flex flex-wrap font-[400] text-main-secondary text-[12px] items-center gap-[5px] rolespan">
              <Roles
                v-if="user?.roles && user?.roles?.length > 0"
                v-memo="[user.roles]"
                :items="user.roles.map((v) => v.name)"
                :fontSize="'12px'"
                :textColor="'#595959'"
                :separatorWidth="'4px'"
                :separatorHeight="'4px'"
                :separatorColor="'#d9d9d9'" />
            </span>
            <span class="text-xs flex gap-2 items-center text-text-500">
              <i class="fal fa-location-dot"></i>
              {{ user.location }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col mx-4 my-2 py-2">
      <div
        class="text-sm font-normal flex gap-[20px] items-left border-b-2 pb-3">
        <div class="flex flex-col">
          <span class="font-[400] text-sm text-primary">
            Make myself discoverable
          </span>
          <span class="text-text-500 text-[10px] md:text-xs">
            When enabled, you'll be clearly visible to others on the main
            network search page.
          </span>
        </div>
        <div class="m-auto">
          <label class="relative inline-flex items-center cursor-pointer h-max">
            <input
              type="checkbox"
              class="sr-only peer"
              v-model="isDiscoverable"
              @change="updateDiscoverable" />
            <div
              class="w-[36px] h-[20px] bg-gray-400 rounded-full peer-focus:ring-none peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-switcherBlue-500 disabled:peer-checked:bg-main-disabled"></div>
          </label>
        </div>
      </div>
    </div>
    <div class="flex flex-col border-b-2 pb-3">
      <div class="text-sm font-normal flex mx-4 justify-between">
        <span class="font-[400] text-sm w-max">Available to work</span>
        <div class="ml-auto">
          <label class="relative inline-flex items-center cursor-pointer h-max">
            <input
              type="checkbox"
              class="sr-only peer"
              v-model="availableToWork"
              @change="updateAvailability" />
            <div
              class="w-[36px] h-[20px] bg-gray-400 rounded-full peer-focus:ring-none peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-switcherBlue-500 disabled:peer-checked:bg-[#BFBFBF]"></div>
          </label>
        </div>
      </div>
    </div>
    <div
      class="cursor-pointer hover:bg-switcherBlue-500/15 py-3"
      :class="{ 'border-b': item.divider }"
      v-for="item in menu"
      :key="item.title"
      @click="item.action">
      <div class="flex items-center gap-2 mx-4">
        <CircleIcon
          size="2rem"
          class="bg-transparent text-primary hover:bg-transparent">
          <i :class="item.icon"></i>
        </CircleIcon>
        <p class="text-sm">{{ item.title }}</p>
      </div>
    </div>
    <div class="!p-4 bg-lightBg-500 rounded-b-md">
      <div class="flex">
        <a
          href="https://bizly.com/terms"
          class="mb-0 text-main-secondary hover:text-switcherBlue-500 text-[13px] flex items-center gap-2">
          Terms &amp; Condition
          <img :src="Bullets1Svg" class="mr-2 !h-[3px] !w-[3px]" />
        </a>
        <a
          href="https://bizly.com/privacy-policy"
          class="mb-0 text-main-secondary hover:text-switcherBlue-500 text-[13px] flex items-center gap-2">
          Privacy Policy
          <img :src="Bullets1Svg" class="mr-2 !h-[3px] !w-[3px]" />
        </a>
        <a
          href="https://bizly.com"
          class="mb-0 text-main-secondary hover:text-switcherBlue-500 text-[13px] flex items-center gap-2">
          Learn More
        </a>
      </div>
      <span class="text-text-500 text-[13px] text-center">
        Biz Technologies © 2024
      </span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch } from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import Bullets1Svg from "@/assets/images/bullets2.svg";
import Roles from "@/components/general/Roles.vue";
import { router, usePage } from "@inertiajs/vue3";
import { computed } from "vue";
import type { UserInterface } from "@/core/interfaces";
import { route } from "ziggy-js";

import { useHomeFeedStore } from "@/store/HomeFeed";
import { useAuthStore } from "@/store/Auth";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";
const authStore = useAuthStore();
const user = computed(() => authStore.getUser as UserInterface);
const page = usePage();

const homeFeedStore = useHomeFeedStore();

const bizCardInfoAPI = new API.BizcardInfo();
const { error, success } = useToastr();

const menu = ref([
  {
    title: "Support",
    icon: "far fa-user-headset",
    action: () => {
      router.visit(route("support"));
    },
    divider: false,
  },
  {
    title: "Invite Your Network",
    icon: "far fa-envelope-open-text",
    action: () => {
      // Open Invite Modal
    },
    divider: true,
  },
  {
    title: "Settings",
    icon: "far fa-cog",
    action: () => {
      router.visit(route("settings.index"));
    },
    divider: true,
  },
  {
    title: "Logout",
    icon: "far fa-right-from-bracket",
    action: () => {
      router.post(
        route("logout"),
        {},
        {
          replace: true,
          onSuccess: () => {
            homeFeedStore.reset();
            authStore.logout();
          },
        },
      );
    },
    divider: false,
  },
]);

const isDiscoverable = ref(user?.value?.is_discoverable ?? false);
const availableToWork = ref(user?.value?.bizcard.available_to_work ?? false);

const updateDiscoverable = async (event: any) => {
  const value = event.target.checked;
  const response = await bizCardInfoAPI.updateDiscoverable({
    is_discoverable: value,
  });
  if (response.error) {
    error(response.error);
  } else {
    success("Discoverable info updated successfully");
    authStore.updateUser({ is_discoverable: value });
  }
};

const updateAvailability = async (event: any) => {
  const value = event.target.checked;
  const response = await bizCardInfoAPI.updateAvailability({
    availability: value,
  });
  if (response.error) {
    error(response.error);
  } else {
    success("Availability updated successfully");
    if (authStore.user) {
      authStore.user.bizcard.available_to_work = value;
    }
  }
};

watch(user, (newVal) => {
  isDiscoverable.value = newVal.is_discoverable;
  availableToWork.value = newVal.bizcard.available_to_work;
});
</script>
