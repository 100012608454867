<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full flex flex-col relative',
      root: '!bg-white max-w-[700px] w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section
      id="header"
      class="p-2 bg-surface-50 border-b-[1px] border-surface-300 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">Bio</h1>
        <CircleIcon
          class="bg-surface-100 text-primary-500 hover:bg-surface-200"
          @click="$emit('close')">
          <i class="far fa-times"></i>
        </CircleIcon>
      </div>
    </section>
    <section id="content" class="px-4 py-2 flex flex-col gap-2 max-h-[80vh]">
      <div class="overflow-auto scroll-hidden basis-[90%]">
        <Textarea
          v-model="form.about"
          rows="2"
          :autoResize="true"
          placeholder="Tell us about yourself"
          class="shadow-inner resize-none scroll-hidden !bg-transparent !border-0 rounded text-surface-500 text-base font-normal py-2 pr-14 pl-4 w-full focus:outline-0 focus:ring-0 focus:border-[1px]" />
      </div>
    </section>
    <section
      id="footer"
      class="bg-surface-50 border-t border-surface-300 sticky bottom-0 z-20 rounded-b-[16px]">
      <div class="flex justify-end items-center py-2.5 px-4 gap-2">
        <Button
          label="Save"
          :disabled="!form.isDirty"
          :loading="form.processing"
          class="min-w-[150px]"
          @click="handleSave"></Button>
      </div>
    </section>
  </Dialog>
</template>

<script lang="ts" setup>
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { router, useForm } from "@inertiajs/vue3";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";

/* -------- PROPS -------- */
const props = defineProps({
  about: {
    required: true,
    type: String,
  },
});
/* -------- COMPOSABLE -------- */
const bizCardInfoAPI = new API.BizcardInfo();
const { success, error } = useToastr();

/* -------- STATE -------- */
const form = useForm({
  about: props.about,
});
const emit = defineEmits(["close"]);
/* -------- METHODS -------- */

const handleSave = async () => {
  form.processing = true;
  try {
    const response = await bizCardInfoAPI.updateBio(form);

    if (response.code === "bio_updated") {
      success("Bio updated successfully");
      router.reload();
      emit("close");
    } else if (response.error) {
      error(response.error);
    } else {
      error("Failed to update bio: Unknown error");
    }
  } catch (e: any) {
    console.error(e);
  } finally {
    form.processing = false;
  }
};
/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
