<template>
  <Swiper
    :key="featured.length"
    v-if="featured.length"
    :spaceBetween="0"
    :slidesPerView="1"
    :pagination="{
      enabled: true,
      clickable: true,
    }"
    :modules="[Thumbs, FreeMode, Scrollbar, Pagination, Navigation]"
    :scrollbar="true"
    :freemode="true"
    :mousewheel="false"
    :loop="featured.length > 1"
    :navigation="true"
    class="h-full min-[1921px]:h-[850px] w-full max-w-[1920px] link_child_swiper_portfolio !scroll-hidden min-[1921px]:rounded-b-[16px]">
    <swiper-slide
      v-for="(child, index) in featured"
      :key="child.id"
      class="content_child">
      <div class="h-screen min-[1921px]:h-[850px] relative image-container">
        <img
          :src="backgroundImage(child)"
          class="w-full h-screen min-[1921px]:h-[850px] object-cover"
          @error="handleImageError" />
      </div>
      <div class="absolute top-16 right-8">
        <CircleIcon
          v-if="is_editing"
          size="2.5rem"
          class="bg-black/10 hover:bg-black/30"
          @click="(e) => handleManageMenu(e, index)">
          <i class="fa fa-ellipsis-v text-white text-xl"></i>
        </CircleIcon>
        <ManageContent
          :content="child"
          ref="manageMenu"
          @updateContent="handleUpdateContent"></ManageContent>
      </div>
      <div class="absolute top-12 left-2">
        <InfoBallon :type="child.content_type"></InfoBallon>
      </div>
      <div
        class="absolute bottom-[60px] left-[78px] flex flex-col min-w-[200px] max-w-[650px] gap-[16px]">
        <div>
          <Button
            label="View Content"
            @click="() => handleViewContent(child)"
            class="bg-white w-[200px] !text-black font-medium hover:!text-white hover:!bg-switcherBlue-600 border-0" />
        </div>

        <div
          class="flex flex-col"
          :class="!child.project_types.length ? 'gap-[4px]' : 'gap-[8px]'">
          <div
            class="flex flex-col"
            :class="!child.project_types.length ? 'gap-[4px]' : 'gap-[8px]'">
            <span class="text-white font-[700] text-[24px] titleClass">
              {{ child.title }}
            </span>
            <div class="flex flex-wrap">
              <ul
                class="m-0 content-list project-type-list !pr-[0.5rem] flex gap-[0.5rem]"
                v-if="child.project_types?.length">
                <template v-for="(item, index) in child.project_types">
                  <li
                    v-if="index < 1"
                    :key="index"
                    class="text-[0.875rem] text-[#fff] m-0 opacity-70">
                    {{ item.title }}
                  </li>
                  <li
                    v-else-if="index === 1"
                    class="text-[0.875rem] text-[#fff] m-0 opacity-70"
                    key="more">
                    +{{ child.project_types.length - 1 }}
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <span class="text-white text-[16px] font-[400] twoLineTextClass">
            {{ child.description }}
          </span>
        </div>
      </div>
    </swiper-slide>
  </Swiper>
</template>
<script setup lang="ts">
import type { UserInterface } from "@/core/interfaces";
import type { PropType } from "vue";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { computed } from "vue";
import type { ContentInterface } from "@/core/interfaces/Content";
import {
  Navigation,
  Scrollbar,
  Pagination,
  FreeMode,
  Thumbs,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { route } from "ziggy-js";
import { router } from "@inertiajs/vue3";
import { type ContentCategoryInterface } from "@/core/interfaces";
import { ref } from "vue";
import InfoBallon from "@/components/parts/InfoBallon.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import ManageContent from "@/components/menus/ManageContent.vue";

const props = defineProps({
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
  category: {
    type: Object as PropType<ContentCategoryInterface | null>,
    default: null,
  },
});

/** --------- STORES --------- */
const bizCardStore = useUserBizcardStore();

/** --------- STATIC ---------- */

/** --------- REACTIVE -------- */
const is_editing = computed(() => bizCardStore.getIsEditing);
const manageMenu = ref();
const featured = computed<ContentInterface[]>(() => {
  if (props.category == null || props.category == undefined) {
    return bizCardStore.featured;
  } else {
    return bizCardStore.featured.filter((item) =>
      item.categories.map((v) => v.id).includes(props.category!.id),
    );
  }
});

/** --------- METHODS -------- */
const backgroundImage = (item: ContentInterface) => {
  return (
    item.image_kit_id_16_9 ??
    item.thumbnail_image_kit_id ??
    item.image_kit_ids?.[0] ??
    item.image_kit_id
  );
};
const handleImageError = (event: any) => {
  event.target.src =
    "https://storage.googleapis.com/demo-api.bizly.net/static/app-templates/web-bg-min.jpg";
};
const handleViewContent = (item: ContentInterface) => {
  router.visit(
    route("bizcards.content.get", {
      content: item.id,
    }),
  );
};
const handleManageMenu = (event: any, index: number) => {
  const dropdown = manageMenu.value.at(index);
  if (dropdown) {
    dropdown.toggle(event);
  }
};

const handleUpdateContent = (content: ContentInterface) => {
  // update content after edit
  console.log("content updated :: ", content);
};
/** --------- WATCHERS -------- */

/** --------- LIFECYCLE HOOKS -------- */
</script>
<style lang="scss">
@import "swiper/css/free-mode";
@import "swiper/css/navigation";
@import "swiper/css/scrollbar";
@import "swiper/css/thumbs";

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: radial-gradient(
      54.96% 65.87% at 16.9% 100%,
      rgba(0, 0, 0, 0.8) 21.9%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      15.7% 24.56% at 100% 0%,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 100%
    );
}
</style>
