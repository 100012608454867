<template>
  <component
    :is="dialog.component"
    v-for="dialog in dialogsList"
    :key="dialog.component"
    v-bind="dialog.props"
    v-on="dialog.events ?? {}" />
</template>
<script setup lang="ts">
import { useDialogStore } from "@/store/Dialog";
import { computed } from "vue";
const dialogStore = useDialogStore();
const dialogsList = computed(() => dialogStore.getActiveDialogs);
</script>
