<template>
  <InfoCard v-if="(is_own_profile && card.tags.length) || is_editing">
    <template #title>
      <div class="flex justify-between items-center">
        <h3 class="text-lg font-medium">Keywords</h3>
      </div>
    </template>
    <template #subtitle>
      <div class="flex flex-col gap-4">
        <span class="text-[14px] text-main-secondary">
          These will remain confidential and are used to optimize your
          visibility in search results.
        </span>
        <span class="text-[12px] text-primary-500">
          You have used
          <span>
            <b>{{ card.tags.length }}/10</b>
          </span>
          Keywords
        </span>
      </div>
    </template>
    <template #content>
      <div class="flex flex-wrap gap-2">
        <div
          class="flex items-center gap-1 w-full"
          v-if="is_editing && card.tags.length < 10">
          <IconField
            iconPosition="right"
            class="relative w-11/12 bg-primary-50 rounded-md">
            <i
              @click="handleSave"
              class="far fa-circle-plus text-surface-400 cursor-pointer"
              :class="{
                '!text-switcherBlue-500': keyword && trim(keyword) !== '',
                'pointer-events-none': !keyword || trim(keyword) === '',
              }"></i>
            <InputText
              maxlength="50"
              @keyup.enter="handleSave"
              v-model="keyword"
              placeholder="Add a keyword"
              class="bg-white/10 w-full focus:!outline-none focus:!border-0 focus:!ring-0 hidden md:block transition-all duration-150 ease-in-out text-black" />
          </IconField>
          <p
            class="text-xs text-black"
            :class="{ 'text-red-500': keyword?.length === 50 }">
            {{ keyword?.length ?? 0 }}/50
          </p>
        </div>
        <CustomChip
          v-for="(value, index) in card.tags"
          :key="value"
          :label="value"
          :removable="is_editing && is_own_profile"
          @remove="() => handleRemove(index)"
          rootClass="text-primary-500 bg-primary-50" />
      </div>
    </template>
  </InfoCard>
</template>

<script lang="ts" setup>
import {
  reactive,
  ref,
  computed,
  onMounted,
  watch,
  PropType,
  mergeProps,
} from "vue";
import InfoCard from "@/components/general/CustomCard.vue";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import CustomChip from "@/components/general/CustomChip.vue";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { API } from "@/core/api";
import { cn } from "@/utils/cn";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { trim } from "lodash";

const BizcardInfoAPI = new API.BizcardInfo();
/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});

/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();

/* -------- STATE -------- */
const is_editing = computed(() => bizcardStore.getIsEditing);
const is_own_profile = computed(() => bizcardStore.own_profile);

const is_adding = ref(false);
const keyword = ref(null);
/* -------- METHODS -------- */
const handleSave = async () => {
  if (!keyword.value || trim(keyword.value) === "") return;
  props.card.tags.push(trim(keyword.value));
  SendData();
  if (props.card.tags.length >= 10) is_adding.value = false;
};
const handleRemove = (idx) => {
  props.card.tags.splice(idx, 1);
  SendData();
};
const SendData = async () => {
  let response;
  try {
    response = await BizcardInfoAPI.updateKeywords({
      keywords: props.card.tags,
    });
    if (response.error) throw new Error("An error has occurred");
    keyword.value = null;
  } catch (error) {
    console.error(response.error);
  }
};
const handleCancel = () => {
  keyword.value = null;
  is_adding.value = false;
};
/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
