<template>
  <CustomCard :pt="cardPt" customClass="relative">
    <template #title>
      <div class="flex justify-between">
        <span class="font-semibold text-lg">Additional Locations</span>
        <div class="flex gap-2" v-if="form.isDirty">
          <div v-tooltip.top="'Cancel'" @click="form.reset()">
            <i class="fa fa-times text-lg cursor-pointer text-primary-200"></i>
          </div>
          <div v-tooltip.top="'Save'" @click="handleSave">
            <i class="fa fa-check text-lg cursor-pointer text-primary-500"></i>
            <div
              v-if="form.processing"
              class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center bg-white bg-opacity-50 w-full h-full z-50">
              <i class="fas fa-spinner-third fa-spin text-primary-500"></i>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #subtitle>
      <span class="text-sm text-main-secondary">
        Do you have any other locations that you are able to travel to or
        service remotely?
      </span>
    </template>
    <template #content>
      <div class="flex flex-col gap-3">
        <span class="text-sm text-primary-500">
          You have selected
          <b>{{ form.locations.length }}/10</b>
          locations.
        </span>
        <CustomCard
          v-if="isEditing && canAdd"
          customClass="px-2 py-1 !w-full bg-primary-50 rounded-lg"
          :pt="{
            root: '!shadow-none',
          }">
          <template #content>
            <div class="flex justify-between items-center gap-2">
              <LocationSelectGroup
                :pt="{
                  root: '!border-none !border-0 !bg-transparent',
                  input: '!text-primary-500 placeholder:!text-primary-200',
                }"
                v-model="keyword"
                placeholder="Add Location"
                showClear
                :clearCallback="() => handleClear()"
                :selected="form.locations" />
              <CircleIcon
                :class="
                  cn('ms-auto', {
                    'bg-surface-100 text-primary-500 hover:bg-surface-100 cursor-not-allowed':
                      !keyword,
                    'bg-primary-400 text-white hover:bg-primary-600':
                      keyword !== undefined,
                  })
                "
                size="1.5rem"
                @click="handleAdd">
                <i class="far fa-plus text-xs"></i>
              </CircleIcon>
            </div>
          </template>
        </CustomCard>
        <div class="flex flex-wrap gap-2">
          <CustomChip
            v-for="(value, index) in form.locations"
            :key="value"
            :label="value"
            :removable="isEditing"
            @remove="() => handleRemove(index)"
            rootClass="text-primary-500 bg-primary-50" />
        </div>
      </div>
    </template>
  </CustomCard>
</template>

<script lang="ts" setup>
import CustomCard from "@/components/general/CustomCard.vue";
import CustomChip from "@/components/general/CustomChip.vue";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import { useUserBizcardStore } from "@/store/UserBizcard";
import type { PropType } from "vue";
import { computed } from "vue";
import { ref } from "vue";
import LocationSelectGroup from "@/components/forms/SelectGroups/Location.vue";
import { cn } from "@/utils/cn";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { router, useForm } from "@inertiajs/vue3";
import { useToastr } from "@/composables/useToastr";
import { API } from "@/core/api";
/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});

/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();
const bizCardServicesAPI = new API.BizcardServices();
const { success, error } = useToastr();

/* -------- STATE -------- */
const isEditing = computed(() => bizcardStore.getIsEditing);
const keyword = ref();

const form = useForm({
  locations: props.card.services_locations,
});

const canAdd = computed(() => form.locations.length < 10);

const cardPt = computed(() => ({
  root: {
    "border border-primary-200": form.isDirty,
  },
}));
/* -------- METHODS -------- */
const handleAdd = async () => {
  if (keyword.value === undefined || keyword.value === "") return;
  form.locations.push(keyword.value);
  keyword.value = undefined;
};
const handleRemove = (idx: number) => {
  form.locations.splice(idx, 1);
};

const handleClear = () => {
  keyword.value = undefined;
};

const handleSave = async () => {
  if (form.processing) return;
  form.processing = true;
  try {
    const response = await bizCardServicesAPI.updateLocations(form);
    if (response.error) {
      error(response.error);
    } else {
      success("Locations updated successfully");
      form.defaults();
      form.reset();
      router.reload();
    }
  } catch (e: any) {
    console.error(e);
    error("Error: " + e.message);
  } finally {
    form.processing = false;
  }
};
/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
