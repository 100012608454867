<template>
  <div class="flex flex-col w-[400px] max-h-[80vh]">
    <div class="bg-lightBg-500 border-b border-b-input-divider rounded-t-md">
      <div class="flex gap-[0.75rem] items-center p-4">
        <h2 class="text-[1rem] font-[600] text-main-primary m-0">Notifications</h2>
        <span
          class="flex items-center justify-center w-[1.5rem] h-[1.5rem] bg-switcherBlue-500 rounded-[50%] text-[0.75rem] text-white font-[500]"
          v-if="totalCount > 0"
        >
          {{ totalCount }}
        </span>
        <button
          @click="notificationStore.readAll"
          class="ms-auto text-[0.75rem] font-[500] text-switcherBlue-500"
          v-if="totalCount > 0"
        >
          Mark all as read
        </button>
      </div>
    </div>
    <div
      v-if="
        notificationStore.lazyload.isLoading && notificationStore.lazyload.start === 0
      "
      class="shadow-inner max-h-[80vh] overflow-y-auto scroll-hidden flex flex-col gap-2"
    >
      <div
        class="flex gap-4 items-center p-2 border-b border-b-main-divider/50"
        v-for="i in 4"
      >
        <Skeleton shape="circle" size="5rem"></Skeleton>
        <div class="flex flex-col gap-2 flex-1">
          <Skeleton></Skeleton>
          <Skeleton width="5rem"></Skeleton>
          <Skeleton width="10rem" height="2rem"></Skeleton>
        </div>
      </div>
    </div>
    <div
      v-else
      class="shadow-inner max-h-[80vh] overflow-y-auto scroll-hidden"
      v-infinite-scroll="notificationStore.getNotifications"
    >
      <div
        class="p-2 border-y-divider border-y-[0.0625rem] sticky top-0 bg-white z-[10]"
        v-if="unread.length"
      >
        <h2 class="text-[0.875rem] text-main-primary m-0 font-[600]">New</h2>
      </div>
      <NotificationComponent
        v-for="(notification, index) in unread"
        :key="notification.id"
        :notification="notification"
        v-bind="getNotificationProps(notification.notification_type)"
        class="bg-primary-50"
      ></NotificationComponent>
      <div
        class="p-2 border-y-divider border-y-[0.0625rem] sticky top-0 bg-white z-[10]"
        v-if="read.length"
      >
        <h2 class="text-[0.875rem] text-main-primary m-0 font-[600]">Old</h2>
      </div>
      <NotificationComponent
        v-for="(notification, index) in read"
        :key="notification.id"
        :notification="notification"
        v-bind="getNotificationProps(notification.notification_type)"
      ></NotificationComponent>
    </div>
    <div
      class="flex items-center justify-center h-[400px]"
      v-if="
        unread.length === 0 && read.length === 0 && !notificationStore.lazyload.isLoading
      "
    >
      <div class="flex flex-col gap-2 items-center justify-center">
        <img :src="bellIcon" alt="" class="size-48 mx-auto" />
        <h1 class="font-semibold text-base">No Notifications here</h1>
        <div class="text-sm">There is no notification to show right now</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NotificationType } from "@/core/enums/NotificationType";
import { router, useForm } from "@inertiajs/vue3";
import { computed, onMounted, reactive, ref } from "vue";
import { route } from "ziggy-js";
import NotificationComponent from "@/components/notifications/Notification.vue";
import { type NotificationInterface } from "@/core/interfaces";
import { vInfiniteScroll } from "@vueuse/components";
import { useToastr } from "@/composables/useToastr";
import bellIcon from "@/assets/images/Bell-icon.svg";
import { useNotificationStore } from "@/store/Notifications";
const notificationStore = useNotificationStore();
const { error, success } = useToastr();

const emit = defineEmits(["read-all"]);
const props = defineProps({
  count: {
    type: Number,
    required: true,
  },
});

const unread = computed(() => notificationStore.unread);
const read = computed(() => notificationStore.read);
const totalCount = computed(() => notificationStore.unread.length);

const types: Record<NotificationType, any> = {
  [NotificationType.Unknown]: {},
  [NotificationType.CollaborationRequest]: {
    content: true,
    acceptCallback: (i: number) =>
      notificationStore.handleCollabRequest(i, "accept"),
    declineCallback: (i: number) =>
      notificationStore.handleCollabRequest(i, "decline"),
    onClick: (notification) =>
      router.visit(route("bizcards.content.get", notification.object.id)), //TODO:: Change this to the correct route
    showContent: true,
  },
  [NotificationType.CollaborationAccepted]: {
    content: true,
    onClick: (notification) =>
      router.visit(route("bizcards.content.get", notification.object.id)),
    showContent: true,
  },
  [NotificationType.CollaborationDeclined]: {
    content: true,
    onClick: (notification) =>
      router.visit(route("bizcards.content.get", notification.object.id)),
    showContent: true,
  },
  [NotificationType.OwnerCollaborationAccepted]: {
    content: true,
    onClick: (notification) =>
      router.visit(route("bizcards.content.get", notification.object.id)),
    showContent: true,
  },
  [NotificationType.ConnectionRequest]: {
    content: true,
    acceptCallback: async (notificationId: number) => {
      notificationStore.handleConnectionRequest(notificationId, "accept");
    },
    declineCallback: (notificationId: number) => {
      notificationStore.handleConnectionRequest(notificationId, "decline");
    },
    onClick: (notification) => {
      router.visit(
        route("user.profile.index", notification.sender.public_username),
      );
    },
  },
  [NotificationType.ConnectionAccepted]: {
    content: false,
    onClick: (notification) =>
      router.visit(
        route("user.profile.index", notification.sender.public_username),
      ),
  },
  [NotificationType.OwnerConnectionAccepted]: {
    content: false,
    onClick: (notification) =>
      router.visit(
        route("user.profile.index", notification.object.public_username),
      ),
  },
  [NotificationType.SocialPostMention]: {
    content: true,
    onClick: (notification) =>
      router.visit(route("social_posts.index", notification.object.id)),
    showContent: true,
  },
  [NotificationType.SocialPostLike]: {
    content: true,
    onClick: (notification) =>
      router.visit(route("bizcards.content.get", notification.object.id)),
    showContent: true,
  },
  [NotificationType.SocialPostComment]: {
    content: true,
    onClick: (notification) =>
      router.visit(route("bizcards.content.get", notification.object.id)),
    showContent: true,
  },
  [NotificationType.SocialPostCommentMention]: {
    content: false,
    onClick: (notification) =>
      router.visit(route("social_posts.index", notification.object?.social_post?.id)),
  },
  [NotificationType.SocialPostContentMention]: {
    content: true,
    onClick: (notification) =>
      router.visit(route("bizcards.content.get", notification.object.id)),
    showContent: true,
  },
  [NotificationType.NewContentUploaded]: {
    content: true,
    onClick: (notification) =>
      router.visit(route("bizcards.content.get", notification.object.id)),
    showContent: true,
  },
};

const getNotificationProps = (notificationType: NotificationType) => {
  const typeConfig = types[notificationType] || {};
  return typeConfig;
};
</script>
