<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full flex flex-col relative',
      root: '!bg-white max-w-[550px] w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section
      id="header"
      class="p-2 bg-surface-50 border-b-[1px] border-surface-300 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">Personal Info</h1>
        <CircleIcon
          class="bg-surface-100 text-primary-500 hover:bg-surface-200"
          @click="$emit('close')">
          <i class="far fa-times"></i>
        </CircleIcon>
      </div>
    </section>
    <section id="content" class="px-6 py-4 flex flex-col gap-2 max-h-[80vh]">
      <div class="overflow-auto scroll-hidden basis-[90%] flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <span class="text-lg font-medium">Personal</span>

          <div class="w-full flex flex-col gap-2">
            <CountryInput
              class="!w-full sm:w-[400px]"
              @isValid="(v) => (form.personal.phoneNumberValid = v)"
              :initValue="form.personal.phone"
              :errors="validateStore.errors"
              errorName="phone"
              @getPhoneValue="
                (v) => {
                  form.personal.phone = v.number;
                }
              " />
            <InputText
              v-model="form.personal.email"
              placeholder="Email"
              class="w-full"
              :invalid="!isValidPersonalEmail" />
            <p class="text-xs text-red-500" v-if="!isValidPersonalEmail">
              This is not a valid email
            </p>
          </div>
          <div class="flex justify-between">
            <div class="flex flex-col gap-1">
              <span class="text-sm font-medium text-main-lightBg">
                Make visible to public
              </span>
              <div
                class="flex gap-1"
                v-if="!form.personal.visible_to_out_of_network">
                <i class="fal fa-eye-slash text-main-secondary text-xs"></i>
                <span class="text-main-secondary text-[10px] md:text-xs">
                  This info is hidden to people outside of your network.
                </span>
              </div>
            </div>
            <label
              class="relative inline-flex items-center cursor-pointer h-max ms-auto">
              <input
                type="checkbox"
                class="sr-only peer"
                v-model="form.personal.visible_to_out_of_network" />
              <div
                class="w-[36px] h-[20px] bg-gray-400 rounded-full peer-focus:ring-none peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-main-success disabled:peer-checked:bg-main-disabled"></div>
            </label>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <span class="text-lg font-medium">Office</span>
          <CompaniesSelectGroup
            v-model="form.office.company"
            placeholder="Company / Organization*"
            :clearCallback="() => (form.office.company = undefined)" />
          <CountryInput
            class="!w-full sm:w-[400px]"
            @isValid="(v) => (form.office.phoneNumberValid = v)"
            :initValue="form.office.phone"
            :errors="validateStore.errors"
            errorName="phone"
            @getPhoneValue="
              (v) => {
                form.office.phone = v.number;
              }
            " />
          <InputText
            v-model="form.office.email"
            placeholder="Email"
            :invalid="!isValidOfficeEmail" />
          <p class="text-xs text-red-500" v-if="!isValidOfficeEmail">
            This is not a valid email
          </p>
          <LocationSelectGroup
            v-model="form.office.location"
            :showClear="true"
            :clearCallback="() => (form.office.location = undefined)" />
        </div>
      </div>
    </section>
    <section
      id="footer"
      class="bg-surface-50 border-t border-surface-300 sticky bottom-0 z-20 rounded-b-[16px]">
      <div class="flex justify-end items-center py-2.5 px-4 gap-2">
        <Button
          label="Save"
          :disabled="!canSave"
          :loading="form.processing"
          class="min-w-[150px]"
          @click="handleSave"></Button>
      </div>
    </section>
  </Dialog>
</template>

<script lang="ts" setup>
import { router, useForm } from "@inertiajs/vue3";
import type { PropType } from "vue";
import type { ContactInfoInterface } from "@/core/interfaces";
import { useValidateStore } from "@/store/Validate";
import _debounce from "lodash/debounce";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import CountryInput from "@/components/forms/countryInput.vue";
import CompaniesSelectGroup from "@/components/forms/SelectGroups/Companies.vue";
import LocationSelectGroup from "@/components/forms/SelectGroups/Location.vue";
import { computed } from "vue";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";
import _ from "lodash";
/* -------- PROPS -------- */
const props = defineProps({
  contact: {
    type: Array as PropType<ContactInfoInterface[]>,
    required: true,
  },
});
/* -------- COMPOSABLE -------- */
const validateStore = useValidateStore();
const bizCardInfoAPI = new API.BizcardInfo();
const { success, error } = useToastr();

/* -------- STATE -------- */
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const isValidPersonalEmail = computed(() =>
  !form.personal.email || form.personal.email === ""
    ? true
    : emailRegex.test(form.personal.email),
);
const isValidOfficeEmail = computed(() =>
  !form.office.email || form.office.email === ""
    ? true
    : emailRegex.test(form.office.email),
);
const emit = defineEmits(["close"]);
// Example usage:
const form = useForm<{
  personal: ContactInfoInterface;
  office: ContactInfoInterface;
}>({
  personal: props.contact.find((c) => c.type === "personal") || {
    type: "personal",
    visible_to_out_of_network: false,
  },
  office: props.contact.find((c) => c.type === "office") || {
    type: "office",
  },
});
const canSave = computed(
  () =>
    form.isDirty &&
    !form.processing &&
    !validateStore.hasErrors &&
    ((form.personal.visible_to_out_of_network &&
      form.personal.phoneNumberValid === true &&
      form.personal.email) ||
      !form.personal.visible_to_out_of_network) &&
    (form.office.phoneNumberValid === true ||
      form.office.phoneNumberValid === undefined) &&
    isValidOfficeEmail.value &&
    isValidPersonalEmail.value,
);

/* -------- METHODS -------- */

const handleSave = async () => {
  form.processing = true;
  try {
    const response = await bizCardInfoAPI.updateContact(form);
    if (response.data) {
      success("Personal Info updated successfully");
      form.isDirty = false;
      router.reload();
      emit("close");
    } else if (response.error) {
      error(response.error);
    } else {
      error("Failed to update personal info: Unknown error");
    }
  } catch (e: any) {
    console.error(e);
  } finally {
    form.processing = false;
  }
};
/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
