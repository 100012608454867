<template>
  <Menubar
    :model="menu"
    class="h-10 shadow-lg p-4 flex-1"
    breakpoint="1920px"
    :pt="{
      start: 'pr-4',
    }">
    <template #start>
      <bizlyLogoGray />
    </template>
    <template #item="{ item, props }">
      <div
        :class="
          cn(
            'text-sm align-items-center text-main-lightGray hover:text-white hidden lg:flex font-normal',
            item.class,
          )
        ">
        <a v-bind="props.action">{{ item.label }}</a>
      </div>
    </template>
    <template #end>
      <div class="flex items-center gap-4 flex-1 justify-end">
        <NotificationHeader badgeType="dot" />
        <AvatarHeader />
      </div>
    </template>
  </Menubar>
</template>
<script setup lang="ts">
import bizlyLogoGray from "@/assets/images/bizly/bizly_grey.vue";
import AvatarHeader from "@/components/layout/parts/AvatarHeader.vue";
import NotificationHeader from "@/components/layout/parts/NotificationHeader.vue";
import { cn } from "@/utils/cn";
import { router } from "@inertiajs/vue3";
import { ref } from "vue";
import { route } from "ziggy-js";
/** HEADER MENU */
const classes = {
  active: "text-white border-b-white",
  inactive: "border-b-main-lightBg",
};

const isActive = (name: String) => {
  return route().current()?.startsWith(name.toLowerCase());
};

const menu = ref([
  {
    label: "Home",
    class: isActive("home") ? classes.active : classes.inactive,
    command: () => {
      router.visit(route("home"));
    },
  },
  {
    label: "Contacts",
    class: isActive("contacts") ? classes.active : classes.inactive,
    command: () => {
      router.visit(route("contacts.index"));
    },
  },
  {
    label: "Explore",
    class: isActive("explore") ? classes.active : classes.inactive,
    command: () => {
      router.visit(route("explore.index"));
    },
  },
  {
    label: "Opportunities",
    class: "opacity-50 hover:text-main-lightGray !cursor-default",
    command: () => {},
  },
]);
</script>
