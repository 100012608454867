<template>
  <Card
    class="max-h-[370px] lg:h-[100%] lg:min-h-[118px] lg:max-h-[100vh] overscroll-contain overflow-y-auto custom-scrollbar scroll-hidden pb-[0] rounded-[10px] relative"
    v-if="users.length">
    <template #content>
      <div
        class="mb-2 text-base text-primary font-[500] px-[20px] py-3 mt-0.5 sticky top-0 bg-white z-50 border-b-[0.5px] border-b-disabled-500">
        Start networking
      </div>
      <div
        class="gap-2.5 cursor-pointer m-0 px-[20px] pb-2"
        v-for="user in users"
        :key="user.id">
        <UserCard :user="user" />
        <hr
          class="text-main-divider opacity-[1] my-[5px]"
          v-if="users?.at(-1) !== user" />
      </div>
    </template>
  </Card>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { type UserInterface } from "@/core/interfaces";
import UserCard from "@/components/general/UserCard.vue";

import { useStore } from "@/store";
const store = useStore();
const users = computed<UserInterface[]>(() => store.newToBizlyUsers ?? []);
</script>
