<template>
  <CustomCard>
    <template #title>
      <div class="flex justify-between items-center">
        <span class="font-semibold text-lg">General</span>
        <div
          class="flex gap-2 items-center justify-center bg-primaryBlue-500 text-white text-sm rounded-full py-2 px-4"
          v-if="availableToWork">
          <div class="size-2 rounded-full bg-green-500"></div>
          <p>Available</p>
        </div>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col gap-4">
        <div class="space-y-2" v-if="locations.length">
          <p class="text-main-lightGray text-sm font-medium">Locations</p>
          <div class="flex flex-wrap gap-2">
            <CustomChip
              v-for="value in locations"
              :key="value"
              :label="value"
              rootClass="text-primary-500 bg-primary-50" />
          </div>
        </div>
        <div class="space-y-2" v-if="opportunityInterests.length">
          <p class="text-main-lightGray text-sm font-medium">
            Opportunity Interests
          </p>
          <div class="flex flex-wrap gap-2">
            <CustomChip
              v-for="value in opportunityInterests"
              :key="value"
              :label="value"
              rootClass="text-primary-500 bg-primary-50" />
          </div>
        </div>
        <div class="space-y-2" v-if="workplace">
          <p class="text-main-lightGray text-sm font-medium">Workplace</p>
          <div class="flex flex-wrap gap-2">
            <CustomChip
              :label="workplace"
              rootClass="text-primary-500 bg-primary-50" />
          </div>
        </div>
      </div>
    </template>
  </CustomCard>
</template>

<script lang="ts" setup>
import CustomCard from "@/components/general/CustomCard.vue";
import CustomChip from "@/components/general/CustomChip.vue";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { computed, ref, type PropType } from "vue";
/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});

/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();

/* -------- STATE -------- */
const isEditing = computed(() => bizcardStore.getIsEditing);
const availableToWork = computed(() => props.card.available_to_work);
const locations = computed(() => props.card.services_locations);
const opportunityInterests = computed(() => props.card.opportunity_settings);
const workplace = computed(() => props.card.workplace_settings[0] ?? null);
/* -------- METHODS -------- */

/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
