import { API } from "@/core/api";
import { type UserInterface } from "@/core/interfaces";
import { defineStore, getActivePinia } from "pinia";
import type { DailyQuoteInterface } from "@/core/interfaces";

interface State {
  newToBizlyUsers: UserInterface[];
  quoteOfTheDay: DailyQuoteInterface | null;
}
const api = new API.Miscellaneous();
export const useStore = defineStore("store", {
  state: (): State => ({
    newToBizlyUsers: [],
    quoteOfTheDay: null,
  }),
  getters: {},
  actions: {
    async getQuoteOfTheDay() {
      try {
        let response = await api.quoteOfTheDay();
        this.quoteOfTheDay = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getNewToBizlyUsers() {
      try {
        let response = await api.newToBizlyUsers();
        this.newToBizlyUsers = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    formatTextWithMentionInSocialPost(text: string) {
      return text?.replace(/<([^:]+):([^>]+)>/g, (_, username) => {
        return username;
      });
    },
    resetStores() {
      getActivePinia()._s.forEach((store) => store.$reset());
    },
  },
});
