<template>
  <HomeLayout v-bind="(page.props as any)">
    <div
      class="overflow-y-auto relative bg-white rounded-[16px] my-[30px] shadow-2xl max-w-[500px] mx-[1rem] lg:mx-0 h-[90%] flex flex-col overflow-hidden">
      <!-- Header -->
      <div
        class="sticky top-0 z-[60] bg-[#F7F8FB] py-[10px] px-[20px] border-b border-[#CDD5E1] flex items-center gap-[16px]">
        <button class="text-gray-500 hover:text-gray-700">
          <img
            src="@/assets/images/arrow-back.svg"
            alt="back"
            @click="useRouterStore().back()" />
        </button>
        <h2 class="!text-[16px] font-[500] mb-0">Portfolio Social Post</h2>
      </div>

      <!-- Scrollable Content -->
      <div
        class="flex-1 overflow-y-auto scroll-hidden overscroll-none scroll-smooth">
        <div class="flex flex-col custom-scrollbar activity-feed">
          <SocialPost v-bind="props" class="!rounded-none" showInteractions />
        </div>
      </div>

      <!-- Footer -->
      <div class="activity-feed-footer sticky bottom-0 z-[20]">
        <Footer :data="Post" />
      </div>
    </div>
  </HomeLayout>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, onUnmounted, watch } from "vue";
import HomeLayout from "@/components/layout/HomeLayout.vue";
import Footer from "@/components/social-posts/Footer.vue";
import { router, Link, usePage } from "@inertiajs/vue3";
import SocialPost from "./parts/SocialPost.vue";
import type { SocialPostInterface } from "@/core/interfaces";
import { useWSStore } from "@/store/ws/WebSocket";
import { useRouterStore } from "@/store/Router";
const wsStore = useWSStore();
const socket = computed(() => wsStore.socket);
const page = usePage();

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
});
const Post = computed(() => props.post);

onMounted(() => {
  wsStore.Subscribe({
    channel: `social-posts.${props.post.id}`,
    is_private: true,
    event: ".comment-event",
    action: commentAction,
  });
  wsStore.Subscribe({
    channel: `social-posts.${props.post.id}`,
    is_private: true,
    event: ".like-event",
    action: likeAction,
  });
});
onUnmounted(() => {
  wsStore.UnSubscribe({ channel: `social-posts.${props.post.id}` });
});

const commentAction = (data) => {
  console.log("Comment Action called!");

  switch (data.type) {
    case "created":
      props.post.comments.unshift(data.comment);
      props.post.comments_count++;
      break;
    case "deleted":
      const commentIdx = props.post.comments.findIndex(
        (c) => c.id === data.comment.id,
      );
      if (commentIdx !== -1) {
        props.post.comments.splice(commentIdx, 1);
        props.post.comments_count--;
      }
      break;
    default:
      console.log("[DEBUG] WebSocket :: commentAction", data);
      return;
  }
};

const likeAction = (data) => {
  console.log("LIKE EVENT :: ", data);
  switch (data.type) {
    case "liked":
      props.post.likes.push(data.user);
      props.post.likes_count++;
      break;
    case "unliked":
      const likeIdx = props.post.likes.findIndex((l) => l.id === data.user.id);
      if (likeIdx !== -1) {
        props.post.likes.splice(likeIdx, 1);
        props.post.likes_count--;
      }
      break;
    default:
      console.log("LIKE EVENT :: ", data);
      return;
  }
};
/* ---------- WATCHERS ---------- */
</script>

<style scoped lang="scss">
@import "../../assets/scss/feed.scss";

.socialPostActivtyFeed {
  width: 600px;

  @media screen and (max-width: 1340px) {
    width: 450px;
  }

  @media screen and (max-width: 1040px) {
    width: 100%;
  }
}

.socialPostActivtyFeedInner {
  width: 500px;

  @media screen and (max-width: 1340px) {
    width: 400px;
  }

  @media screen and (max-width: 1040px) {
    width: 500px;
  }
}
</style>
