<template>
  <div>
    <Header />
    <slot name="sidebar" />
    <main
      class="transition-all mt-[56px] max-h-[calc(100vh-56px)] w-screen md:ml-[350px] lg:ml-[400px] md:w-[calc(100%-350px)] lg:w-[calc(100%-400px)] overflow-y-auto scroll-hidden">
      <div class="block md:hidden">
        <i
          class="fa-solid fa-arrow-right text-xl cursor-pointer bg-white shadow-default py-1 px-2"
          @click="isOpen = true"></i>
      </div>
      <slot />
    </main>
  </div>
</template>
<script setup lang="ts">
import Header from "@/components/layout/Header.vue";
import { provide, ref } from "vue";
const isOpen = ref(false);
provide("isOpen", isOpen);
</script>
