<template>
  <Unavailable v-if="code === 'unavailable'" type="content" />
  <Layout
    v-else
    :header="{
      title: content.data.title,
    }">
    <component :is="contentComponent" :content="content.data" />

    <template #actions>
      <CircleIcon
        v-if="isOwned"
        size="2.3rem"
        class="!bg-main-secondary"
        @click="handleOpenContentDialog">
        <i class="fa-sharp fa-thin fa-pen text-white"></i>
      </CircleIcon>
      <CircleIcon size="2.3rem" class="!bg-main-secondary">
        <i class="fa-sharp fa-thin fa-share text-white"></i>
      </CircleIcon>
      <CircleIcon
        v-if="authStore.isLoggedIn()"
        size="2.3rem"
        class="!bg-main-secondary"
        @click="toggle">
        <i class="fa fa-ellipsis text-white"></i>
      </CircleIcon>
      <ManageContent
        :content="props.content.data"
        ref="headerMenu"></ManageContent>
    </template>
  </Layout>
</template>
<script setup lang="ts">
import {
  reactive,
  ref,
  computed,
  onMounted,
  watch,
  markRaw,
  nextTick,
  provide,
} from "vue";
import { Link, router, usePage } from "@inertiajs/vue3";
import { useToastr } from "@/composables/useToastr";
import { transformData } from "@/components/dialogs/content/helpers/transformData";
import { API } from "@/core/api";
import { DialogType, useDialogStore } from "@/store/Dialog";
import { route } from "ziggy-js";
import Layout from "@/components/bizcard/content/Layout.vue";
import Video from "./videos/Video.vue";
import Image from "./images/Image.vue";
import Collection from "./collection/Index.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import ConfirmDialog from "@/components/dialogs/Confirm.vue";
import LinkPage from "./links/Link.vue";
import type { MenuButtonInterface, UserInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
import ManageContent from "@/components/menus/ManageContent.vue";
import Unavailable from "@/pages/Inactive.vue";
/* ---------- REACTIVE DATA ---------- */
const props = defineProps({
  content: {
    required: true,
    type: Object,
  },
  code: {
    type: String,
  },
});

/* ----- COMPOSABLES -----*/
const authStore = useAuthStore();
const dialogStore = useDialogStore();
const { error } = useToastr();

/* ----- API -----*/
const contentAPI = new API.Content();

const DeletePostDialogVisible = ref(false);
const headerMenu = ref();
const user = computed(() => authStore.getUser);
const collaborators = computed(
  () =>
    props.content?.data.collaborators?.filter(
      (c) => c.accepted || c.id === props.content.data.bizcard.user.id,
    ) ?? [],
);
provide("collaborators", collaborators);
const isOwned = computed<Boolean>(
  () => props.content.data.bizcard.user.id === user.value.id,
);

const contentComponent = computed(() => {
  switch (props.content.data.content_type) {
    case "video":
    case "video_gallery":
      return markRaw(Video);
    case "image":
    case "image_gallery":
      return markRaw(Image);
    case "link":
    case "link_gallery":
      return markRaw(LinkPage);
    case "collection":
      return markRaw(Collection);
    default:
      return null;
  }
});

/* ---------- FUNCTIONS ---------- */
const toggle = (event) => {
  headerMenu.value.toggle(event);
};

const handleOpenContentDialog = async () => {
  const transformedData = await transformData(props.content.data);

  if (props.content.data.content_type === "collection") {
    dialogStore.show(DialogType.UPLOAD_CONTENT_COLLECTION, undefined, {
      content: transformedData,
    });
    dialogStore.setEvents(DialogType.UPLOAD_CONTENT_COLLECTION, {
      close: () => {
        dialogStore.hide(DialogType.UPLOAD_CONTENT_COLLECTION);
      },
    });
  } else {
    dialogStore.show(DialogType.UPLOAD_CONTENT, undefined, {
      content: transformedData,
    });
    dialogStore.setEvents(DialogType.UPLOAD_CONTENT, {
      close: () => {
        dialogStore.hide(DialogType.UPLOAD_CONTENT);
      },
    });
  }
};

onMounted(async () => {
  await nextTick(); //testasasdasd asd asd asd asd s
  console.log(
    "[SCROLL][CLP]",
    document.getElementsByTagName("body")[0].scrollTop,
  );
});
</script>
