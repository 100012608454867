import ImageKit from "imagekit-javascript";
import { API } from "@/core/api";

const public_key = import.meta.env.VITE_IMAGEKIT_PUBLIC_KEY;
const urlEndpoint = import.meta.env.VITE_IMAGEKIT_ENDPOINT_URL;
const APP_URL = import.meta.env.VITE_APP_URL;
const imageKit = new ImageKit({
  publicKey: public_key,
  urlEndpoint: urlEndpoint,
});
export function isBase64(file) {
  return (
    typeof file === "string" &&
    file.startsWith("data:image/") &&
    file.includes("base64,")
  );
}
export function isCorsLink(link) {
  return typeof link === "string" && link.includes(APP_URL);
}

export function convertToBase64(url) {
  return fetch(url)
    .then((res) => res.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );
}
export async function upload(file, fileName) {
  const imageKitAPI = new API.imageKit();

  try {
    let auth_response = await imageKitAPI.authenticate();
    if (auth_response.error) throw new Error(auth_response.error);
    const { signature, token, expire } = auth_response.data;
    const uploadOptions = {
      file, // required
      fileName, // required
      signature, // required
      token, // required
      expire, // required
    };
    let response = await imageKit.upload(uploadOptions);
    if (response && response.url) return response;
    throw new Error("An error has occurred while uploading media");
  } catch (err) {
    console.error(err);
    return { error: err };
  }
}
