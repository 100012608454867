<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-4 w-full flex flex-col relative gap-4',
      root: 'bg-lightBg-400 max-w-[500px] max-h-screen w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <h1 class="font-semibold text-center">Report Submitted Successfully</h1>
    <p class="text-center text-sm text-primary/80">
      Thank you for helping us maintain a positive and safe community. Our team
      will review your report and take appropriate action as needed. Your
      privacy is important to us; rest assured that your report will remain
      confidential
    </p>
    <p class="text-center text-sm text-primary/80">
      Would you also like to block this user?
    </p>
    <div class="flex gap-2 items-center w-full">
      <button
        class="py-2 w-full border border-primary text-primary rounded-md"
        @click="$emit('close')">
        Done
      </button>
      <button
        class="py-2 w-full border border-primary text-primary rounded-md bg-red-500 text-white"
        @click="blockUser">
        Block user
      </button>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import { useDialogStore, DialogType } from "@/store/Dialog";
import { useUserStore } from "@/store/User";
const dialogStore = useDialogStore();
const emit = defineEmits(["close"]);
const user = computed(() => dialogStore.getData(DialogType.REPORT_USER));

const blockUser = () => {
  useUserStore().blockUser(user.value?.id);

  emit("close");
};
</script>
