import baseApi from "./Base";
import { route } from "ziggy-js";

export class SocialPostCommentsService extends baseApi {
  async add(post: number, form: any) {
    return await this.request(
      route("social_posts.comments.add", { post }),
      "POST",
      form,
    );
  }

  async remove(post: number, comment: number) {
    return await this.request(
      route("social_posts.comments.delete", { post, comment }),
      "POST",
    );
  }
  async get(postId: number, form: Object) {
    return await this.request(
      route("social_posts.comments.index", { post: postId }),
      "GET",
      form,
    );
  }
}
