<template>
  <div>
    <slot />
    <DialogList />
  </div>
</template>
<script setup lang="ts">
import { onBeforeMount, provide, reactive, ref, computed, onMounted, watch } from "vue";
import type {
  ConnectionsInterface,
  NotificationInterface,
  UserInterface,
} from "@/core/interfaces";
import { useStore } from "@/store";
import { useAuthStore } from "@/store/Auth";
import { useUserStore } from "@/store/User";
import { parseFlashData } from "@/utils/toast";
import { router, usePage } from "@inertiajs/vue3";
import DialogList from "@/components/dialogs/DialogsList.vue";
import { useWSStore } from "@/store/ws/WebSocket";
import { useNotificationStore } from "@/store/Notifications";
import { useEventsStore } from "@/store/Events";
import { useRouterStore } from "@/store/Router";
import { DialogType, useDialogStore } from "./store/Dialog";

const emitter = computed(() => useEventsStore().emitter);
/* ---------- REACTIVE DATA ---------- */

/* EVENT EMITTER (EVENT BUS) */

window.addEventListener("resize", () => emitter.value.emit("resize"));

const notificationStore = useNotificationStore();
const page = usePage();
const authStore = useAuthStore();
const store = useStore();
const userStore = useUserStore();
const ToastList = computed(() => page.props.flash_data);
const token = computed<string | null>(() => page.props.token as string | null);
const authUser = computed(() => ({
  ...(page.props.auth_user as UserInterface),
}));

const wsStore = useWSStore();
const routerStore = useRouterStore();
const old_user_id = ref();

/* ---------- FUNCTIONS ---------- */

/* WS ACTIONS */
const notificationEventAction = (data: {
  type: string;
  notification: NotificationInterface;
}) => {
  switch (data.type) {
    case "created":
      notificationStore.AddNotification(data.notification);
      break;
    case "deleted":
      notificationStore.RemoveNotification(data.notification);
      break;
    default:
      console.log("notificationEventAction", data);
      return;
  }
  console.log("notificationEventAction", data);
};
const connectionRequestAction = (data: {
  type: string;
  connection_request: ConnectionsInterface;
}) => {
  console.log("connectionRequestAction", data);
  switch (data.type) {
    case "received":
      userStore.addConnectionRequest(data.connection_request);
      break;
    case "accepted":
      userStore.connectionRequestAccepted(data.connection_request);
      break;
    case "canceled":
      userStore.removeReceivedRequestWS(data.connection_request);
      break;
    case "deleted":
      userStore.removeReceivedRequestWS(data.connection_request);
      userStore.removeSentRequestWS(data.connection_request);
      break;
    default:
      console.log("connectionRequestAction", data);
      return;
  }
};

onMounted(() => {
  wsStore.initWS();
  if (authStore.isLoggedIn()) {
    notificationStore.getNotifications();
    if (!authStore.getUser.avatar_image) useDialogStore().show(DialogType.UPLOAD_PROFILE);
  }
});

const setFriendsList = async () => {
  if (token.value) {
    await userStore.getFriends();
  } else {
    await userStore.emptyFriendsList();
  }
};

const setUserData = () => {
  console.log("[DEBUG] App.vue setUserData", authUser.value);
  authStore.setToken(token.value as string | null);
  authStore.setUser(authUser.value);
  setFriendsList();
  notificationStore.getNotifications();
};

/* ---------- WATCHERS ---------- */
watch(
  ToastList,
  (newVal) => {
    parseFlashData(newVal);
  },
  { immediate: true, deep: true }
);

watch(
  () => token.value,
  () => {
    if (token.value) {
      setUserData();
      store.getNewToBizlyUsers();
      store.getQuoteOfTheDay();
    }
  },
  { immediate: true }
);

// LISTEN TO USER EVENTS WHEN USER IS LOADED AND IS NOT NULL
watch(
  () => authUser.value,
  (newVal, oldVal) => {
    if (newVal && !oldVal) {
      wsStore.Subscribe({
        channel: `notifications.user.${newVal.id}`,
        is_private: true,
        event: ".notification-event",
        action: notificationEventAction,
      });
      wsStore.Subscribe({
        channel: `connection-requests.user.${newVal.id}`,
        is_private: true,
        event: ".connection-request-event",
        action: connectionRequestAction,
      });
    }
    if (oldVal && !newVal) {
      wsStore.UnSubscribe({
        channel: `notifications.user.${oldVal.id}`,
      });
      wsStore.UnSubscribe({
        channel: `connection-requests.user.${oldVal.id}`,
      });
    }
  },
  { deep: true, immediate: true }
);

// LOCAL ROUTER HISTORY
router.on("navigate", (event) => {
  routerStore.pushState(route().current());
});
</script>
