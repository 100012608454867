+
<template>
  <section
    id="hero"
    class="relative items-end bg-center bg-no-repeat bg-cover mx-auto max-w-screen overflow-hidden p-[50px] !h-screen md:!h-full flex w-full overscroll-contain"
    ref="headerContainer"
    :style="`background-image: url(${user.web_background_image ?? DefaultBg})`">
    <div class="absolute bottom-10 left-10 text-white z-10 space-y-4">
      <section id="Info" class="flex gap-4 items-center">
        <div class="relative cursor-pointer">
          <div v-if="!bizCardStore.isEditing">
            <img
              :src="user.avatar_image"
              alt=""
              class="rounded-full object-cover size-24 border-2 border-white" />
          </div>
          <FileUpload
            v-else
            mode="advanced"
            name="demo[]"
            url="/api/upload"
            accept="image/*"
            customUpload
            :auto="true"
            @uploader="handleUpdateAvatar">
            <template #header="{ chooseCallback }">
              <div @click="chooseCallback" class="relative">
                <img
                  :src="user.avatar_image"
                  alt=""
                  class="rounded-full object-cover size-24 border-2 border-white" />
                <CircleIcon
                  v-if="bizCardStore.isEditing"
                  class="absolute bottom-1 right-1 border-2 border-white !bg-main-primary"
                  size="1.2rem">
                  <i class="fal fa-refresh text-white text-[10px]"></i>
                </CircleIcon>
              </div>
            </template>
          </FileUpload>
        </div>
        <div class="flex flex-col justify-center">
          <h1 class="font-bold text-5xl leading-tight">
            {{ user.first_name }}
            <br />
            {{ user.last_name }}
          </h1>
          <p class="text-xs text-main-disabled" v-if="user.pronouns">
            ({{ user.pronouns[0] }})
          </p>
        </div>
      </section>
      <section
        id="roles"
        class="flex gap-2 items-center"
        v-if="user.roles.length">
        <div
          class="flex items-center gap-2"
          v-for="role in user.roles"
          :key="role.id">
          <p>{{ role.name }}</p>
          <div
            class="p-0.5 bg-white rounded-full size-0.5"
            v-if="role.id !== user.roles.at(-1).id"></div>
        </div>
      </section>
      <section id="location" class="flex gap-4 items-center">
        <i class="fa-regular fa-location-dot text-xl"></i>
        <p class="text-base">{{ user.location }}</p>
      </section>
      <section id="pitch" class="text-lg text-white font-semibold">
        {{ user.elevator_pitch }}
      </section>
      <section id="links" class="flex gap-4">
        <div class="flex gap-4 flex-wrap max-w-[600px]">
          <button
            v-if="owned_profile && bizCardStore.isEditing"
            @click="handleOpenCreateLink"
            class="text-sm bg-switcherBlue-500/60 !rounded-[20px] text-white py-2 px-4 flex items-center justify-center gap-2 cursor-pointer">
            <i class="fa fa-chain"></i>
            Add a link
          </button>
          <button
            v-if="card.buttons && card.buttons.length"
            class="text-sm bg-white/15 !rounded-[20px] text-white py-2 px-4 flex items-center justify-center gap-2 hover:bg-white/45 transition-all cursor-pointer"
            v-for="(button, index) in card.buttons"
            :key="button.link"
            @click="() => handleOpen(button, index)">
            <i class="fa fa-chain"></i>
            {{ button.title }}
          </button>
        </div>
      </section>
      <section id="actions" v-if="authStore.isLoggedIn()">
        <section id="own actions" v-if="owned_profile">
          <div v-if="bizCardStore.isEditing" class="flex gap-4">
            <button
              @click="() => handleManageBizcard('intro')"
              class="text-white bg-switcherBlue-500 min-w-[300px] font-medium py-2.5 px-5 rounded">
              <i class="fa-solid fa-pen"></i>
              Edit Intro
            </button>
            <button
              @click="() => handleManageBizcard('web_cover')"
              class="text-white bg-[#616161] min-w-[300px] font-medium py-2.5 px-5 rounded">
              <i class="fa-solid fa-pen"></i>
              Edit cover photo
            </button>
          </div>
        </section>
        <section id="friend actions" class="flex gap-4" v-else-if="is_friend">
          <button
            @click="handleOpenMobileAppDialog"
            class="mt-5 text-white bg-switcherBlue-500 w-[300px] font-medium py-2.5 px-5 flex items-center justify-center gap-2 h-[44px] text-lg rounded-md">
            <i class="fa-solid fa-comment"></i>
            Message {{ user.first_name }}
          </button>
        </section>
        <section
          id="incoming requests actions"
          class="flex gap-4"
          v-else-if="is_incoming_request">
          <button
            @click="handleAcceptConnection"
            class="mt-5 text-white bg-[#34c759] w-[200px] font-medium py-2.5 px-5 flex items-center justify-center h-[44px] text-lg rounded-md">
            Accept
          </button>
          <button
            @click="handleDeclineConnection"
            class="mt-5 text-white bg-[#616161] w-[200px] font-medium py-2.5 px-5 flex items-center justify-center h-[44px] text-lg rounded-md">
            Decline
          </button>
        </section>
        <section
          id="outbound requests actions"
          class="flex gap-4"
          v-else-if="is_outbound_request">
          <button
            @click="(event) => emit('toggleRequestsMenu', event)"
            class="mt-5 text-white bg-[#ff9900] w-[300px] font-medium py-2.5 px-5 flex items-center justify-center gap-2 h-[44px] text-lg rounded-md">
            <i class="fa-thin fa-clock-three"></i>
            Connection Pending
          </button>
        </section>
        <section id="stranger actions" class="flex gap-4" v-else>
          <button
            @click="handleConnectUser"
            class="mt-5 text-white bg-switcherBlue-500 w-[300px] font-medium py-2.5 px-5 flex items-center justify-center gap-2 h-[44px] text-lg rounded-md">
            <i class="fa-thin fa-user-plus"></i>
            Connect
          </button>
        </section>
      </section>
    </div>
    <div class="absolute inset-0">
      <div class="h-full w-full radial-gradient-overlay"></div>
    </div>
  </section>
</template>
<script lang="ts" setup>
import { DialogType, useDialogStore } from "@/store/Dialog";
import { useUserStore } from "@/store/User";
import { useUserBizcardStore } from "@/store/UserBizcard";
import type { PropType } from "vue";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import { computed } from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import DefaultBg from "@/assets/images/bgPics/web-bg-min.jpg";
import { router } from "@inertiajs/vue3";
import { useToastr } from "@/composables/useToastr";
import { API } from "@/core/api";
import { formToJSON } from "axios";
import { useAuthStore } from "@/store/Auth";
import { upload } from "@/utils/imageKit";
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});

const emit = defineEmits(["toggleRequestsMenu"]);

/** --------- APIS ---------- */
const bizcardAPI = new API.Bizcard();
const userAPI = new API.Users();

/* ---------- STORES ----------*/
const { success, error } = useToastr();
const dialogStore = useDialogStore();
const bizCardStore = useUserBizcardStore();
const userStore = useUserStore();
const authStore = useAuthStore();

/** ---------- REACTIVE --------- */
const owned_profile = computed(() => bizCardStore.own_profile);
const friends = computed(() => userStore.friends ?? []);
const received_requests = computed(
  () => userStore.friend_requests.received ?? [],
);
const sent_requests = computed(() => userStore.friend_requests.sent ?? []);
const is_incoming_request = computed(
  () =>
    received_requests.value.findIndex((r) => r.sender.id === props.user.id) !==
    -1,
);
const is_outbound_request = computed(
  () =>
    sent_requests.value.findIndex((r) => r.recipient.id === props.user.id) !==
    -1,
);
const is_friend = computed(
  () => friends.value.findIndex((f) => f.id === props.user.id) !== -1,
);

/** ---------- FUNCTIONS --------- */
const handleConnectUser = () => {
  userStore.connectUser(props.user.id);
};

const handleAcceptConnection = () => {
  userStore.acceptConnectionRequest(
    received_requests.value.find((r) => r.sender.id === props.user.id),
  );
};

const handleDeclineConnection = () => {
  userStore.declineConnectionRequest(
    received_requests.value.find((r) => r.sender.id === props.user.id),
  );
};

const handleOpenCreateLink = () => {
  dialogStore.show(DialogType.MANAGE_LINK);
  dialogStore.setEvents(DialogType.MANAGE_LINK, {
    close: () => dialogStore.hide(DialogType.MANAGE_LINK),
    confirm: async (item: any) => {
      props.card.buttons.push({ title: item.title, link: item.link });
      try {
        let response = await bizcardAPI.updateLinks(props.card.buttons);
        if (!response.success) throw new Error(response.error);
        dialogStore.hide(DialogType.MANAGE_LINK);
        success("Links updated!");
      } catch (err) {
        console.error(err);
        error("An error has occurred!");
      }
    },
  });
};

const handleManageBizcard = (page: string) => {
  dialogStore.show(DialogType.MANAGE_BIZCARD, undefined, {
    user: props.user,
    page,
  });
  dialogStore.setEvents(DialogType.MANAGE_BIZCARD, {
    close: () => dialogStore.hide(DialogType.MANAGE_BIZCARD),
  });
};

const handleOpen = (item: any, itemIdx: number) => {
  if (bizCardStore.isEditing) {
    dialogStore.show(DialogType.MANAGE_LINK, undefined, { ...item });
    dialogStore.setEvents(DialogType.MANAGE_LINK, {
      close: () => dialogStore.hide(DialogType.MANAGE_LINK),
      confirm: async (item: any) => {
        props.card.buttons[itemIdx] = { title: item.title, link: item.link };

        try {
          let response = await bizcardAPI.updateLinks(props.card.buttons);

          if (!response.success) throw new Error(response.error);
          dialogStore.hide(DialogType.MANAGE_LINK);
          success("Links updated!");
        } catch (err) {
          console.error(err);
          error("An error has occurred!");
        }
      },
      delete: async (item: any) => {
        props.card.buttons.splice(itemIdx, 1);
        try {
          let response = await bizcardAPI.updateLinks(props.card.buttons);

          if (!response.success) throw new Error(response.error);
          dialogStore.hide(DialogType.MANAGE_LINK);
          success("Links updated!");
        } catch (err) {
          console.error(err);
          error("An error has occurred!");
        }
      },
    });
  } else {
    handleOpenUrl(item.url);
  }
};

const handleOpenUrl = (url: string) => {
  // Check if the URL does not start with 'http://' or 'https://'
  if (!/^https?:\/\//i.test(url)) {
    // Prepend 'https://' to the URL
    url = "https://" + url;
  }

  if (url.includes("mybizly.com") || userStore.openExternalLinks) {
    window.open(url, "_blank");
    return;
  } else {
    dialogStore.show(DialogType.EXTERNAL_LINK, undefined, { url });
    dialogStore.setEvents(DialogType.EXTERNAL_LINK, {
      close: () => dialogStore.hide(DialogType.EXTERNAL_LINK),
      confirm: () => {
        window.open(url, "_blank");
        dialogStore.hide(DialogType.EXTERNAL_LINK);
      },
    });
  }
};

const handleOpenMobileAppDialog = () => {
  dialogStore.show(DialogType.MOBILE_APP);
  dialogStore.setEvents(DialogType.MOBILE_APP, {
    close: () => dialogStore.hide(DialogType.MOBILE_APP),
  });
};

const handleUpdateAvatar = (event: any) => {
  const file = event.files[0];
  const reader = new FileReader();

  reader.onload = async function (e: any) {
    try {
      const IKresponse = await upload(e.target.result, "AVATAR_IMAGE");
      if (IKresponse.error) throw new Error(IKresponse.error);
      const response = await userAPI.updateAvatar({ avatar: IKresponse.url });
      if (response.error) throw new Error(response.error);
      if (response.success) {
        success(response.success);
        authStore.setUser({
          ...authStore.getUser,
          avatar_image: props.user.avatar_image,
        });
        router.reload({ only: ["user"] });
      }
    } catch (err) {
      console.error(err);
      error("An error has occurred!");
    }
  };

  reader.readAsDataURL(file);
};
</script>
