import { defineStore } from "pinia";
import mitt, { type Emitter } from "mitt";

interface State {
  emitter: Emitter<any>;
}

export const useEventsStore = defineStore("eventsStore", {
  state: (): State => ({
    emitter: mitt(),
  }),
  getters: {},
  actions: {},
});
