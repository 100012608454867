<template>
  <div
    class="w-full bg-white px-4 py-[12px] border-b border-main-divider flex items-center justify-between"
    :class="
      page.url?.includes('social-posts') ? 'rounded-t-none' : 'rounded-t-[16px]'
    ">
    <span
      class="info-container flex gap-2 md:gap-[0.5rem] items-center w-[90%]">
      <span
        class="flex items-center gap-[5px] justify-center py-[3px] px-[10px] bg-primary-400 text-white text-switcherBlue-500Bg rounded-[20px] text-[14px] focus:shadow-none">
        <i class="fa fa-folders"></i>
        Portfolio
      </span>
      <div
        class="flex items-center gap-[5px] justify-center"
        v-if="post.is_hidden">
        <DotSeparator />
        <i
          class="fa fa-eye-slash cursor-pointer text-primary-500"
          v-tooltip.top="'This post is hidden'"
          @click.stop="handleUnhidePost"></i>
      </div>
      <DotSeparator />
      <i
        class="text-main-lightGray"
        :class="
          !post?.is_network_only
            ? 'fa-solid fa-globe'
            : 'fa-solid fa-user-group'
        " />
      <DotSeparator />
      <small class="text-main-lightGray mb-0 text-xs whitespace-nowrap w-max">
        {{ usePostCreationTime(post?.created_on) }}
      </small>
    </span>
    <button
      v-if="authStore.isLoggedIn()"
      class="flex gap-2.5 items-center rounded-[50%] hover:bg-[#e4e4e4] focus:bg-[#e4e4e4]"
      type="button"
      @click.stop="toggle"
      aria-haspopup="true">
      <svg
        width="27"
        height="26"
        viewBox="0 0 27 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.83237 14.3317C7.56875 14.3317 8.16571 13.7347 8.16571 12.9984C8.16571 12.262 7.56875 11.665 6.83237 11.665C6.09598 11.665 5.49902 12.262 5.49902 12.9984C5.49902 13.7347 6.09598 14.3317 6.83237 14.3317Z"
          fill="#595959" />
        <path
          d="M13.4979 14.3317C14.2343 14.3317 14.8312 13.7347 14.8312 12.9984C14.8312 12.262 14.2343 11.665 13.4979 11.665C12.7615 11.665 12.1646 12.262 12.1646 12.9984C12.1646 13.7347 12.7615 14.3317 13.4979 14.3317Z"
          fill="#595959" />
        <path
          d="M20.1668 14.3317C20.9032 14.3317 21.5002 13.7347 21.5002 12.9984C21.5002 12.262 20.9032 11.665 20.1668 11.665C19.4305 11.665 18.8335 12.262 18.8335 12.9984C18.8335 13.7347 19.4305 14.3317 20.1668 14.3317Z"
          fill="#595959" />
      </svg>
    </button>

    <Menu
      v-if="authStore.isLoggedIn()"
      id="overlay_menu"
      :model="ellipsesMenu"
      class="w-[21rem] content_menu overflow-hidden shadow-[0_2px_15px_0_rgba(0,0,0,0.3)]"
      ref="menu"
      :popup="true">
      <template #item="{ item }">
        <div class="" v-if="item.active">
          <a
            class="flex items-center gap-4 py-3 px-4 w-full hover:bg-switcherBlue-500/15"
            :class="{ 'border-t border-main-divider': item.border }"
            v-if="item.type == 'external_link'"
            :href="item.href"
            target="_blank">
            <CircleIcon>
              <i :class="item.icon"></i>
            </CircleIcon>

            <div class="flex flex-col gap-[2px] items-start justify-start">
              <p
                class="mb-0 w-max text-sm font-medium leading-5 text-primary"
                :class="{
                  'text-[#ff3b30]': item.danger,
                }">
                {{ item?.label }}
              </p>
              <small
                class="text-xs font-normal leading-4 text-main-secondary text-left"
                v-if="item.description">
                {{ item?.description }}
              </small>
            </div>
          </a>
          <Link
            v-else-if="item.type === 'inertia_link'"
            :href="item.href"
            class="flex items-center gap-4 py-3 px-4 w-full hover:bg-switcherBlue-500/15"
            :class="{ 'border-t border-main-divider': item.border }">
            <div class="size-6">
              <i
                :class="
                  cn(item.icon, {
                    'text-[#ff3b30]': item.danger,
                  })
                "></i>
            </div>

            <div class="flex flex-col gap-[2px] items-start justify-start">
              <p
                class="mb-0 w-max text-sm font-medium leading-5 text-primary"
                :class="{
                  'text-[#ff3b30]': item.danger,
                }">
                {{ item?.label }}
              </p>
              <small
                class="text-xs font-normal leading-4 text-main-secondary text-left"
                v-if="item.description">
                {{ item?.description }}
              </small>
            </div>
          </Link>
          <button
            v-else-if="item.type === 'button'"
            @click="item.clickEvent"
            class="flex items-center gap-3.5 py-3 px-4 w-full hover:bg-switcherBlue-500/15"
            :class="{ 'border-t border-main-divider': item.border }">
            <div class="size-6">
              <i
                :class="
                  cn(item.icon, {
                    'text-[#ff3b30]': item.danger,
                  })
                "></i>
            </div>
            <div class="flex flex-col gap-[2px] items-start justify-start">
              <p
                class="mb-0 w-max text-sm font-medium leading-5 text-primary"
                :class="{
                  'text-[#ff3b30]': item.danger,
                }">
                {{ item?.label }}
              </p>
              <small
                class="text-xs font-normal leading-4 text-text-500 text-left"
                v-if="item.description">
                {{ item?.description }}
              </small>
            </div>
          </button>
        </div>
      </template>
    </Menu>
  </div>

  <div
    v-if="user?.is_on_waitlist && route().current().includes('social_posts')"
    class="h-[40px] text-[#546B91] bg-[#EFF1F5] border-b-[1px] border-b-[#CDD5E1] px-[20px] py-[10px] flex gap-[6px] items-center">
    <i class="fa-solid fa-triangle-exclamation"></i>
    <span class="text-xs text-[400]">Engagement and usage is limited.</span>
  </div>
  <!-- ----- DIALOGS ----- -->

  <!-- Delete Post -->
  <ConfirmDangerDialog
    v-if="isOwned && DeletePostDialogVisible"
    @close="DeletePostDialogVisible = false"
    @confirm="handleDeletePost"
    title="Delete Social Post"
    icon="fa fa-trash"
    action="Delete">
    <div>
      <div
        class="text-main-secondaryBlue text-xs md:text-sm font-normal text-center mt-2.5 leading-5">
        Are you sure you would like to delete this post? This action cannot be
        undone.
      </div>
    </div>
  </ConfirmDangerDialog>
  <!-- Block User -->
  <ConfirmDangerDialog
    class=""
    v-if="BlockUserDialogVisible"
    @close="BlockUserDialogVisible = false"
    @confirm="handleBlockUser"
    title="Block User"
    icon="fa fa-trash"
    action="Block">
    <div>
      <div
        class="text-main-secondaryBlue text-xs md:text-sm font-normal text-center mt-2.5 leading-5 mx-auto w-full self-center">
        Are you sure you want to block
        <strong>{{ postOwner.first_name }}</strong>
        ?
      </div>
    </div>
  </ConfirmDangerDialog>
</template>

<script setup lang="ts">
import ConfirmDangerDialog from "@/components/dialogs/ConfirmDanger.vue";
import { transform } from "@/components/dialogs/content/helpers/transformData";
import DotSeparator from "@/components/general/DotSeparator.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { useToastr } from "@/composables/useToastr";
import { HomeFeedType } from "@/core/enums";
import type { ConnectionsInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
import { DialogType, useDialogStore } from "@/store/Dialog";
import { useHomeFeedStore } from "@/store/HomeFeed";
import { useUserStore } from "@/store/User";
import { cn } from "@/utils/cn";
import { usePostCreationTime } from "@/utils/commonFunctions.js";
import { Link, router, usePage } from "@inertiajs/vue3";
import _debounce from "lodash/debounce";
import Menu from "primevue/menu";
import { computed, ref } from "vue";
import { route } from "ziggy-js";

/* ----- STORES ----- */
const authStore = useAuthStore();
const dialogStore = useDialogStore();
const homeFeedStore = useHomeFeedStore();
const UserStore = useUserStore();
const { success, error } = useToastr();

const props = defineProps({
  post: {
    required: true,
    type: Object,
  },
});

/* ---------- REACTIVE DATA ----------*/

const page = usePage();
const menu = ref();
const emit = defineEmits(["delete"]);
/* ----- Dialogs -----*/
const DeletePostDialogVisible = ref(false);
const BlockUserDialogVisible = ref(false);

const user = computed(() => authStore.getUser);
const transformedData = ref();
const postOwner = computed(() => props.post.owner);
const isOwned = computed(() => postOwner.value.id === user.value.id);
const friends = computed(() => UserStore.friends ?? []);
const isFriend = computed(
  () => friends.value.findIndex((f) => f.id === postOwner.value.id) !== -1,
);
const friend_requests = computed(() => UserStore.friend_requests);
const has_friend_request = computed(
  () =>
    friend_requests.value.sent.findIndex(
      (f) => f.recipient.id === postOwner.value.id,
    ) !== -1 ||
    friend_requests.value.received.findIndex(
      (f) => f.sender.id === postOwner.value.id,
    ) !== -1,
);
const ellipsesMenu = computed(() => {
  return [
    {
      active: isOwned.value,
      label: "Edit Post",
      border: false,
      description: "Edit the message on your post.",
      type: "button",
      clickEvent: () => {
        dialogStore.show(DialogType.POST_MANAGER, props.post.id, {
          post: props.post,
          user: user.value,
        });
        dialogStore.setEvents(DialogType.POST_MANAGER, {
          confirm: () => handleEditPost(),
          close: () => dialogStore.hide(DialogType.POST_MANAGER),
        });
      },
      icon: "fa-sharp fa-regular fa-pen-to-square text-primary",
    },
    {
      active: isOwned.value,
      label: "Delete Post",
      border: false,
      description:
        "Remove this post from all feeds. This will not delete the content from your portfolio.",
      type: "button",
      icon: "fa-sharp fa-regular fa-trash text-primary",
      clickEvent: () => (DeletePostDialogVisible.value = true),
    },
    {
      active: !isOwned.value && !props.post.is_hidden,
      label: "Hide this post",
      border: false,
      description: "Remove this post from your feed.",
      clickEvent: handleHidePost,
      type: "button",
      icon: "fa-sharp fa-regular fa-eye-slash text-primary",
    },
    {
      active: !isOwned.value,
      label: "Report this post",
      border: false,
      description: "This will automatically hide this post as well.",
      type: "button",
      clickEvent: handleReportPost,
      icon: "fa-sharp fa-regular fa-brake-warning text-primary",
    },
    {
      active: !isOwned.value,
      label: "Copy Link",
      border: false,
      description: "click to copy link to the post",
      type: "button",
      clickEvent: async () => {
        if (navigator.clipboard) {
          try {
            const url = route("social_posts.index", props.post.id);
            await navigator.clipboard.writeText(url);
            success(`${url} copied to clipboard!`);
          } catch (err) {
            console.error("Failed to copy text to clipboard:", err);
            error("Copying to clipboard is not supported.");
          }
        } else {
          // Fallback if Clipboard API is not supported (i.e., older mobile browsers)
          const textArea = document.createElement("textarea");
          textArea.value = route("social_posts.index", props.post.id);
          document.body.appendChild(textArea);
          textArea.select();
          try {
            document.execCommand("copy");
            success("URL copied to clipboard!");
          } catch (err) {
            console.error("Fallback: Oops, unable to copy", err);
            error("Copying to clipboard failed.");
          }
          document.body.removeChild(textArea);
        }
      },
      icon: "fa-sharp fa-regular fa-copy text-primary",
    },
    {
      active: isOwned.value,
      label: "Edit Content",
      border: true,
      description: "Edit this portfolio content",
      type: "button",
      icon: "fa-sharp fa-regular fa-pen text-primary",
      clickEvent: handleOpenContentDialog,
    },
    {
      active: !isOwned.value && !isFriend.value && !has_friend_request.value,
      label: `Connect with ${postOwner.value.first_name}`,
      border: true,
      description: null,
      type: "button",
      clickEvent: handleConnectUser,
      icon: "fa-regular fa-user-plus",
    },
    {
      active:
        !isOwned.value &&
        !isFriend.value &&
        friend_requests.value.sent.findIndex(
          (c) => c.recipient.id === postOwner.value.id,
        ) !== -1,
      label: `Withdraw connection request`,
      border: true,
      description: null,
      type: "button",
      clickEvent: handleWithdrawConnRequest,
      icon: "fa-regular fa-user-minus",
    },
    {
      active:
        !isOwned.value &&
        !isFriend.value &&
        friend_requests.value.received.findIndex(
          (c) => c.sender.id === postOwner.value.id,
        ) !== -1,
      label: `Accept connection request`,
      border: true,
      description: null,
      type: "button",
      clickEvent: handleAcceptConnectionRequest,
      icon: "fa-regular fa-user-check",
    },
    {
      active: !isOwned.value && isFriend.value,
      label: `Remove Connection`,
      border: true,
      description: null,
      type: "button",
      clickEvent: handleRemoveConnection,
      icon: "fa-regular fa-user-xmark",
    },
    {
      active: !isOwned.value,
      label: `Block ${postOwner.value.first_name}`,
      border: true,
      description: null,
      type: "button",
      icon: "fa-sharp fa-regular fa-shield text-red-700",
      clickEvent: () => (BlockUserDialogVisible.value = true),
      danger: true,
    },
  ];
});

/* ---------- METHODS AND FUNCTIONS ---------- */

const handleOpenContentDialog = async () => {
  transformedData.value = await transform(props.post);
  if (props.post.content.content_type === "collection")
    dialogStore.show(DialogType.UPLOAD_CONTENT_COLLECTION, props.post.id, {
      content: transformedData.value,
    });
  else {
    dialogStore.show(DialogType.UPLOAD_CONTENT, props.post.id, {
      content: transformedData.value,
    });
    dialogStore.setEvents(DialogType.UPLOAD_CONTENT, {
      close: () => dialogStore.hide(DialogType.UPLOAD_CONTENT),
    });
  }
};
/* ----- Ellipsis Menu Methods ----- */

const handleDeletePost = () => {
  router.post(
    route("social_posts.delete", props.post.id),
    { redirect: true },
    {
      onSuccess() {
        if (route().current() === "social_posts.index") {
          router.get(route("home"));
        }
        homeFeedStore.removePost(HomeFeedType.Community, props.post.id);
        DeletePostDialogVisible.value = false;
        emit("delete", props.post.id);
      },
    },
  );
};

const handleBlockUser = _debounce(() => {
  try {
    UserStore.blockUser(postOwner.value.id);
    homeFeedStore.hidePostsForUser(HomeFeedType.Community, postOwner.value.id);
  } catch (error) {
    console.error(error);
  }
}, 1000);

const handleConnectUser = _debounce(async () => {
  try {
    UserStore.connectUser(postOwner.value.id);
  } catch (error) {
    console.log(error);
  }
}, 1000);
const handleWithdrawConnRequest = () => {
  const connection = friend_requests.value.sent.find(
    (c) => c.recipient.id === postOwner.value.id,
  ) as ConnectionsInterface;
  UserStore.withdrawConnectionRequest(connection);
};
const handleAcceptConnectionRequest = () => {
  const connection = friend_requests.value.received.find(
    (c) => c.sender.id === postOwner.value.id,
  ) as ConnectionsInterface;
  UserStore.acceptConnectionRequest(connection);
};
const handleRemoveConnection = _debounce(async () => {
  try {
    UserStore.removeConnection(postOwner.value.id);
  } catch (error) {
    console.log(error);
  }
}, 250);

const handleEditPost = () => {};

const handleHidePost = async () => {
  props.post.is_hidden = true;
  await homeFeedStore.hidePost(HomeFeedType.Community, props.post.id);
  success("Post has been hidden!");
};

const handleUnhidePost = async () => {
  props.post.is_hidden = false;
  await homeFeedStore.unhidePost(HomeFeedType.Community, props.post.id);
  success("Post has been unhidden!");
};

const handleReportPost = async () => {
  dialogStore.show(DialogType.REPORT_CONTENT, props.post.id, {
    type: "post",
    data: props.post.id,
  });
};

const toggle = (event: Event) => {
  menu.value.toggle(event);
};
</script>
