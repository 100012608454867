<template>
  <div class="container m-0 p-0 !w-full" ref="container">
    <div v-if="shouldShowPlusOnePlusTwo" class="overflow-container !w-full">
      <template v-for="(item, index) in visibleItems" :key="item + index">
        <div class="item" :style="{ fontSize: fontSize, color: textColor }">
          {{ item }}
        </div>
        <span
          class="separator"
          :style="{
            backgroundColor: separatorColor,
            height: separatorHeight,
            width: separatorWidth,
          }"></span>
      </template>
      <div
        class="plus-one-plus-two"
        :style="{ fontSize: fontSize, color: textColor }">
        +{{ hiddenCount }}
      </div>
    </div>
    <div v-else class="overflow-container !w-full">
      <template v-for="(item, index) in visibleItems" :key="item + index">
        <div class="item" :style="{ fontSize: fontSize, color: textColor }">
          {{ item }}
        </div>
        <span
          class="separator"
          :style="{
            backgroundColor: separatorColor,
            height: separatorHeight,
            width: separatorWidth,
          }"></span>
      </template>
    </div>
    <canvas ref="canvas" class="hidden"></canvas>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  computed,
  onUpdated,
  watch,
  inject,
  nextTick,
  onUnmounted,
} from "vue";
import { useEventsStore } from "@/store/Events.ts";

const container = ref(null);
const canvas = ref(null);

const emitter = computed(() => useEventsStore().emitter);
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  fontSize: {
    type: String,
    default: "inherit",
  },
  textColor: {
    type: String,
    default: "white",
  },
  separatorColor: {
    type: String,
    default: "white",
  },
  separatorHeight: {
    type: String,
    default: "3px",
  },
  separatorWidth: {
    type: String,
    default: "3px",
  },
});

const visibleItems = ref([]);
const hiddenCount = ref(0);

onMounted(() => {
  nextTick(() => {
    emitter.value.on("resize", computeVisibleItems);
    computeVisibleItems();
  });
});
onUnmounted(() => {
  emitter.value.off("resize", computeVisibleItems);
});
const computeVisibleItems = () => {
  if (!container.value || !canvas.value) return;
  const containerWidth = container.value.offsetWidth;
  let remainingWidth = containerWidth;
  let tempVisibleItems = [];
  let count = 0;
  let visibleWidth = 0;

  for (let i = 0; i < props.items.length; i++) {
    const itemWidth = measureTextWidth(props.items[i]);

    if (visibleWidth + itemWidth <= containerWidth) {
      tempVisibleItems.push(props.items[i]);
      visibleWidth += itemWidth;

      if (i === props.items.length - 1) {
        // If it's the last item, check if there's enough space for it
        if (visibleWidth <= containerWidth) {
          // If there's enough space for the last item, break the loop
          break;
        } else {
          // If there's not enough space for the last item, truncate it
          const lastItem = tempVisibleItems.pop();
          const truncatedLastItem = truncateItem(
            lastItem,
            containerWidth - visibleWidth,
          );
          tempVisibleItems.push(truncatedLastItem);
          count = 1;
          break;
        }
      }
    } else {
      if (tempVisibleItems.length === 0) {
        // Handle case where single item is too long
        const truncatedItem = truncateItem(props.items[i], containerWidth);
        tempVisibleItems.push(truncatedItem);
        count = 1;
      } else {
        count = props.items.length - tempVisibleItems.length;
      }
      break;
    }
  }

  hiddenCount.value = count;
  visibleItems.value = tempVisibleItems;
};

const truncateItem = (item, containerWidth) => {
  const ctx = canvas.value?.getContext("2d");
  let truncatedText = "";
  if (ctx) {
    ctx.font =
      window.getComputedStyle(container.value).fontSize + " sans-serif";
    for (let i = 0; i < item.length; i++) {
      const width = ctx.measureText(truncatedText + item[i]).width;
      if (width <= containerWidth) {
        truncatedText += item[i];
      } else {
        truncatedText += "...";
        break;
      }
    }
  }
  return truncatedText;
};
const measureTextWidth = (text) => {
  const ctx = canvas.value?.getContext("2d");
  if (ctx) {
    ctx.font =
      window.getComputedStyle(container.value).fontSize + " sans-serif";
    return ctx.measureText(text).width;
  }
};

const shouldShowPlusOnePlusTwo = computed(() => {
  return hiddenCount.value > 0;
});

watch(
  () => props.items,
  () => {
    computeVisibleItems();
  },
  { deep: true },
);
</script>

<style scoped lang="scss">
.container {
  position: relative;
  overflow: hidden;
}

.overflow-container {
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  overflow: hidden;
  align-items: center;
}

.item {
  white-space: nowrap;
  overflow: hidden;

  &:last-of-type {
    & + .separator {
      display: none;
    }
  }
}

.plus-one-plus-two {
  white-space: nowrap;
  overflow: hidden;
}

.separator {
  margin: auto 5px;
  /* Adjust margin as needed */
  border-radius: 50%;
  display: inline-block;
}

.hidden {
  position: absolute;
  visibility: hidden;
}
</style>
