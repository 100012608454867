<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'py-8 px-4 w-full flex flex-col relative gap-4',
      root: '  !bg-white max-w-[450px] w-full rounded-2xl  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section
      id="header"
      class="rounded-t-2xl flex flex-col items-center justify-center gap-4">
      <img
        src="https://ik.imagekit.io/bizapp/Sillhouette.png"
        class="size-16 rounded-full"
        alt="" />
      <h1 class="text-xl font-semibold">Action Required!</h1>
      <div
        v-if="uploading"
        class="flex gap-2 items-center justify-center text-green-700">
        <p class="text-sm">Uploading</p>
        <i class="fa-solid fa-spinner-third fa-spin"></i>
      </div>
    </section>
    <section id="content" class="px-2 flex flex-col gap-2 flex-1">
      <p class="text-primary/80 text-sm text-center">
        Your profile is currently invisible on the network page.
        <br />
        Upload a profile picture now to be discoverable
      </p>
    </section>
    <section id="footer" class="mt-4 rounded-b-2xl flex gap-2 w-full">
      <button
        class="w-1/2 py-1.5 text-primary border border-primary rounded-md"
        @click="dialogStore.hide(DialogType.UPLOAD_PROFILE)">
        I'll do it later
      </button>
      <FileUpload
        :pt="{ uploadicon: 'hidden', root: '!w-1/2' }"
        :ptOptions="{ mergeProps: true }"
        mode="basic"
        accept="image/*"
        customUpload
        :auto="true"
        chooseLabel="Upload & be seen!"
        @uploader="handleUpload"
        class="!w-full !py-1.5 text-white !font-normal bg-switcherBlue-500 rounded-md" />
    </section>
  </Dialog>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import { useDialogStore, DialogType } from "@/store/Dialog";
import { upload } from "@/utils/imageKit";
import { useToastr } from "@/composables/useToastr";
import { API } from "@/core/api";
const usersApi = new API.Users();
const { success, error } = useToastr();
const dialogStore = useDialogStore();
const uploading = ref(false);
const handleUpload = async (event) => {
  event.files.forEach((file, idx) => {
    const reader = new FileReader();
    reader.onerror = function (error) {
      console.error(error);
    };
    reader.onload = async function (e) {
      const base64data = e.target.result;
      try {
        uploading.value = true;
        const IK_response = await upload(base64data, "AVATAR_IMAGE");
        if (IK_response && IK_response.error)
          throw new Error(IK_response.error);
        const response = await usersApi(IK_response.url);
        if (response.error) throw new Error(response.error);
        success("Profile picture uploaded successfully!");
        uploading.value = false;
        dialogStore.hide(DialogType.UPLOAD_PROFILE);
      } catch (err) {
        uploading.value = false;
        error("An error has occurred!");
      }
    };
    reader.onerror = function (error) {
      console.error(error);
    };
    reader.readAsDataURL(file);
  });
};
</script>
