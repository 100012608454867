<template>
  <div class="bg-main-lightBg rounded-md">
    <section
      id="header"
      class="px-4 py-2 flex justify-between items-center text-sm"
      :class="{
        'text-white': collaboration.show_on_portfolio,
        'text-main-disabled': !collaboration.show_on_portfolio,
      }">
      <div class="flex gap-1.5 items-center">
        <i :class="iconMap[collaboration.content_type]"></i>
        <div class="rounded-full bg-white size-1"></div>
        <p>{{ collaboration.content.bizcard.user.full_name }}</p>
      </div>
      <i class="fa fa-ellipsis text-sm cursor-pointer" @click="toggleMenu"></i>
    </section>
    <hr class="border-main-divider/50" />
    <section id="content" class="px-4 py-2 flex items-center gap-4">
      <InputSwitch
        v-model="model"
        :pt="{ root: '!w-11 !h-6' }"
        :ptOptions="{ mergeProps: true }" />
      <div class="w-[70%] space-y-2">
        <p
          class="text-lg line-clamp-2"
          :class="{
            '!text-white': collaboration.show_on_portfolio,
            '!text-main-disabled': !collaboration.show_on_portfolio,
          }">
          {{ collaboration.content.title }}
        </p>
        <div class="flex gap-2 items-center flex-wrap">
          <Chip
            v-for="role in collaboration.roles"
            :key="role.name"
            :label="role.name"
            :pt="{
              root: cn(
                '!rounded-md !text-sm !font-light !bg-main-lightBg shadow-default brightness-150',
                {
                  '!text-white': collaboration.show_on_portfolio,
                  '!text-main-disabled': !collaboration.show_on_portfolio,
                },
              ),
            }"
            :ptOptions="{ mergeProps: true }" />
        </div>
      </div>
      <img
        :src="
          collaboration.content.image_kit_id ||
          collaboration.content.thumbnail_image_kit_id ||
          collaboration.content.image_kit_id_16_9 ||
          collaboration.content.image_kit_ids?.[0]
        "
        @error="(e) => (e.target.src = DefaultBg)"
        alt=""
        class="w-[25%] aspect-square object-cover rounded-md shadow-default" />
    </section>
  </div>
  <Menu
    id="overlay_menu"
    ref="contentMenu"
    :model="ellipsis_menu.filter((i) => i.active)"
    class="w-[21rem] content_menu overflow-hidden shadow-[0_2px_15px_0_rgba(0,0,0,0.3)]"
    :popup="true"
    :pt="{
      content: 'bg-main-lightBg hover:!bg-main-lightBg/70',
    }">
    <template #item="{ item }">
      <div
        class="p-2 flex items-center gap-2 text-white cursor-pointer"
        @click="item.clickEvent">
        <CircleIcon
          size="2.5rem"
          class="bg-main-lightBg hover:!bg-main-lightBg/80 brightness-150">
          <i :class="item.icon"></i>
        </CircleIcon>
        <p class="">{{ item.title }}</p>
      </div>
    </template>
  </Menu>
  <RemoveCollabConfirm
    v-if="RemoveCollabConfirmVisible"
    :content="collaboration.content"
    @close="RemoveCollabConfirmVisible = false" />
</template>
<script setup lang="ts">
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { computed, ref } from "vue";
import { useUserBizcardStore } from "@/store/UserBizcard";
import RemoveCollabConfirm from "@/components/dialogs/bizcard/portfolio/RemoveCollabConfirm.vue";
import DefaultBg from "@/assets/images/bgPics/web-bg-min.jpg";
import { cn } from "@/utils/cn";
const bizcardStore = useUserBizcardStore();
const props = defineProps({
  collaboration: {
    type: Object,
    required: true,
  },
});
const RemoveCollabConfirmVisible = ref(false);
const model = defineModel();
const iconMap = {
  video_gallery: "fa fa-rectangle-history",
  video: "fa fa-clapperboard-play",
  image_gallery: "fal fa-images",
  image: "fal fa-image",
  link_gallery: "fa fa-rectangle-history",
  link: "fa fa-chain",
  collection: "fa fa-rectangle-history",
};
const contentMenu = ref();

const ellipsis_menu = computed(() => [
  {
    active: !props.collaboration.show_on_portfolio,
    title: "Show content",
    icon: "fa fa-eye",
    clickEvent: () => {
      toggleShow();
    },
  },
  {
    active: props.collaboration.show_on_portfolio,
    title: "Hide content",
    icon: "fa fa-eye-slash",
    clickEvent: () => {
      toggleShow();
    },
  },
  {
    active: true,
    title: "Remove me as a collaborator",
    icon: "fa fa-user-xmark",
    clickEvent: () => {
      RemoveCollabConfirmVisible.value = true;
    },
  },
]);
const toggleMenu = (event) => {
  if (contentMenu.value) contentMenu.value.toggle(event);
};

const toggleShow = () => {
  model.value = !model.value;
};
</script>
