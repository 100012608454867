<template>
  <ConfirmDialog
    color="danger"
    action="Yes"
    cancel="No"
    @confirm="handleConfirm"
    @close="$emit('close')">
    <template #icon>
      <div
        class="size-16 p-1 bg-red-700 rounded-full flex items-center justify-center">
        <i class="fa-2x fa fa-trash text-white"></i>
      </div>
    </template>
    <template #title>Remove collaboration?</template>
    <template #description>
      <p>
        Removing yourself as a collaborator would mean you will no longer be
        listed on the content page or be able to use it on your portfolio. This
        action cannot be undone.
        <br />
        <span class="font-medium text-base">Are you sure?</span>
      </p>
    </template>
  </ConfirmDialog>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import ConfirmDialog from "@/components/dialogs/Confirm.vue";
import { useUserBizcardStore } from "@/store/UserBizcard";
const props = defineProps(["content"]);
const emit = defineEmits(["close"]);
const bizcardStore = useUserBizcardStore();
const handleConfirm = async () => {
  let response = await bizcardStore.removeAsCollaborator(props.content.id);
  emit("close");
};
</script>
