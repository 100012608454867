<template>
  <div class="w-full flex flex-col relative">
    <section
      id="header"
      class="p-2 bg-surface-50 border-b-[1px] border-surface-300 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">Representation</h1>
        <CircleIcon
          class="bg-surface-100 text-primary-500 hover:bg-surface-200"
          @click="$emit('close')">
          <i class="far fa-times"></i>
        </CircleIcon>
      </div>
    </section>
    <section
      id="content"
      class="px-4 py-2 flex flex-col gap-2 overflow-y-auto scroll-hidden max-h-[80vh]">
      <InfoCard
        customClass="bg-switcherBlue-500/20 rounded-lg py-2 cursor-pointer hover:bg-switcherBlue-500/30"
        @click="$emit('changeTo', 'manage_representation')">
        <template #content>
          <div class="flex gap-2 items-center">
            <i class="fal fa-plus-circle text-switcherBlue-500 text-lg"></i>
            <span class="text-switcherBlue-500">Add Representation</span>
          </div>
        </template>
      </InfoCard>
      <draggableComponent
        class="draggable-container flex flex-col gap-2"
        item-key="order"
        :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }"
        v-model="form.representations"
        v-bind="dragOptions"
        handle=".drag-handle">
        <template #item="{ element, index }">
          <InfoCard customClass="px-6 py-4">
            <template #content>
              <div class="flex gap-3 items-center">
                <i
                  class="fa fa-grip-dots-vertical text-main-disabled drag-handle cursor-grabbing"></i>
                <Avatar
                  :image="
                    element.contact_information.company.image ?? NoCompanyImage
                  "
                  class="!size-10 drag-handle cursor-grabbing"
                  shape="circle"
                  @error="handleCompanyError" />
                <div
                  class="flex-1 flex flex-col gap-0.5 drag-handle cursor-grabbing">
                  <p class="uppercase text-surface-500 text-xs leading-none">
                    {{ element.title }}
                  </p>
                  <div class="text-sm font-medium leading-tight">
                    {{ element.contact_information.company.name }}
                  </div>
                  <span class="text-primary-500 text-xs font-normal">
                    {{ element.representatives.length }} Representative{{
                      element.representatives.length > 1 ||
                      element.representatives.length == 0
                        ? "s"
                        : ""
                    }}
                  </span>
                </div>
                <i
                  class="fal fa-pencil text-primary-500 cursor-pointer text-lg hover:text-primary-700"
                  @click="
                    () => $emit('changeTo', 'manage_representation', index)
                  "></i>
                <CircleIcon
                  class="bg-primary-500 text-white hover:bg-primary-600"
                  @click="handleRemove(index)"
                  size="1.5rem">
                  <i class="far fa-times text-xs"></i>
                </CircleIcon>
              </div>
            </template>
          </InfoCard>
        </template>
      </draggableComponent>
    </section>
    <section
      id="footer"
      class="bg-surface-50 border-t border-surface-300 sticky bottom-0 z-20 rounded-b-[16px]">
      <div class="flex justify-end items-center py-2.5 px-4 gap-2">
        <Button
          label="Save"
          :disabled="!canSave"
          :loading="form.processing"
          class="min-w-[150px]"
          @click="handleSave"></Button>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import CircleIcon from "@/components/icons/CircleIcon.vue";
import InfoCard from "@/components/general/CustomCard.vue";
import draggableComponent from "vuedraggable";
import NoCompanyImage from "@/assets/images/new_icons/company_dummy.svg";
import { router, useForm } from "@inertiajs/vue3";
import { computed } from "vue";
import { watch } from "vue";
import { route } from "ziggy-js";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";

/* -------- PROPS -------- */
const model = defineModel({
  type: Object,
  required: true,
});

const emit = defineEmits(["close", "changeTo"]);

/* -------- COMPOSABLE -------- */
const bizCardInfoAPI = new API.BizcardInfo();
const { success, error } = useToastr();

/* -------- STATE -------- */
const dragOptions = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost",
};
const form = useForm({
  representations: model.value.representations,
});
const canSave = computed(
  () => (form.isDirty || model.value.isDirty) && !form.processing,
);

/* -------- METHODS -------- */
const handleSave = async () => {
  form.processing = true;
  try {
    const response = await bizCardInfoAPI.updateRepresentations(form);
    if (response.data) {
      success("Representations updated successfully");
      form.isDirty = false;
      router.reload();
      emit("close");
    } else if (response.error) {
      error(response.error);
    } else {
      error("Failed to update representations: Unknown error");
    }
  } catch (e: any) {
    console.error(e);
  } finally {
    form.processing = false;
  }
};

const handleRemove = (index: number) => {
  form.representations.splice(index, 1);
};

const handleCompanyError = (event: any) => {
  console.log("[DEBUG] :: CompanyImageError :: ", event);
  event.target.src = NoCompanyImage;
};

/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
watch(
  () => form.representations,
  (newValue) => {
    model.value.representations = newValue;
  },
);
</script>
