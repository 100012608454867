<template>
  <section id="content" class="overflow-y-auto scroll-hidden">
    <slot />
    <div ref="scrollTrigger" id="scrollTrigger" class="h-8"></div>
    <div v-if="loading && showLoading" class="w-full flex items-center justify-center">
      <i class="fa-duotone fa-solid fa-spinner-third fa-spin text-2xl"></i>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";

const scrollTrigger = ref<HTMLElement | null>(null);
const loading = ref(false);
const props = defineProps({
  callback: {
    type: Function,
    default: () => {},
  },
  showLoading: {
    type: Boolean,
    default: true,
  },
});
const scrollTriggerAction = () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(async (entry) => {
      if (entry.isIntersecting && !loading.value) {
        loading.value = true;
        await props.callback();
        loading.value = false;
      }
    });
  });

  if (scrollTrigger.value) {
    observer.observe(scrollTrigger.value);
  }
};

onMounted(() => {
  scrollTriggerAction();
});
</script>
