<template>
  <div class="bg-white/10 rounded p-2" :key="collaborator.id">
    <div class="flex items-center gap-2">
      <div class="self-start">
        <Avatar
          :image="collaborator.avatar_image"
          size="large"
          shape="circle"
          :pt="{
            root: 'bg-transparent',
          }"
          :ptOptions="{
            mergeProps: true,
          }" />
      </div>
      <div class="flex flex-col gap-2 w-full">
        <div class="text-white flex gap-4">
          <p>
            {{ collaborator.full_name }}
          </p>
          <span
            v-if="is_owner"
            class="font-[400] text-[10px] bg-[#3888ff65] px-[8px] py-[4px] rounded-[20px]">
            Owner
          </span>
        </div>
        <div class="flex gap-2 flex-wrap">
          <RolesChip
            v-for="(role, index) in collaborator.roles"
            :key="role.id"
            :label="role.name"
            :removable="is_owner || collaborator.roles.length > 1"
            @remove="() => handleRemoveRole(collaborator, role)"
            :index="index" />
          <Button
            type="button"
            rounded
            icon="far fa-plus"
            label="Add Role"
            v-if="showAddRole"
            @click="handleSelectUser(collaborator)"
            class="border-0 !px-3 !py-1 !text-sm focus:ring-0" />
        </div>
      </div>

      <div class="ms-auto" v-if="!is_owner">
        <CircleIcon
          class="bg-white/10 text-surface-300"
          @click="() => handleRemoveCollab(collaborator.id)">
          <i class="fas fa-times"></i>
        </CircleIcon>
      </div>
    </div>
    <p
      v-if="!collaborator.roles.length && !is_owner"
      class="text-red-500 basis-[100%]">
      Please select atleast one role.
    </p>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import type { UserInterface } from "@/core/interfaces";
import type { PropType } from "vue";
import RolesChip from "@/components/chips/collaborators/Roles.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { useAuthStore } from "@/store/Auth";
const authStore = useAuthStore();
const props = defineProps({
  collaborator: {
    type: Object as PropType<UserInterface>,
    required: true,
  },
  handleRemoveCollab: {
    type: Function,
    required: true,
  },
  handleRemoveRole: {
    type: Function,
    required: true,
  },
  handleSelectUser: {
    type: Function,
    required: true,
  },
  showAddRole: {
    type: Boolean,
    required: false,
    default: true,
  },
});
const is_owner = computed(() => authStore.getUser.id === props.collaborator.id);
</script>
