<template>
  <InfoCard v-if="is_editing || form.availability">
    <template #content>
      <div class="flex gap-4 items-center">
        <i
          :class="
            cn(
              'fa fa-circle text-xs',
              form.availability ? 'text-green-500' : 'text-gray-500',
            )
          "></i>
        <p class="">Available to work</p>
        <label
          v-if="is_editing && is_own_profile"
          class="relative inline-flex items-center cursor-pointer h-max ms-auto">
          <input
            type="checkbox"
            class="sr-only peer"
            v-model="form.availability"
            @change="handleChange" />
          <div
            class="w-[36px] h-[20px] bg-gray-400 rounded-full peer-focus:ring-none peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-main-success disabled:peer-checked:bg-main-disabled"></div>
        </label>
      </div>
    </template>
  </InfoCard>
</template>

<script lang="ts" setup>
import { reactive, ref, computed, onMounted, watch, PropType } from "vue";
import InfoCard from "@/components/general/CustomCard.vue";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { API } from "@/core/api";
import { router, useForm } from "@inertiajs/vue3";
import { useToastr } from "@/composables/useToastr";
import { useAuthStore } from "@/store/Auth";
import { cn } from "@/utils/cn";

/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});

/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();
const authStore = useAuthStore();
const bizCardInfoAPI = new API.BizcardInfo();
const { error, success } = useToastr();
/* -------- STATE -------- */
const is_editing = computed(() => bizcardStore.getIsEditing);
const is_own_profile = computed(() => bizcardStore.own_profile);
const form = useForm({
  availability: props.card.available_to_work,
});
/* -------- METHODS -------- */
const handleChange = async (e: any) => {
  try {
    const response = await bizCardInfoAPI.updateAvailability({
      availability: form.availability,
    });
    if (response.error) throw new Error("An error has occurred");
    else {
      success("Availability updated successfully");
      router.reload({ only: ["card"] });
      if (authStore.user) {
        authStore.user.bizcard.available_to_work = form.availability;
      }
    }
  } catch (err: any) {
    error(err?.message ?? "An error has occurred");
  }
};
/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
watch(
  () => authStore.user,
  (newVal) => {
    form.availability = newVal?.bizcard?.available_to_work ?? false;
  },
  { deep: true },
);
</script>
