<template>
  <div class="flex flex-col">
    <div
      class="lg:mx-8 lg:mb-8 relative bg-transparent w-full lg:w-5/6 self-center rounded-b-lg"
    >
      <section
        id="hero"
        class="items-end bg-center bg-no-repeat bg-cover mx-auto max-w-screen overflow-hidden p-[50px] !h-[calc(100vh-100px)] lg:!h-[calc(100vh-100px)] flex w-full rounded-b-lg"
        :style="`background-image: url(${model.image_kit_id_16_9})`"
      >
        <div class="absolute inset-0">
          <div class="h-full w-full radial-gradient-overlay rounded-b-lg"></div>
        </div>
      </section>

      <div
        class="absolute bottom-20 left-16 flex-col text-white max-w-[35%] hidden lg:flex"
      >
        <div class="text-white text-2xl font-bold font-['Poppins'] tracking-wide">
          {{ model.title }}
        </div>
        <div class="flex gap-3 pb-3">
          <ProjectType
            v-if="model.project_types.length"
            v-model="model.project_types"
          ></ProjectType>
        </div>
        <div class="text-white text-base font-light">
          {{ model.description }}
        </div>
        <div class="pt-5 flex items-center gap-2">
          <img
            :src="model.bizcard.user.avatar_image"
            class="size-9 rounded-full shadow-lg object-cover"
          />
          <span class="text-white font-light">
            {{ model.bizcard.user.full_name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Roles from "@/components/general/Roles.vue";
import ProjectType from "./parts/ProjectType.vue";

const model = defineModel({ type: Object, required: true });
</script>
<style scoped>
.radial-gradient-overlay {
  background: radial-gradient(
    111% 190% at 7.9% 106%,
    rgba(0, 0, 0, 0.8) 20.6%,
    transparent 43%
  );
  height: 100%;
  width: 100%;
}
</style>
