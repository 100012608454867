import { defineStore } from "pinia";
// DIALOGS COMPONENTS
import EXTERNAL_LINK from "@/components/dialogs/ExternalLink.vue";
import MANAGE_GROUP from "@/components/dialogs/contacts/ManageGroup.vue";
import MANAGE_GROUPS from "@/components/dialogs/contacts/ManageGroups.vue";
import INVITATIONS from "@/components/dialogs/invitations/Main.vue";
import MOBILE_APP from "@/components/dialogs/MobileApp.vue";
import MANAGE_LINK from "@/components/dialogs/bizcard/home/ManageLink.vue";
import MANAGE_BIZCARD from "@/components/dialogs/bizcard/home/ManageBizcard.vue";
import UPLOAD_CONTENT from "@/components/dialogs/content/Main.vue";
import UPLOAD_CONTENT_COLLECTION from "@/components/dialogs/collection/Index.vue";
import POST_MANAGER from "@/components/dialogs/social-posts/EditPost.vue";
import CONFIRM_COLLAB from "@/components/dialogs/ConfirmCollaboration.vue";
import REPORT_CONTENT from "@/components/dialogs/reports/Content.vue";
import REPORT_USER from "@/components/dialogs/reports/User.vue";
import CONTENT_SHARE_POST from "@/components/dialogs/SharePost.vue";
import UPLOAD_PROFILE from "@/components/dialogs/UploadProfile.vue";
import { markRaw } from "vue";
// ------------------------------------------------------------
export enum DialogType {
  UPLOAD_CONTENT,
  POST_MANAGER,
  CONTENT_SHARE_POST,
  CONTENT_MANAGER,
  UI_BLOCK_FULL,
  UPLOAD_CONTENT_COLLECTION,
  UPLOAD_CONTENT_CHILD_DESCRIPTION,
  EXTERNAL_LINK,
  MANAGE_GROUP,
  MANAGE_GROUPS,
  INVITATIONS,
  MOBILE_APP,
  MANAGE_LINK,
  MANAGE_BIZCARD,
  CONFIRM_COLLAB,
  REPORT_USER,
  REPORT_CONTENT,
  UPLOAD_PROFILE,
}

interface DialogState {
  id?: number | string;
  show: boolean;
  events?: any;
  data: any;
  component?: any;
}

interface State {
  [key: number]: DialogState;
}

export const useDialogStore = defineStore("dialogStore", {
  state: (): State => ({
    [DialogType.UPLOAD_CONTENT]: {
      show: false,
      data: null,
      component: markRaw(UPLOAD_CONTENT),
    },
    [DialogType.POST_MANAGER]: {
      show: false,
      data: null,
      component: markRaw(POST_MANAGER),
    },
    [DialogType.CONTENT_SHARE_POST]: {
      show: false,
      data: null,
      component: markRaw(CONTENT_SHARE_POST),
    },
    [DialogType.CONTENT_MANAGER]: {
      show: false,
      data: null,
    },
    [DialogType.UPLOAD_CONTENT_COLLECTION]: {
      show: false,
      data: null,
      component: markRaw(UPLOAD_CONTENT_COLLECTION),
    },
    [DialogType.UI_BLOCK_FULL]: {
      show: false,
      data: null,
    },
    [DialogType.UPLOAD_CONTENT_CHILD_DESCRIPTION]: {
      show: false,
      data: null,
    },
    [DialogType.EXTERNAL_LINK]: {
      show: false,
      data: null,
      component: markRaw(EXTERNAL_LINK),
    },
    [DialogType.CONFIRM_COLLAB]: {
      show: false,
      data: null,
      component: markRaw(CONFIRM_COLLAB),
    },
    [DialogType.MANAGE_GROUP]: {
      show: false,
      data: null,
      component: markRaw(MANAGE_GROUP),
    },
    [DialogType.MANAGE_GROUPS]: {
      show: false,
      data: null,
      component: markRaw(MANAGE_GROUPS),
    },
    [DialogType.INVITATIONS]: {
      show: false,
      data: null,
      component: markRaw(INVITATIONS),
    },
    [DialogType.MOBILE_APP]: {
      show: false,
      data: null,
      component: markRaw(MOBILE_APP),
    },
    [DialogType.MANAGE_LINK]: {
      show: false,
      data: null,
      component: markRaw(MANAGE_LINK),
    },
    [DialogType.MANAGE_BIZCARD]: {
      show: false,
      data: null,
      component: markRaw(MANAGE_BIZCARD),
    },
    [DialogType.REPORT_USER]: {
      show: false,
      data: null,
      component: markRaw(REPORT_USER),
    },
    [DialogType.REPORT_CONTENT]: {
      show: false,
      data: null,
      component: markRaw(REPORT_CONTENT),
    },
    [DialogType.UPLOAD_PROFILE]: {
      show: false,
      data: null,
      component: markRaw(UPLOAD_PROFILE),
    },
  }),
  getters: {
    getActiveDialogs: (state) => {
      const active: DialogState[] = [];
      for (const type in Object.entries(DialogType).filter(([key, value]) =>
        isNaN(Number(key)),
      )) {
        if (state[type]?.show && state[type]?.hasOwnProperty("component")) {
          active.push(state[type]);
        }
      }
      return active;
    },
  },
  actions: {
    show(dialog: DialogType, id?: number | string, content?: any) {
      this[dialog] = {
        ...this[dialog],
        show: true,
        data: content,
        id,
      };
    },
    hide(dialog: DialogType) {
      this[dialog] = {
        ...this[dialog],
        show: false,
        data: null,
      };
    },
    setEvents(dialog: DialogType, events: any) {
      this[dialog].events = events;
    },
    isOpened(dialog: DialogType, id?: number | string) {
      return id
        ? this[dialog].id == id && this[dialog].show
        : this[dialog].show;
    },
    getData(dialog: DialogType) {
      return this[dialog].data;
    },
  },
});
