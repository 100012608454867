<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-4 w-full flex flex-col relative gap-4',
      root: 'bg-lightBg-400 max-w-[500px] max-h-screen w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <h1 class="font-semibold text-center capitalize">
      {{ content.type }} Report Submitted
    </h1>
    <p class="text-center text-sm text-primary/80">
      Thank your for helping us ensure a respectful community. Our team will
      review the reported content promptly. We value your privacy, and your
      report will remain confidential.
    </p>
    <hr v-if="content.type === 'content'" class="border-main-divider" />
    <p
      v-if="content.type === 'content'"
      class="text-center text-sm text-primary/80">
      This content will be removed from your view, but it will remain visible to
      others until our review in complete.
    </p>
    <button
      class="py-2 w-full border border-primary text-primary rounded-md"
      @click="$emit('close')">
      Done
    </button>
  </Dialog>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import { useDialogStore, DialogType } from "@/store/Dialog";

const emit = defineEmits(["close"]);
const content = computed(() =>
  useDialogStore().getData(DialogType.REPORT_CONTENT),
);
</script>
