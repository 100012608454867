import { defineStore } from "pinia";
import { router } from "@inertiajs/vue3";
interface State {
  history: Array<string>;
}

export const useRouterStore = defineStore("routerStore", {
  state: (): State => ({
    history: [],
  }),
  getters: {
    previous: (state) => (state.history.length ? state.history.at(-2) : null),
  },
  actions: {
    pushState(route: string) {
      this.history.push(route);
    },
    back() {
      if (
        !this.previous ||
        this.previous === "login" ||
        this.previous === "register"
      )
        router.visit(route("home"));
      else {
        this.history.pop();
        window.history.back();
      }
    },
  },
});
