<template>
  <div
    class="w-full fixed top-0 bg-main-lightBg flex justify-center items-center z-[100]">
    <Menubar :model="menu" class="h-14 shadow-lg p-5 flex-1 max-w-[1920px]">
      <template #start>
        <img
          :src="bizlyLogoWhite"
          alt="Bizly Logo"
          class="h-[70%] w-auto pr-8" />
      </template>
      <template #item="{ item, props, hasSubmenu, root }">
        <div
          :class="
            cn(
              'h-14 align-items-center border-b-2 font-semibold text-main-lightGray hover:text-white hidden lg:flex',
              item.class,
            )
          ">
          <a v-bind="props.action">{{ item.label }}</a>
        </div>
      </template>
      <template #end>
        <div class="flex items-center gap-4 flex-1">
          <GeneralSearch />
          <div class="cursor-pointer size-5">
            <i
              @click="showContentDialog"
              v-if="props.plusIcon"
              class="fa fa-plus-circle text-[20px] text-main-text hover:text-white"></i>
          </div>

          <div class="cursor-pointer size-5">
            <i
              class="fa-regular fa-comment text-[20px] text-main-lightGray hover:text-white"></i>
          </div>
          <NotificationHeader />
          <AvatarHeader />
        </div>
      </template>
    </Menubar>
  </div>
</template>

<script lang="ts" setup>
import bizlyLogoWhite from "@/assets/images/bizlyLogoWhite.svg";
import GeneralSearch from "@/components/home/GeneralSearch.vue";
import { API } from "@/core/api";
import { useAuthStore } from "@/store/Auth";
import { DialogType, useDialogStore } from "@/store/Dialog";
import { cn } from "@/utils/cn";
import { router } from "@inertiajs/vue3";
import { computed, ref } from "vue";
import { route } from "ziggy-js";
import AvatarHeader from "./parts/AvatarHeader.vue";
import NotificationHeader from "./parts/NotificationHeader.vue";
const authStore = useAuthStore();
const user = computed(() => authStore.getUser);

const props = defineProps({
  search: {
    type: Boolean,
    default: true,
  },
  plusIcon: {
    type: Boolean,
    default: true,
  },
});

/** HEADER MENU */
const classes = {
  active: "text-white border-b-white",
  inactive: "border-b-main-lightBg",
};

const isActive = (name: String) => {
  return route().current()?.startsWith(name.toLowerCase());
};

const menu = ref([
  {
    label: "Home",
    class: isActive("home") ? classes.active : classes.inactive,
    command: () => {
      router.visit(route("home"));
    },
  },
  {
    label: "Contacts",
    class: isActive("contacts") ? classes.active : classes.inactive,
    command: () => {
      router.visit(route("contacts.index"));
    },
  },
  {
    label: "Explore",
    class: isActive("explore") ? classes.active : classes.inactive,
    command: () => {
      router.visit(route("explore.index"));
    },
  },
  {
    label: "Opportunities",
    class: `${classes.inactive} text-main-lightGray opacity-50 hover:text-main-lightGray !cursor-default`,
    command: () => {},
  },
]);

/**  UPLOAD CONTENT */
const dialogStore = useDialogStore();
const contentAPI = new API.Content();
const showContentDialog = () => {
  dialogStore.show(DialogType.UPLOAD_CONTENT);
  dialogStore.setEvents(DialogType.UPLOAD_CONTENT, {
    close: () => {
      dialogStore.hide(DialogType.UPLOAD_CONTENT);
    },
  });
};
const handleClose = (id?: number) => {
  dialogStore.hide(DialogType.UPLOAD_CONTENT);
  if (id !== undefined) {
    dialogStore.show(DialogType.CONTENT_SHARE_POST, id, { id });
  }
};

const handleSharePost = async () => {
  const data = dialogStore.getData(DialogType.CONTENT_SHARE_POST);

  if (data.id === null) {
    return;
  }
  let response = await contentAPI.shareAsPost(data.id);
  response = response.data;
  dialogStore.hide(DialogType.CONTENT_SHARE_POST);
  dialogStore.show(DialogType.POST_MANAGER, undefined, {
    post: response,
    user: user,
  });
};
</script>
