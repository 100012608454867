import baseApi from "./Base";
import { route } from "ziggy-js";

export class ContentService extends baseApi {
  async create(form: any) {
    return await this.request(route("bizcards.content.upload"), "POST", form);
  }
  async update(form: any) {
    return await this.request(
      route("bizcards.content.update", { content: form.id }),
      "POST",
      form,
    );
  }
  async get(id: Number) {
    return await this.request(
      route("bizcards.content.get", { content: id }),
      "GET",
    );
  }

  async shareAsPost(content: number) {
    return await this.request(
      route("bizcards.content.share.post", content),
      "POST",
    );
  }

  async getYoutubeVideoLink(link: string) {
    return await this.request(
      route("bizcards.youtube-video-data.get"),
      "POST",
      {
        url: link,
      },
    );
  }
  async getVimeoVideoLink(link: string) {
    return await this.request(route("bizcards.vimeo-video-data.get"), "POST", {
      url: link,
    });
  }
  async getLinkMeta(link: string) {
    return await this.request(route("bizcards.link-data.get"), "POST", {
      url: link,
    });
  }
  async delete(contentId: number) {
    return await this.request(
      route("bizcards.content.delete", contentId),
      "POST",
      null,
    );
  }
}
