<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'w-full flex flex-col relative gap-4',
      root: 'bg-lightBg-400 max-w-[600px] max-h-screen w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="mainDialogVisible"
    modal
    :closable="false">
    <section id="header" class="rounded-t-[16px] space-y-4">
      <div
        class="relative py-2 rounded-t-2xl bg-lightBg-500 border-b border-main-divider">
        <h1 class="font-poppins text-xl text-center capitalize">
          Report {{ form.reportable_type }}
        </h1>
        <CircleIcon
          class="bg-lightBg-500 mr-2 absolute top-2 right-2"
          @click="dialogStore.hide(DialogType.REPORT_CONTENT)">
          <i class="fa fa-x text-primary"></i>
        </CircleIcon>
      </div>

      <div class="flex flex-col gap-2 items-center justify-center">
        <CircleIcon size="4rem">
          <i class="fa-regular fa-brake-warning text-primary text-4xl"></i>
        </CircleIcon>
        <h1 class="font-medium capitalize">
          Report {{ form.reportable_type }}
        </h1>
        <p class="text-sm text-primary/80 text-center max-w-[75%]">
          Help us ensure a respectful and safe environment by reporting content
          that violates our guidelines.
        </p>
      </div>
    </section>
    <section
      id="content"
      class="p-6 flex-1 overflow-y-auto scroll-hidden my-2 max-h-[60vh]">
      <h1 class="font-medium">
        Why are you reporting this {{ form.reportable_type }}?
      </h1>
      <!-- REASONS -->
      <div class="flex flex-col gap-2">
        <div
          v-for="type in REASONS"
          :key="type.value"
          class="py-4"
          :class="{
            'border-b border-main-divider': type.value !== REASONS.at(-1).value,
          }">
          <div class="flex w-full">
            <RadioButton
              v-model="form.type"
              :inputId="type.value"
              name="dynamic"
              :value="type.value" />
            <div class="flex flex-col gap-1 ml-2 w-full">
              <label :for="type.value" class="font-semibold">
                {{ type.title }}
              </label>

              <p v-if="type.description" class="text-sm text-primary/80">
                {{ type.description }}
              </p>
              <Textarea
                v-if="type.hasOwnProperty('model')"
                v-model="form[type.model]"
                :disabled="form.type !== 'other'"
                placeholder="Please specify"
                class="!w-full hover:!border-[#3578F8] border-[2px] resize-none text-[#000bf] border-[#8F9FB8] p-[10px] rounded-[5px] focus:outline-[#3578F8] placeholder:text-[#8F9FB8] mx-auto focus:!ring-0 focus:!outline-0"
                rows="3"></Textarea>
            </div>
          </div>
        </div>
      </div>
      <!-- Additional information -->
      <div class="space-y-2">
        <h1 class="font-medium">
          Additional information
          <span class="text-red-500">*</span>
        </h1>
        <p class="text-sm text-primary/80">
          Share more context or specifics about your concern to help our
          moderation team.
        </p>
        <Textarea
          v-model="form.additional_information"
          placeholder="Type here"
          class="!w-full hover:!border-[#3578F8] border-[2px] resize-none text-[#000bf] border-[#8F9FB8] p-[10px] rounded-[5px] focus:outline-[#3578F8] placeholder:text-[#8F9FB8] mx-auto focus:!ring-0 focus:!outline-0"
          rows="3"></Textarea>
      </div>
      <!-- YOUR PRIVACY STATEMENT -->
      <div class="mt-2">
        <h1 class="font-semibold">Your privacy</h1>
        <p class="text-sm text-primary/80">
          Your report is confidential, and content creators will not be notified
          about who reported their content.
        </p>
      </div>
    </section>
    <section
      id="footer"
      class="mb-2 border-t border-main-divider flex justify-end items-center p-2 rounded-b-[16px] gap-2 w-full">
      <button
        class="rounded !text-primary text-sm font-medium min-h-[40px] py-1.5 !px-12 text-center disabled:bg-[#bfbfbf] border border-primary bg-transparent w-1/4"
        @click="dialogStore.hide(DialogType.REPORT_CONTENT)">
        Cancel
      </button>
      <button
        :disabled="isSubmitDisabled"
        class="mr-4 rounded text-white text-sm font-medium min-h-[40px] py-1.5 !px-12 text-center disabled:bg-[#bfbfbf] bg-primary w-1/4"
        @click="handleSubmit">
        Submit
      </button>
    </section>
  </Dialog>
  <ContentReportSubmitted
    v-if="ContentReportSubmittedVisible"
    @close="handleClose" />
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { useDialogStore, DialogType } from "@/store/Dialog";
import { useForm } from "@inertiajs/vue3";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";
import ContentReportSubmitted from "./ContentReportSubmitted.vue";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { useRouterStore } from "@/store/Router";
import { HomeFeedType } from "@/core/enums";
import { useHomeFeedStore } from "@/store/HomeFeed";

const { success, error } = useToastr();
const reportsApi = new API.Reports();
const dialogStore = useDialogStore();
const ContentReportSubmittedVisible = ref(false);
const mainDialogVisible = ref(true);
const content = computed(() => dialogStore.getData(DialogType.REPORT_CONTENT));
const form = useForm({
  reportable_type: content.value.type,
  reportable_id: content.value?.data,
  type: null,
  other_description: null,
  additional_information: null,
});
const isSubmitDisabled = computed(
  () =>
    !form.type ||
    (form.type === "other" && !!!form.other_description) ||
    !form.additional_information,
);

const REASONS = [
  {
    title: "Spam or Misleading",
    description: `This ${form.reportable_type} is promotional or deceptive.`,
    value: "spam_or_misleading_activities",
  },
  {
    title: "Hate Speech or Discrimination",
    description: `This ${form.reportable_type} promotes hate or discriminates against a group.`,
    value: "hate_speech_or_discrimination",
  },
  {
    title: "Violence or Harm",
    description: `This ${form.reportable_type} showcases violent actions or encourages harm.`,
    value: "violence_or_harm",
  },
  {
    title: "Inappropriate Content",
    description: `This ${form.reportable_type} is sexually explicit or unsuitable for all audiences.`,
    value: "inappropriate_content",
  },
  {
    title: "Copyright Violation",
    description: `This ${form.reportable_type} infringes on someone's copyright.`,
    value: "copyright_violation",
  },
  {
    title: "Other",
    value: "other",
    model: "other_description",
  },
];
const handleSubmit = async () => {
  try {
    const response = await reportsApi.report(form);
    if (response.error) throw new Error(response.error);
    switch (form.reportable_type) {
      case "content":
        useUserBizcardStore().deleteContent(form.reportable_id);
        break;
      case "post":
        await useHomeFeedStore().hidePost(
          HomeFeedType.Community,
          form.reportable_id,
        );
        break;
      default:
        break;
    }
    mainDialogVisible.value = false;
    ContentReportSubmittedVisible.value = true;
  } catch (err) {
    console.log(err);
    error(err.message);
  }
};

const handleClose = () => {
  dialogStore.hide(DialogType.REPORT_CONTENT);
  if (route().current("bizcards.content.get")) useRouterStore().back();
};
</script>
