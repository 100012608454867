<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full',
      root: 'mx-auto my-auto flex flex-col items-center bg-[#F8F5FA] justify-center max-w-[450px] overflow-y-auto w-full rounded-[16px] p-[30px]',
    }"
    :visible="true"
    modal
    :closable="false">
    <h1
      class="mb-0 mt-4 mx-auto text-center font-medium text-xl text-black m-0 tracking-tight leading-8">
      Would you like to display the content on your portfolio?
    </h1>
    <p class="text-sm text-main-secondaryBlue text-center w-full mt-4">
      You are now displayed as a collaborator on
      <strong>{{ data?.notification.sender.full_name ?? "someone" }}</strong>
      's content. Would you like to display it on your portfolio? Don't worry,
      you can change your mind later!
    </p>
    <div class="mt-4 flex flex-col gap-2">
      <button
        class="py-3 w-full rounded-md bg-switcherBlue-500 text-white text-sm"
        @click="handleConfirm">
        Yes, add it to my portfolio
      </button>
      <button
        class="py-3 w-full rounded-md border border-black text-primary-500 text-sm"
        @click="dialogStore.hide(DialogType.CONFIRM_COLLAB)">
        No, hide it from my portfolio
      </button>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch, handleError } from "vue";
import { DialogType, useDialogStore } from "@/store/Dialog";
import CircleIcon from "../icons/CircleIcon.vue";
import Confirm from "./Confirm.vue";
import { useUserStore } from "@/store/User";

const dialogStore = useDialogStore();
const userStore = useUserStore();
const rememberChoice = ref(false);
const emit = defineEmits(["close"]);
const data = computed(() => dialogStore[DialogType.CONFIRM_COLLAB].data);
const handleConfirm = () => {
  dialogStore[DialogType.CONFIRM_COLLAB].events?.confirm();
};
</script>
