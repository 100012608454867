<template>
  <!-- < 600 && > 900 -->
  <div
    class="lg:sticky lg:top-20 flex-none self-start w-full lg:w-[400px] flex sm:flex-col md:max-h-[800px] lg:max-h-max flex-wrap gap-6"
  >
    <component
      :is="value.component"
      v-for="(value, index) in shownComponents"
      :key="index"
      v-bind="value.props"
    />
  </div>
</template>

<script lang="ts" setup>
import { useEventListener, useWindowSize } from "@vueuse/core";
import { computed, ref } from "vue";
import CollaboratorsComponent from "@/pages/bizcard/content/parts/Collaborators.vue";
import ProjectDetailsComponent from "@/pages/bizcard/content/parts/ProjectDetails.vue";
import PublisherComponent from "@/pages/bizcard/content/parts/Publisher.vue";
import TagsComponent from "@/pages/bizcard/content/parts/Tags.vue";
import { markRaw } from "vue";

const model = defineModel({ type: Object, required: true });

const { width, height } = useWindowSize({ listenOrientation: true });

const projectDetails = computed(() => ({
  component: markRaw(ProjectDetailsComponent),
  condition: true,
  props: {
    content: model.value,
  },
}));
const collaborators = computed(() => ({
  component: markRaw(CollaboratorsComponent),
  condition: model.value.collaborators.length,
  props: {
    collaborators: model.value.collaborators.filter((c: any) => c.accepted_on),
    ownerId: model.value.bizcard.user.id,
  },
}));
const publisher = computed(() => ({
  component: markRaw(PublisherComponent),
  condition: true,
  props: {
    published: model.value.created_on,
    publisher: model.value.bizcard.user,
    full: !projectDetails.value && !model.value.tags.length,
  },
}));
const tags = computed(() => ({
  component: markRaw(TagsComponent),
  condition: model.value.tags.length,
  props: {
    tags: model.value.tags,
  },
}));
const shownComponents = computed(() => components.value.filter((v: any) => v.condition));
const components = ref([
  projectDetails.value,
  collaborators.value,
  tags.value,
  publisher.value,
]);
const update = () => {
  if (width.value > 640 && width.value < 1024) {
    components.value = [
      projectDetails.value,
      tags.value,
      collaborators.value,
      publisher.value,
    ];
  }
};
update();
useEventListener("resize", update, { passive: true });
</script>
