<template>
  <section id="publisher">
    <div class="bg-white rounded-md shadow-default w-full p-4">
      <div class="flex gap-1 flex-col">
        <p class="uppercase text-black text-xs font-semibold">published on</p>
        <p class="text-sm text-main-primary">{{ formattedDate }}</p>
      </div>

      <hr class="bg-main-secondary w-full my-4" />
      <p class="uppercase text-black text-xs font-semibold">PUBLISHED BY</p>
      <div class="flex gap-4 mt-2 flex-wrap justify-between items-center">
        <div class="w-full lg:max-w-[700px]">
          <UserCard
            class="w-full"
            :user="publisher"
            :showLocation="false"
            :showRelationship="false" />
        </div>
        <section
          v-if="authStore.isLoggedIn() && publisher.id !== authStore.getUser.id"
          id="actions"
          class="w-full"
          :class="{ 'lg:max-w-[250px]': full }">
          <button
            v-if="is_friend"
            @click="handleOpenMobileAppDialog"
            class="flex items-center justify-center gap-2 text-sm text-[white] h-[40px] rounded-md bg-switcherBlue-500 hover:bg-switcherBlue-600 hover:text-white cursor-pointer w-full"
            :class="{ 'lg:max-w-[250px]': full }">
            <i class="fa fa-comment text-white"></i>
            Message
          </button>
          <button
            v-else-if="is_outbound_request || is_incoming_request"
            @click="toggleRequestsMenu"
            class="flex items-center justify-center gap-2 text-sm text-[white] h-[40px] rounded-md bg-[#ff9900] hover:bg-[#ff9900]/80 hover:text-white cursor-pointer w-full"
            :class="{ 'lg:max-w-[250px]': full }">
            <i class="fa fa-clock-three text-white"></i>
            Connection Pending
          </button>
          <button
            v-else
            @click="handleConnectUser"
            class="flex items-center justify-center gap-2 text-sm text-[white] h-[40px] rounded-md bg-switcherBlue-500 hover:bg-switcherBlue-600 hover:text-white cursor-pointer w-full"
            :class="{ 'lg:max-w-[250px]': full }">
            <i class="fa fa-user-plus text-white"></i>
            Connect
          </button>
        </section>
      </div>
    </div>
    <Menu
      v-if="is_incoming_request || is_outbound_request"
      id="REQUESTS_MENU"
      :model="REQUESTS.filter((r) => r.active)"
      class="w-[21rem] content_menu overflow-hidden shadow-[0_2px_15px_0_rgba(0,0,0,0.3)]"
      ref="incomingRequestMenu"
      :popup="true"
      :pt="{
        root: 'min-w-[12rem] rounded-md bg-surface-0 text-surface-700 p-2',
        content: 'hover:!bg-white',
      }">
      <template #item="{ item }">
        <button :class="item.className" @click="item.clickEvent">
          <i v-if="item.icon" :class="item.icon"></i>
          {{ item.title }}
        </button>
      </template>
    </Menu>
  </section>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import UserCard from "@/components/general/UserCard.vue";
import type { ConnectionsInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
import { useUserStore } from "@/store/User";
import { formatDateShort } from "@/utils/formatter";
import { DialogType, useDialogStore } from "@/store/Dialog";

const props = defineProps({
  published: {
    required: true,
    type: String,
  },
  publisher: {
    required: true,
    type: Object,
  },
  full: {
    required: true,
    type: Boolean,
  },
});

/* ----- STORES ----- */
const userStore = useUserStore();
const authStore = useAuthStore();
const dialogStore = useDialogStore();
/* ---------- REACTIVE DATA ----------*/
const incomingRequestMenu = ref();
const user = computed(() => authStore.getUser);
const is_friend = computed(
  () => userStore.friends.findIndex((f) => f.id === props.publisher.id) !== -1,
);

const received_requests = computed(
  () => userStore.friend_requests.received ?? [],
);
const sent_requests = computed(() => userStore.friend_requests.sent ?? []);
const is_incoming_request = computed(
  () =>
    received_requests.value.findIndex(
      (r) => r?.sender?.id === props.publisher.id,
    ) !== -1,
);
const is_outbound_request = computed(
  () =>
    sent_requests.value.findIndex(
      (r) => r?.recipient?.id === props.publisher.id,
    ) !== -1,
);

/* ----- MENUS -----*/
const REQUESTS = computed(() => [
  {
    active: is_incoming_request.value,
    title: "Accept connection request",
    className:
      "rounded-md px-1 py-3 w-full bg-green-500 text-white transition-all",
    clickEvent: handleAcceptConnection,
  },
  {
    active: is_incoming_request.value,
    title: "Decline connection request ",
    className:
      "rounded-md px-1 py-3 w-full hover:bg-lightBg-500 transition-all",
    clickEvent: handleDeclineConnection,
  },
  {
    active: is_outbound_request.value,
    title: "Withdraw connection request",
    icon: "fa fa-user-xmark",
    className:
      "flex items-center justify-center gap-2 rounded-md px-1 py-3 w-full hover:bg-lightBg-500 transition-all",
    clickEvent: () => {
      userStore.withdrawConnectionRequest(
        sent_requests.value.find(
          (r) => r.recipient.id === props.publisher.id,
        ) as ConnectionsInterface,
      );
    },
  },
]);
/* ---------- FUNCTIONS ---------- */
const toggleRequestsMenu = (event: Event) => {
  if (incomingRequestMenu.value) incomingRequestMenu.value.toggle(event);
};

const handleConnectUser = () => {
  userStore.connectUser(props.publisher.id);
};
const handleAcceptConnection = () => {
  userStore.acceptConnectionRequest(
    received_requests.value.find(
      (r) => r.sender.id === props.publisher.id,
    ) as ConnectionsInterface,
  );
};
const handleDeclineConnection = () => {
  userStore.declineConnectionRequest(
    received_requests.value.find(
      (r) => r.sender.id === props.publisher.id,
    ) as ConnectionsInterface,
  );
};
const handleOpenMobileAppDialog = () => {
  dialogStore.show(DialogType.MOBILE_APP);
  dialogStore.setEvents(DialogType.MOBILE_APP, {
    close: () => dialogStore.hide(DialogType.MOBILE_APP),
  });
};
const formattedDate = computed(() => {
  return formatDateShort(props.published);
});
</script>
