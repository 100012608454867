<template>
  <div
    class="flex items-center justify-start !cursor-pointer transition duration-150 gap-2.5 !w-full">
    <div class="flex items-center !w-[calc(100%-40px)] gap-2.5" v-if="user">
      <Link
        :href="route('user.profile.index', user.public_username)"
        v-if="isLink">
        <img
          :src="user.avatar_image ?? ProfileNoPic"
          alt="Image"
          :class="
            cn(
              'w-[50px] h-[50px] object-cover max-h-[50px] max-w-[50px] min-h-[50px] min-w-[50px] rounded-full',
              avatarClass,
            )
          " />
      </Link>
      <img
        v-else
        :src="user.avatar_image ?? ProfileNoPic"
        alt="Image"
        :class="
          cn(
            'w-[50px] h-[50px] object-cover max-h-[50px] max-w-[50px] min-h-[50px] min-w-[50px] rounded-full',
            avatarClass,
          )
        " />
      <div class="flex flex-col justify-between !w-full flex-1">
        <div
          :class="
            cn(
              'text-[14px] font-[600] transition duration-150 hoverHeading',
              userProps.class,
            )
          ">
          {{ user.full_name }}
          <span
            v-if="ownerTag"
            class="font-[400] text-[10px] bg-[#3888ff65] px-[8px] py-[4px] rounded-[20px]">
            Owner
          </span>
        </div>
        <div
          :class="
            cn(
              'relative overflow-hidden m-0 p-0 !w-full min-w-[200px]',
              roleClass,
            )
          ">
          <Roles
            v-if="showRoles && user.roles && user.roles.length !== 0"
            :items="user.roles.map((v) => v.name)"
            v-bind="roleProps" />
          <canvas class="hidden"></canvas>
        </div>
        <div
          class="text-[12px] text-main-lightGray hidden xl:flex xl:gap-1 items-center"
          v-if="showLocation">
          <i class="fa-sharp far fa-location-dot"></i>
          {{ user.location }}
        </div>
      </div>
    </div>
    <div class="friendIconWrapper" v-if="showRelationship">
      <!--
                            TODO:: Create relationship buttons in a separated component
                            [
                                Add Friend,
                                Remove Friend,
                                Accept Request,
                                Decline Request,
                                Cancel Request
                                Message
                            ]
                         -->
      <CircleIcon
        @click="toggleRequestsMenu"
        v-if="is_outbound_request || is_incoming_request"
        size="2rem"
        class="bg-[#ff9900] hover:!bg-[#ff9900]/80">
        <i class="fa fa-user-clock text-white text-xs"></i>
      </CircleIcon>
      <i
        v-else-if="showAddButton"
        @click="handleConnectUser"
        class="fa fa-user-plus text-primary-400 text-base"></i>
    </div>
    <div v-if="slots.actions">
      <slot name="actions"></slot>
    </div>
    <Menu
      v-if="is_incoming_request || is_outbound_request"
      id="REQUESTS_MENU"
      :model="REQUESTS.filter((r) => r.active)"
      class="w-[21rem] content_menu overflow-hidden shadow-[0_2px_15px_0_rgba(0,0,0,0.3)]"
      ref="incomingRequestMenu"
      :popup="true"
      :pt="{
        root: 'min-w-[12rem] rounded-md bg-surface-0 text-surface-700 p-2',
        content: 'hover:!bg-white',
      }">
      <template #item="{ item }">
        <button :class="item.className" @click="item.clickEvent">
          <i v-if="item.icon" :class="item.icon"></i>
          {{ item.title }}
        </button>
      </template>
    </Menu>
  </div>
</template>
<script setup lang="ts">
import ProfileNoPic from "@/assets/images/profile-no-pic.png";
import Roles from "@/components/general/Roles.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import type { ConnectionsInterface, UserInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
import { DialogType, useDialogStore } from "@/store/Dialog";
import { useUserStore } from "@/store/User";
import { cn } from "@/utils/cn";
import { formatDateShort } from "@/utils/formatter";
import { Link } from "@inertiajs/vue3";
import type { PropType } from "vue";
import { computed, ref, useSlots } from "vue";

const props = defineProps({
  user: {
    type: Object as PropType<UserInterface>,
    required: true,
  },
  avatarClass: {
    type: String,
    required: false,
  },
  showLocation: {
    type: Boolean,
    default: true,
  },
  showRelationship: {
    type: Boolean,
    default: true,
  },
  userProps: {
    type: Object,
    required: false,
    default: {
      class: "",
    },
  },
  showRoles: {
    type: Boolean,
    default: true,
  },
  roleClass: {
    type: String || Object,
    required: false,
  },
  roleProps: {
    type: Object,
    required: false,
    default: {
      fontSize: "12px",
      textColor: "#000",
      separatorWidth: "4px",
      separatorHeight: "4px",
      separatorColor: "#8c8c8c",
    },
  },
  isLink: {
    type: Boolean,
    default: true,
  },
  ownerTag: {
    type: Boolean,
    default: false,
  },
});
/* ----- STORES ----- */
const userStore = useUserStore();
const authStore = useAuthStore();
const dialogStore = useDialogStore();

/* ---------- REACTIVE DATA ----------*/
const incomingRequestMenu = ref();
const auth_user = computed(() => authStore.getUser);
const is_friend = computed(
  () => userStore.friends.findIndex((f) => f.id === props.user.id) !== -1,
);

const received_requests = computed(
  () => userStore.friend_requests.received ?? [],
);
const sent_requests = computed(() => userStore.friend_requests.sent ?? []);
const is_incoming_request = computed(
  () =>
    received_requests.value.findIndex(
      (r) => r?.sender?.id === props.user.id,
    ) !== -1,
);
const is_outbound_request = computed(
  () =>
    sent_requests.value.findIndex((r) => r?.recipient?.id === props.user.id) !==
    -1,
);

/* ----- MENUS -----*/
const REQUESTS = computed(() => [
  {
    active: is_incoming_request.value,
    title: "Accept connection request",
    className:
      "rounded-md px-1 py-3 !w-full bg-green-500 text-white transition-all",
    clickEvent: handleAcceptConnection,
  },
  {
    active: is_incoming_request.value,
    title: "Decline connection request ",
    className:
      "rounded-md px-1 py-3 !w-full hover:bg-lightBg-500 transition-all",
    clickEvent: handleDeclineConnection,
  },
  {
    active: is_outbound_request.value,
    title: "Withdraw connection request",
    icon: "fa fa-user-xmark",
    className:
      "flex items-center justify-center gap-2 rounded-md px-1 py-3 !w-full hover:bg-lightBg-500 transition-all",
    clickEvent: () => {
      userStore.withdrawConnectionRequest(
        sent_requests.value.find(
          (r) => r.recipient.id === props.user.id,
        ) as ConnectionsInterface,
      );
    },
  },
]);
const authUser = computed(() => authStore.getUser);

const showAddButton = computed(
  () =>
    !is_friend.value &&
    props.user?.id !== authUser.value.id &&
    !is_incoming_request.value &&
    !is_outbound_request.value,
);

const roleProps = computed(() => {
  const defaultValue = {
    fontSize: "12px",
    textColor: "#000",
    separatorWidth: "4px",
    separatorHeight: "4px",
    separatorColor: "#8c8c8c",
  };
  return { ...defaultValue, ...props.roleProps };
});
const slots = useSlots();

/* ---------- FUNCTIONS ---------- */
const toggleRequestsMenu = (event: Event) => {
  if (incomingRequestMenu.value) incomingRequestMenu.value.toggle(event);
};

const handleConnectUser = () => {
  userStore.connectUser(props.user.id);
};
const handleAcceptConnection = () => {
  userStore.acceptConnectionRequest(
    received_requests.value.find(
      (r) => r.sender.id === props.user.id,
    ) as ConnectionsInterface,
  );
};
const handleDeclineConnection = () => {
  userStore.declineConnectionRequest(
    received_requests.value.find(
      (r) => r.sender.id === props.user.id,
    ) as ConnectionsInterface,
  );
};
const handleOpenMobileAppDialog = () => {
  dialogStore.show(DialogType.MOBILE_APP);
  dialogStore.setEvents(DialogType.MOBILE_APP, {
    close: () => dialogStore.hide(DialogType.MOBILE_APP),
  });
};
</script>
