import { h } from "vue";

export interface SocialNetworkInterface {
  url: string;
  network: string;
}

export const socialNetworks: Record<
  string,
  { label: string; icon: string; prefix?: string; suffix?: string }
> = {
  facebook: {
    label: "Facebook",
    icon: "fab fa-facebook text-[#1977f3]",
    prefix: "https://facebook.com/",
  },
  reddit: {
    label: "Reddit",
    icon: "fab fa-reddit-alien text-[#ff4500]",
    prefix: "https://reddit.com/",
  },
  discord: {
    label: "Discord",
    icon: "fab fa-discord text-[#5865f2]",
    prefix: "https://discord.com/",
  },
  tumblr: {
    label: "Tumblr",
    icon: "fab fa-tumblr text-[#000000]",
    prefix: "https://",
    suffix: ".tumblr.com",
  },
  telegram: {
    label: "Telegram",
    icon: "fab fa-telegram text-[#26a5e4]",
    prefix: "https://t.me/",
  },
  twitch: {
    label: "Twitch",
    icon: "fab fa-twitch text-[#9146ff]",
    prefix: "https://www.twitch.tv/",
  },
  signal: {
    label: "Signal Messenger",
    icon: "fab fa-signal-messenger text-[#3a76f0]",
    prefix: "https://signal.org/",
  },
  imdb: {
    label: "IMDb",
    icon: "fab fa-imdb text-[#e6b91e]",
    prefix: "https://imdb.com/",
  },
  snapchat: {
    label: "Snapchat",
    icon: "fab fa-snapchat text-[#ffd237]",
    prefix: "https://snapchat.com/",
  },
  pinterest: {
    label: "Pinterest",
    icon: "fab fa-pinterest text-[#e60019]",
    prefix: "https://pinterest.com/",
  },
  github: {
    label: "GitHub",
    icon: "fab fa-github text-[#191717]",
    prefix: "https://github.com/",
  },
  soundcloud: {
    label: "SoundCloud",
    icon: "fab fa-soundcloud text-[#ff7700]",
    prefix: "https://soundcloud.com/",
  },
  behance: {
    label: "Behance",
    icon: "fab fa-behance text-[#00a9ff]",
    prefix: "https://behance.net/",
  },
  dribble: {
    label: "Dribble",
    icon: "fab fa-dribbble text-[#c32361]",
    prefix: "https://dribbble.com/",
  },
  whatsapp: {
    label: "WhatsApp",
    icon: "fab fa-whatsapp text-[#25d366]",
    prefix: "https://wa.me/",
  },
  spotify: {
    label: "Spotify",
    icon: "fab fa-spotify text-[#22d563]",
    prefix: "https://open.spotify.com/",
  },
  skype: {
    label: "Skype",
    icon: "fab fa-skype text-[#0078d7]",
    prefix: "https://skype.com/",
  },
  linkedin: {
    label: "LinkedIn",
    icon: "fab fa-linkedin text-[#0a66c2]",
    prefix: "https://linkedin.com/",
  },
  youtube: {
    label: "YouTube",
    icon: "fab fa-youtube text-[#ff0000]",
    prefix: "https://youtube.com/user/",
  },
  tiktok: {
    label: "TikTok",
    icon: "fab fa-tiktok text-[#000000]",
    prefix: "https://tiktok.com/",
  },
  twitter: {
    label: "Twitter",
    icon: "fab fa-x-twitter text-[#000000]",
    prefix: "https://x.com/",
  },
  vimeo: {
    label: "Vimeo",
    icon: "fab fa-vimeo-v text-[#1ab7ea]",
    prefix: "https://vimeo.com/",
  },
  instagram: {
    label: "Instagram",
    icon: "fab fa-instagram bg-gradient-to-tr from-amber-400 via-pink-600 to-purple-800 bg-clip-text text-transparent",
    prefix: "https://instagram.com/",
  },
  messenger: {
    label: "Messenger",
    icon: "fab fa-facebook-messenger bg-gradient-to-tr from-sky-500 via-purple-600 to-red-500 bg-clip-text text-transparent",
    prefix: "https://m.me/",
  },
  google: {
    label: "Google",
    icon: "fab fa-google text-[#ea4335]",
    prefix: "https://google.com/",
  },
};
