<template>
  <Confirm
    class="bg-neutral-900"
    layout="vertical"
    color="primary-light"
    action="Share as post"
    @confirm="handleSharePost"
    @close="dialogStore.hide(DialogType.CONTENT_SHARE_POST)">
    <template #icon>
      <i
        class="fa-duotone fa-solid fa-party-horn fa-3x"
        style="
          --fa-primary-color: #fed920;
          --fa-secondary-color: #d80e0e;
          --fa-secondary-opacity: 0.6;
        "></i>
    </template>
    <template #title>
      <p class="text-white text-lg font-semibold">
        Congratulations, your content is now available to view on your
        portfolio!
      </p>
    </template>
    <template #description>
      <p class="text-sm text-main-disabled text-center">
        Would you like to share your latest portfolio addition with the
        bizlyCommunity?
      </p>
    </template>
  </Confirm>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import { useDialogStore, DialogType } from "@/store/Dialog";
import { API } from "@/core/api";
import Confirm from "@/components/dialogs/Confirm.vue";
import { useAuthStore } from "@/store/Auth";

const dialogStore = useDialogStore();
const contentAPI = new API.Content();
const authStore = useAuthStore();

const handleSharePost = async () => {
  const data = dialogStore.getData(DialogType.CONTENT_SHARE_POST);
  if (data.id === null) {
    return;
  }
  let response = await contentAPI.shareAsPost(data.id);
  response = response.data;
  dialogStore.hide(DialogType.CONTENT_SHARE_POST);
  dialogStore.show(DialogType.POST_MANAGER, undefined, {
    post: response,
    user: authStore.user,
  });
};
</script>
