<template>
  <div class="bg-white rounded-md shadow-default w-full p-4">
    <h1 class="mb-2">
      {{ collaborators.length }} Collaborator{{
        collaborators.length > 1 ? "s" : ""
      }}
    </h1>
    <div class="flex flex-col">
      <div
        v-for="(collaborator, index) in collaborators"
        :key="collaborator.id">
        <UserCard
          :user="collaborator"
          :showLocation="false"
          :ownerTag="ownerId === collaborator.id"
          :showRelationship="useAuthStore().isLoggedIn()" />
        <hr
          class="bg-main-divider h-[1px] w-full my-2"
          v-if="collaborators.at(-1) !== collaborators.at(index)" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import UserCard from "@/components/general/UserCard.vue";
import type { UserInterface } from "@/core/interfaces";
import { computed, inject, type PropType } from "vue";
import { useAuthStore } from "@/store/Auth";

const props = defineProps({
  owner: {
    type: Object as PropType<UserInterface>,
    default: null,
  },
  ownerId: {
    type: Number,
    default: () => null,
    required: true,
  },
});
const collaborators = inject("collaborators") as UserInterface[];
</script>
