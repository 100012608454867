import {
  type UserInterface,
  type NotificationInterface,
} from "@/core/interfaces";
import { defineStore } from "pinia";
import { router } from "@inertiajs/vue3";
import { reactive, ref, computed, onMounted, watch } from "vue";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";
import { useUserStore } from "./User";
import { DialogType, useDialogStore } from "./Dialog";
interface State {
  notifications: NotificationInterface[];
  lazyload: {
    start: number;
    size: number;
    no_more_results: Boolean;
    isLoading: Boolean;
  };
}
export const useNotificationStore = defineStore("notificationStore", {
  state: (): State => ({
    notifications: [],
    lazyload: {
      start: 0,
      size: 15,
      no_more_results: false,
      isLoading: false,
    },
  }),
  getters: {
    read: (state): NotificationInterface[] =>
      state.notifications.filter((n: NotificationInterface) => n.is_read) ?? [],
    unread: (state): NotificationInterface[] =>
      state.notifications.filter((n: NotificationInterface) => !n.is_read) ??
      [],
  },
  actions: {
    async getNotifications() {
      const notificationApi = new API.Notifications();
      if (!this.lazyload.no_more_results && !this.lazyload.isLoading) {
        this.lazyload.isLoading = true;

        const { data: response }: { data: NotificationInterface[] } =
          await notificationApi.get({ ...this.lazyload });
        if (this.lazyload.start) {
          if (response.length) {
            this.notifications = [...this.notifications, ...response];
          }
        } else {
          this.notifications = response;
        }
        if (response.length < (this.lazyload.size ?? 0)) {
          this.lazyload.no_more_results = true;
        }
        this.lazyload.start = this.notifications.length;
        this.lazyload.size = 10;
      }
      if (this.lazyload.isLoading === true) {
        this.lazyload.isLoading = false;
      }
    },
    readAll() {
      const notificationApi = new API.Notifications();
      notificationApi.markAllAsRead().then(() => {
        // emit("read-all");
        this.notifications.forEach((n) => (n.is_read = true));
      });
    },
    async handleCollabRequest(id: number, action: string) {
      const dialogStore = useDialogStore();
      const { error, success } = useToastr();
      const notificationApi = new API.Notifications();
      const bizcardApi = new API.Bizcard();
      const notification = this.notifications.find((n) => n.id === id);
      if (!notification) return;
      try {
        const response = await notificationApi.toggleCollaboration(
          notification.id,
          action,
        );
        if (response.code === "invite_accepted") {
          this.notifications.splice(
            this.notifications.indexOf(notification),
            1,
          );
          success(
            action === "accept"
              ? "Collaboration accepted"
              : "Collaboration declined",
          );
          if (action === "accept") {
            dialogStore.show(DialogType.CONFIRM_COLLAB, id, {
              notification,
            });
            dialogStore.setEvents(DialogType.CONFIRM_COLLAB, {
              confirm: async () => {
                try {
                  const response_1 =
                    await bizcardApi.toggleCollaborationContent(
                      response.data.collaboration_id,
                      "show",
                    );
                  if (response_1.error) throw new Error(response_1.error);
                  success(response_1.success);
                  dialogStore.hide(DialogType.CONFIRM_COLLAB);
                } catch (err: any) {
                  error(err);
                }
              },
            });
          }
        } else if (response.hasOwnProperty("error")) {
          error(response.error);
        }
      } catch (err: any) {
        console.log("[DEBUG] Error in handleCollabRequest: ", err);
        error(err.response.data);
      }
    },
    async handleConnectionRequest(notificationId: number, action: string) {
      try {
        const notificationApi = new API.Notifications();
        const userStore = useUserStore();
        const notificationIdx = this.notifications.findIndex(
          (i) => i.id === notificationId,
        );
        if (notificationIdx !== -1) {
          const { code } = await notificationApi.toggleConnection(
            this.notifications[notificationIdx].id,
            action,
          );
          if (code === "invite_accepted") {
            // TODO: Change this to enum
            userStore.friends.push(this.notifications[notificationIdx].object);
            this.notifications.splice(notificationIdx, 1);
          } else if (code === "invite_declined") {
            this.notifications[notificationIdx].is_read = true;
          } else {
            throw new Error("An error has occurred!");
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    AddNotification(notification: NotificationInterface) {
      this.notifications.unshift(notification);
    },
    RemoveNotification(notification: NotificationInterface) {
      const notificationIdx = this.notifications.findIndex(
        (n) => n.id === notification.id,
      );
      if (notificationIdx !== -1) {
        this.notifications.splice(notificationIdx, 1);
      }
    },
  },
});
