import { useAuthStore } from "./store/Auth";
import Tailwind from "@/assets/tailwind-preset";
import { createInertiaApp } from "@inertiajs/vue3";
import "@js/bootstrap";
import { ZiggyVue } from "@ziggy";
import { route } from "ziggy-js";
import PrimeVue from "primevue/config";
import BadgeDirective from "primevue/badgedirective";
import Tooltip from "primevue/tooltip";

import { createApp, h, type DefineComponent } from "vue";
import VueTelInput from "vue-tel-input";
import { createPinia } from "pinia";
import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify";

import "vue3-toastify/dist/index.css";
import "vue-tel-input/vue-tel-input.css";
import "./assets/main.css";
import "./assets/scss/global.scss";
import "../js/bootstrap";
import { CkeditorPlugin } from "@ckeditor/ckeditor5-vue";
import { createImageKitVue } from "imagekit-vue3";
import AppLayout from "@/App.vue";
const IKurlEndpoint = import.meta.env.VITE_IMAGEKIT_ENDPOINT_URL;
const public_key = import.meta.env.VITE_IMAGEKIT_PUBLIC_KEY;
const pinia = createPinia();
const App = createInertiaApp({
  resolve: (name: string) => {
    const pages = import.meta.glob("./**/*.vue", { eager: true });
    Object.keys(pages).forEach((key) => {
      pages[key].default.layout = AppLayout;
    });
    return pages[`./${name}.vue`] as
      | DefineComponent
      | Promise<DefineComponent>
      | { default: DefineComponent };
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });
    app.config.globalProperties.window = window;

    app
      .use(CkeditorPlugin)
      .use(pinia)
      .use(plugin)
      .use(ZiggyVue)
      .use(PrimeVue, {
        unstyled: true,
        pt: Tailwind,
      })
      .use(
        createImageKitVue({
          urlEndpoint: IKurlEndpoint, // Required. Default URL-endpoint is https://ik.imagekit.io/your_imagekit_id
          publicKey: public_key, // optional
          authenticationEndpoint: route("api.ik_auth"),
        }),
      )
      .use(VueTelInput as any)
      .use(Vue3Toastify, {
        autoClose: 3000,
        theme: "dark",
      } as ToastContainerOptions)
      .directive("badge", BadgeDirective)
      .directive("tooltip", Tooltip)
      .mount(el);
  },
});
