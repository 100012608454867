import type { AxiosRequestConfig } from "axios";
import baseApi from "./Base";
import { route } from "ziggy-js";

export class UsersService extends baseApi {
  async block(user: number) {
    return await this.request(route("users.block", user), "POST");
  }
  async unblock(user: number) {
    return await this.request(route("users.unblock", user), "POST");
  }

  async connect(user: number) {
    return await this.request(
      route("users.send_connection_request", user),
      "POST",
    );
  }

  async RemoveConnect(user: number) {
    return await this.request(route("users.remove_connection", user), "POST");
  }

  async getOrdered(form: any, customConfig: AxiosRequestConfig = {}) {
    return await this.request(
      route("users.ordered"),
      "GET",
      form,
      customConfig,
    );
  }

  async getFriends() {
    return await this.request(route("user.friends"), "GET");
  }

  async getPosts(user: number, form: any) {
    return await this.request(route("user.profile.posts", user), "GET", form);
  }

  /** BIZCARD PROFILE */
  async updateAvatar(form: any) {
    return await this.request(route("user.avatar"), "POST", form);
  }

  async validateDomain(form: any) {
    return await this.request(route("user.domain.validate"), "GET", form);
  }

  async updateIntro(form: any) {
    return await this.request(route("user.bizcard.intro"), "POST", form);
  }

  async updateLinks(form: any) {
    return await this.request(route("user.bizcard.links"), "POST", form);
  }

  async getMentions(form: any) {
    return await this.request(
      route("users.search.mention"),
      "GET",
      form,
    );
  }
}
