<template>
  <div class="w-full">
    <ckeditor
      v-model="model"
      :key="config.mention?.feeds?.[0]?.feed"
      :editor="InlineEditor"
      :config="config"
      class="" />
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch, createApp } from "vue";
import UserCard from "@/components/general/UserCard.vue";
import {
  InlineEditor,
  Mention,
  Paragraph,
  SpecialCharacters,
  Clipboard,
  WordCount,
  ShiftEnter,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { useUserStore } from "@/store/User";
import _debounce from "lodash/debounce";
import { API } from "@/core/api";
const userAPI = new API.Users();
const props = defineProps({
  placeholder: {
    type: String,
    default: "",
  },
});
const userStore = useUserStore();
const model = defineModel();

const Charcount = defineModel("charCount");
const mentionable = computed(
  () =>
    userStore.friends?.map((f) => ({
      ...f,
      id: `@${f.id}`,
      text: f.full_name,
    })) ?? [],
);

const config = computed(() => ({
  plugins: [
    ShiftEnter,
    Mention,
    Paragraph,
    SpecialCharacters,
    Clipboard,
    WordCount,
  ],
  placeholder: props.placeholder,
  mention: {
    dropdownLimit: 20,
    feeds: [
      {
        marker: "@",
        feed: getMentions,
        itemRenderer: customItemRenderer,
      },
    ],
  },
  wordCount: {
    onUpdate: (stats) => {
      // Prints the current content statistics.
      Charcount.value = stats.characters;
    },
  },
}));

function customItemRenderer(user: any) {
  const wrapper = document.createElement("div");

  const app = createApp(UserCard, {
    user,
    isLink: false,
    showLocation: false,
    showRelationship: false,
    roleProps: {
      fontSize: "12px",
      textColor: "#000",
      separatorWidth: "4px",
      separatorHeight: "3px",
      separatorColor: "#8c8c8c",
    },
  });
  app.mount(wrapper);
  return wrapper;
}

const getMentions = async (queryText) => {
  try {
    const response = await userAPI.getMentions({ keyword: queryText });
    if (response.error) throw new Error(response.error);
    return response.data.map((m) => ({
      ...m,
      id: `@${m.id}`,
      text: m.full_name,
      fullName: m.full_name,
    }));
  } catch (error) {
    console.error(error);
  }
};
</script>
<style lang="scss">
:root {
  --ck-font-size-normal: 1rem;
  /* Make the mention background blue. */
  --ck-color-mention-background: transparent;
  --ck-focus-ring: none;
  --ck-border-radius: 16px;
  /* Make the mention text dark grey. */
  --ck-color-mention-text: #2998ff;
}
.ck-balloon-panel {
  z-index: 99999 !important;
}
.ck-toolbar-container,
.ck-powered-by-balloon {
  display: none !important;
}
.ck-on {
  background-color: transparent !important;
}
.ck-on:hover {
  background: var(--ck-color-list-button-hover-background) !important;
}
.ck-list.ck-mentions {
  min-width: 400px;

  cursor: pointer !important;
  &::-webkit-scrollbar {
    display: none !important;
    opacity: 0 !important;
  }
}
.ck-button,
.ck.ck-list__item {
  cursor: pointer !important;
}
.ck.ck-editor__editable {
  padding-right: 0px !important;
  box-shadow: none !important;
  overflow-y: scroll;
  overscroll-behavior: contain;
  max-height: 200px;
  &::-webkit-scrollbar {
    display: none !important;
    opacity: 0 !important;
  }
}
</style>
