<template>
  <Layout>
    <div class="flex flex-col gap-5 py-[28px] px-2.5 xl:px-[90px]">
      <div
        class="bg-white px-6 py-4 rounded-lg shadow-default flex items-center transition duration-150 gap-2.5 w-full">
        <div class="flex flex-col flex-1 gap-4">
          <span class="text-2xl font-medium py-2.5 w-full">
            {{ group.name }}
          </span>
          <div class="flex gap-4 items-center">
            <AvatarGroup class="pl-4">
              <Avatar
                v-for="user in group.users"
                :key="user.id"
                :image="user.avatar_image"
                shape="circle" />
            </AvatarGroup>
            <div class="text-main-lightGray text-sm font-normal">
              {{ group.users.length }} members
            </div>
          </div>
        </div>
        <Button
          type="button"
          icon="far fa-ellipsis-v"
          text
          rounded
          class="!p-2 text-xl text-main-lightGray"
          @click="toggleMenu" />
      </div>
      <IconField
        :iconPosition="loading ? 'both' : 'left'"
        class="cursor-pointer w-full z-[60]">
        <i class="fa fa-search size-4"></i>

        <InputText
          placeholder="Search connections"
          v-model="search"
          class="bg-white w-full focus:!outline-none focus:!border-0 focus:!ring-0 hidden md:block shadow-default" />
        <i
          v-if="loading"
          class="pointer-events-none far fa-spinner-third animate-spin"></i>
      </IconField>
      <UserCard
        :user="authUser"
        class="bg-white py-5 px-4 rounded-lg shadow-default" />
      <section id="usersList">
        <div class="space-y-4">
          <div
            v-for="(group, key) in groupFiltered"
            :key="key"
            class="flex flex-col gap-4">
            <div class="sticky z-10">
              <p
                class="text-[#39537D] bg-[#EFF1F5] px-4 py-[5px] rounded-[4px] font-[500] uppercase">
                {{ key }}
              </p>
            </div>
            <div
              class="grid-cols-1 min-[1366px]:grid-cols-2 gap-[20px] w-full"
              :class="{
                grid: layout === 'full',
                'flex flex-wrap gap-4': layout === 'compact',
              }">
              <component
                :is="userCard"
                v-for="friend in group"
                :key="friend.id"
                :user="friend">
                <template #actions>
                  <i
                    class="text-white/80 fa-regular fa-comment fa-xl"
                    @click="handleMobileDialog"></i>
                  <i
                    class="text-white/80 fa-regular fa-star fa-xl"
                    :class="{
                      'fa-solid !text-red-500': is_favorite(friend),
                    }"
                    @click="() => toggleFavorite(friend)"></i>
                </template>
              </component>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Menu
      id="overlay_menu"
      :model="ellipsesMenu"
      class="w-[21rem] content_menu overflow-hidden shadow-[0_2px_15px_0_rgba(0,0,0,0.3)]"
      ref="headerMenu"
      :popup="true"
      :pt="{ content: 'hover:!bg-transparent' }"
      :ptOptions="{ mergeProps: true }">
      <template #item="{ item }">
        <div
          :class="{
            'pointer-events-none text-main-disabled hover:bg-white':
              item.disabled,
          }">
          <a
            class="flex items-center gap-4 py-3 px-4 w-full"
            :class="{
              'border-b border-main-divider': item.border,
              'hover:bg-main-lightGray/20': !item.disabled,
            }"
            v-if="item.type == 'external_link'"
            :href="item.href"
            target="_blank">
            <CircleIcon>
              <i :class="item.icon"></i>
            </CircleIcon>

            <div class="flex flex-col gap-[2px] items-start justify-start">
              <p
                class="mb-0 w-max text-[16px] font-medium leading-5"
                :class="{
                  'text-[#ff3b30]': item.danger,
                }">
                {{ item?.label }}
              </p>
              <small
                class="text-xs font-normal leading-4 text-main-secondary text-left"
                v-if="item.description">
                {{ item?.description }}
              </small>
            </div>
          </a>
          <Link
            v-else-if="item.type === 'inertia_link'"
            :href="item.href"
            class="flex items-center gap-4 py-3 px-4 w-full"
            :class="{
              'border-b border-main-divider': item.border,
              'hover:bg-main-lightGray/20': !item.disabled,
            }">
            <CircleIcon :class="{ 'bg-[#ff3b30]/30': item.danger }">
              <i :class="item.icon"></i>
            </CircleIcon>

            <div class="flex flex-col gap-[2px] items-start justify-start">
              <p
                class="mb-0 w-max text-[16px] font-medium leading-5"
                :class="{
                  'text-[#ff3b30]': item.danger,
                }">
                {{ item?.label }}
              </p>
              <small
                class="text-xs font-normal leading-4 text-main-secondary text-left"
                v-if="item.description">
                {{ item?.description }}
              </small>
            </div>
          </Link>
          <button
            v-else-if="item.type === 'button'"
            class="flex items-center gap-[12px] py-3 px-4 w-full"
            :class="{
              'border-b border-main-divider': item.border,
              'hover:bg-main-lightGray/20 cursor-pointer': !item.disabled,
            }"
            @click="item.clickEvent">
            <CircleIcon
              size="2rem"
              :class="{
                'bg-red-200': item.danger,
              }">
              <i
                :class="
                  cn(item.icon, {
                    'text-red-500': item.danger,
                  })
                "></i>
            </CircleIcon>
            <div class="flex flex-col gap-[2px] items-start justify-start">
              <p
                class="mb-0 w-max text-[16px] font-medium leading-5"
                :class="{
                  'text-[#ff3b30]': item.danger,
                }">
                {{ item?.label }}
              </p>
              <small
                class="text-xs font-normal leading-4 text-main-secondary text-left"
                v-if="item.description">
                {{ item?.description }}
              </small>
            </div>
          </button>
        </div>
      </template>
    </Menu>
  </Layout>
</template>

<script setup lang="ts">
import CompactCard from "@/components/contacts/content/CompactCard.vue";
import FullCard from "@/components/contacts/content/FullCard.vue";
import Layout from "@/components/contacts/Layout.vue";
import UserCard from "@/components/general/UserCard.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { useToastr } from "@/composables/useToastr";
import { API } from "@/core/api";
import {
  type MenuButtonInterface,
  type UserInterface,
} from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
import { useContactsStore } from "@/store/Contacts";
import { DialogType, useDialogStore } from "@/store/Dialog";
import { cn } from "@/utils/cn";
import { Link, router } from "@inertiajs/vue3";
import { computed, ref } from "vue";
import { route } from "ziggy-js";
import _debounce from "lodash/debounce";

const authStore = useAuthStore();
const authUser = computed(() => authStore.getUser);
const contactsStore = useContactsStore();
const dialogStore = useDialogStore();
const groupsAPI = new API.ContactGroups();
const { success, error } = useToastr();
const props = defineProps({
  group: { required: true, type: Object },
});
const loading = ref(false);
const search = ref("");
const headerMenu = ref();

const layout = computed(() => contactsStore.layout);
const userCard = computed(() =>
  layout.value === "full" ? FullCard : CompactCard,
);

const group = computed(() => props.group);
const groupFiltered = computed(() => {
  const filteredGroups: Record<string, UserInterface[]> = {};

  // Iterate over each letter group
  Object.keys(group.value.grouped_users).forEach((letter) => {
    // Filter users within the group by the search term
    const filteredUsers = group.value.grouped_users[letter].filter(
      (user: UserInterface) => {
        return user.full_name
          .toLowerCase()
          .includes(search.value.toLowerCase());
      },
    );

    // If there are filtered users, add them to the filteredGroups object
    if (filteredUsers.length > 0) {
      filteredGroups[letter] = filteredUsers;
    }
  });

  return filteredGroups;
});

const ellipsesMenu = computed<MenuButtonInterface[]>(() => {
  return [
    {
      label: "Edit",
      icon: "fa fa-pencil",
      type: "button",
      clickEvent: () => {
        dialogStore.show(DialogType.MANAGE_GROUP, undefined, {
          id: props.group.id,
          name: props.group.name,
          users: props.group.users.map((user: any) => {
            const { bizcard, ...rest } = user;
            return rest;
          }),
        });
        dialogStore.setEvents(DialogType.MANAGE_GROUP, {
          close: () => {
            dialogStore.hide(DialogType.MANAGE_GROUP);
          },
        });
      },
    },
    {
      label: "Delete Group",
      icon: "fa fa-trash",
      type: "button",
      danger: true,
      clickEvent: async () => {
        try {
          const response = await groupsAPI.delete(props.group.id);
          if (response.hasOwnProperty("success")) {
            contactsStore.removeGroup(props.group.id);
            router.visit(route("contacts.index"));
            success(response.success);
          }
          if (response.hasOwnProperty("error")) {
            error(response.error);
          }
        } catch (err) {
          console.error(err);
        }
      },
    },
  ];
});

const toggleMenu = (event: any) => {
  if (headerMenu.value) headerMenu.value.toggle(event);
};
const favorites = computed(() => contactsStore.favorites || []);
const is_favorite = (user: UserInterface): Boolean => {
  return favorites.value.findIndex((f) => f.id === user.id) !== -1;
};
const handleMobileDialog = () => {
  dialogStore.show(DialogType.MOBILE_APP);
  dialogStore.setEvents(DialogType.MOBILE_APP, {
    close: () => dialogStore.hide(DialogType.MOBILE_APP),
  });
};
const toggleFavorite = _debounce(async (user: any) => {
  contactsStore.toggleFavorite(user);
}, 250);
</script>
