<template>
  <Layout
    :header="{
      title: props.user.full_name,
      subtitle: props.user.roles.map((v) => v.name),
      user: props.user,
      card,
    }"
  >
    <template #subheaderActions>
      <CircleIcon
        v-for="action in ACTIONS.filter((action) => action.show)"
        :key="action.icon"
        :class="
          cn('bg-main-secondary hover:bg-main-secondary/80 text-white', action.class)
        "
        @click="action.clickEvent"
        size="2.5rem"
      >
        <i :class="action.icon"></i>
      </CircleIcon>
      <CircleIcon
        v-if="!owned_profile"
        class="bg-main-secondary hover:bg-main-secondary/80 text-white"
        size="2.5rem"
      >
        <i class="fa fa-ellipsis-vertical"></i>
      </CircleIcon>
    </template>
    <div class="p-8 min-h-screen lg:px-0 flex justify-center w-full">
      <div class="grid grid-cols-5 max-w-[1180px] w-full gap-4">
        <div
          class="col-span-full grid grid-cols-5 items-end gap-4"
          v-if="right.length === 1 && props.card.available_to_work"
        >
          <div class="col-span-full lg:col-span-3 order-2 lg:order-1">
            <component
              v-if="props.card.social_networks.length"
              :is="Socials"
              v-bind="props"
            ></component>
          </div>
          <div class="col-span-full lg:col-span-2 order-1 lg:order-2">
            <component :is="Availability" v-bind="props"></component>
          </div>
        </div>
        <div
          v-if="left.length"
          id="left"
          :class="{
            '!col-span-full':
              right.length === 0 || (right.length === 1 && props.card.available_to_work),
          }"
          class="col-span-full lg:col-span-3 flex flex-col gap-6 lg:w-full"
        >
          <component
            v-for="item in left"
            :key="item.key"
            :is="item.component"
            v-bind="props"
          />
        </div>
        <div
          v-if="right.length && !(right.length === 1 && props.card.available_to_work)"
          id="right"
          class="col-span-full lg:col-span-2 flex flex-col gap-6 lg:w-full"
        >
          <component
            v-for="item in right"
            :key="item.key"
            :is="item.component"
            v-bind="props"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script lang="ts" setup>
import { reactive, ref, computed, onMounted, watch, markRaw } from "vue";
import Layout from "@/components/bizcard/home/Layout.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { useToastr } from "@/composables/useToastr";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { cn } from "@/utils/cn";
import type { Component, PropType } from "vue";
import Availability from "./components/Availability.vue";
import Bio from "./components/Bio.vue";
import Contact from "./components/Contact.vue";
import GeneralInfo from "./components/GeneralInfo.vue";
import Keywords from "./components/Keywords.vue";
import MemberSince from "./components/MemberSince.vue";
import Representation from "./components/Representation.vue";
import Socials from "./components/Socials.vue";

/* ---------- REACTIVE DATA ----------*/

/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});
/* -------- COMPOSABLE -------- */

/* -------- STATE -------- */
const { success, error } = useToastr();
const bizcardStore = useUserBizcardStore();
const is_editing = computed(() => bizcardStore.getIsEditing);
const owned_profile = computed(() => bizcardStore.own_profile);
const ContentDialogVisible = ref(false);
const isGeneralInfoVisible = computed(
  () =>
    is_editing.value || props.user.show_current_occupation || props.user.industries.length
);
const left = computed<{ component: Component; active: Boolean; key?: string }[]>(() =>
  [
    {
      key: "Socials",
      component: markRaw(Socials),
      active:
        (is_editing.value || props.card.social_networks?.length) &&
        !(right.value.length === 1 && props.card.available_to_work),
    },
    {
      key: "Bio",
      component: markRaw(Bio),
      active: !!props.card.about || is_editing.value,
    },
    {
      key: "GeneralInfo",
      component: markRaw(GeneralInfo),
      active: isGeneralInfoVisible.value,
    },
    {
      key: "MemberSince",
      component: markRaw(MemberSince),
      active: true,
    },
  ].filter((i) => i.active)
);
const right = computed<{ component: Component; active: Boolean; key?: string }[]>(() =>
  [
    {
      key: "Availability",
      component: markRaw(Availability),
      active: props.card.available_to_work || is_editing.value,
    },
    {
      key: "Contact",
      component: markRaw(Contact),
      active: props.card.contact_information?.length || is_editing.value,
    },
    {
      key: "Representation",
      component: markRaw(Representation),
      active: props.card.representations?.length || is_editing.value,
    },
    {
      key: "Keywords",
      component: markRaw(Keywords),
      active: props.card.tags?.length || is_editing.value,
    },
  ].filter((i) => i.active)
);

const ACTIONS = computed(() => [
  {
    icon: "fa-regular fa-circle-plus",
    show: is_editing.value,
    clickEvent: () => {
      ContentDialogVisible.value = true;
    },
  },
  {
    icon: "fa-regular fa-pen",
    class: is_editing.value ? "bg-switcherBlue-500 hover:bg-switcherBlue-600" : "",
    show: owned_profile.value,
    clickEvent: bizcardStore.toggleEditing,
  },
  {
    icon: "fa-regular fa-share",
    show: true,
    clickEvent: async () => {
      const permissionStatus = await navigator.permissions.query({
        name: "clipboard-write",
      });
      if (permissionStatus.state === "granted" || permissionStatus.state === "prompt") {
        const url = route("user.profile.info", props.user?.public_username); // TODO route for services
        await navigator.clipboard.writeText(url);
        success(`Copied to clipboard: ${url}`);
      }
    },
  },
]);
/* -------- METHODS -------- */

/* -------- HOOKS -------- */
onMounted(() => {
  bizcardStore.init(props.card, props.user);
});
/* -------- WATCHERS -------- */
</script>
