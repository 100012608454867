<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'relative p-0 w-full flex-1 flex flex-col ',
      root: ' bg-main-lightBg max-w-[900px] w-full rounded-[16px] h-screen scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section
      id="header"
      class="p-2 !bg-main-primary text-white border-b-[1px] border-surface-500 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">
          {{ form.id ? "Edit Portfolio Content" : "Portfolio Content" }}
        </h1>
        <CircleIcon class="bg-main-secondary" @click="$emit('close')">
          <i class="fa fa-x"></i>
        </CircleIcon>
      </div>
    </section>
    <ContentComponent
      v-model="form"
      :contentTypeSelector="true"
      :galleryManager="true"
      :classes="{
        left: 'w-[400px] max-h-[88vh]',
        right: 'w-[500px] max-h-[88vh]',
      }"></ContentComponent>
    <section
      id="footer"
      class="!bg-main-primary sticky bottom-0 z-20 rounded-b-[16px]">
      <div class="flex justify-end items-center py-2.5 px-4 gap-2">
        <button
          :disabled="form.items.length === 0"
          @click="ManageCoverDialogVisible = true"
          class="border-[1px] border-white text-white rounded text-xs w-[180px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent hover:bg-switcherBlue-500 disabled:hover:border-[1px] hover:border-0 disabled:border-main-disabled disabled:text-main-disabled">
          Manage Cover
        </button>
        <PrimeVueButton
          :disabled="!canUpload || isUploading"
          class="!bg-switcherBlue-500 hover:!bg-switcherBlue-500/80 text-white disabled:!border-[1px] disabled:!bg-transparent disabled:!border-main-disabled disabled:!text-main-disabled rounded text-xs w-[180px] h-[40px]"
          @click="handleUpload"
          :loading="isUploading"
          :label="form.id ? 'Update' : 'Upload'"></PrimeVueButton>
      </div>
    </section>
    <div class="absolute inset-0 bg-black/20 z-50" v-if="isUploading"></div>
  </Dialog>

  <ManageCoverDialog
    v-if="ManageCoverDialogVisible"
    @close="ManageCoverDialogVisible = false"
    v-model="form" />
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue";
import ManageCoverDialog from "./parts/ManageCover.vue";
import PrimeVueButton from "primevue/button";
import { router, useForm } from "@inertiajs/vue3";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";
import { useHomeFeedStore } from "@/store/HomeFeed";
import { route } from "ziggy-js";
import ContentComponent from "./parts/Content.vue";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { useDialogStore, DialogType } from "@/store/Dialog";
import { useAuthStore } from "@/store/Auth";
import {
  upload,
  isBase64,
  isCorsLink,
  convertToBase64,
} from "@/utils/imageKit";
const authStore = useAuthStore();
const user = computed(() => authStore.getUser);
const dialogStore = useDialogStore();
const homeFeedStore = useHomeFeedStore();
const bizcardStore = useUserBizcardStore();
const { success, error } = useToastr();
const imageKitAPI = new API.imageKit();

const emit = defineEmits(["close", "update"]);
/* ---------- REACTIVE DATA ----------*/
const props = defineProps({
  content: {
    type: Object,
    default: null,
  },
});
const content = computed(
  () => dialogStore.getData(DialogType.UPLOAD_CONTENT)?.content,
);
const contentAPI = new API.Content();
const ManageCoverDialogVisible = ref(false);

const collaborators = computed(() => {
  // IF NEW CONTENT JUST APPEND THE AUTH USER
  if (!content.value?.id) return [{ ...authStore.getUser, roles: [] }];
  else return content.value?.collaborators;
});
const form = useForm<any>({
  id: content.value?.id ?? null,
  isValid: {},
  content_type: content.value?.content_type ?? null,
  title: content.value?.title ?? null,
  description: content.value?.description ?? null,
  categories: content.value?.categories ?? [],
  tags: content.value?.tags ?? [],
  project_types: content.value?.project_types ?? [],
  collaborators: collaborators.value,
  project_roles:
    content.value?.owner_roles?.map((i) => ({ id: i.id, name: i.name })) ?? [],
  project_companies: content.value?.organizations ?? [],
  project_completed_on: content.value?.completion_year ?? null,
  related_services: content.value?.related_services?.map((i) => i.id) ?? [],
  items: content.value?.items ?? [],
  thumbnail: {
    video: content.value?.image_kit_id_16_9 ?? null,
    square: content.value?.thumbnail_image_kit_id ?? null,
    default: content.value?.thumbnail_image_kit_id ?? null,
  },
  is_featured: content.value?.is_featured,
});

const canUpload = computed(() => {
  return (
    form.items.length > 0 &&
    form.title &&
    !Object.values(form.isValid).includes(false) &&
    !form.items.some((i: any) => !i.valid) &&
    isCollaboratorsValid.value &&
    hasValidCover.value &&
    linkGalleryValidation.value
  );
});
const hasValidCover = computed(
  () =>
    !!form.thumbnail.video &&
    !!form.thumbnail.square &&
    !!form.thumbnail.default,
);
const linkGalleryValidation = computed(() => {
  if (form.content_type === "link_gallery") {
    const checkTitle = form.items.length > 1;
    if (
      form.items.some((i) => (checkTitle && !!!i.title) || !i.thumbnail.default)
    )
      return false;
  }
  return true;
});
const isCollaboratorsValid = computed(() =>
  form.collaborators
    ? form.collaborators.length
      ? !form.collaborators
          .filter((c) => c.id !== authStore.getUser.id)
          .some((i) => !i.roles.length)
      : true
    : true,
);
const isUploading = ref(false);
/* ---------- FUNCTIONS ----------*/

const handleUpload = async () => {
  let response;
  try {
    isUploading.value = true;

    // Check if we are updating or creating content
    await uploadImagesToImageKit();
    response = content.value
      ? await contentAPI.update(form)
      : await contentAPI.create(form);

    if (response && response.error) throw new Error(response.error);

    if (response && response.data) {
      // Update data based on the current route
      switch (route().current()) {
        case "social_posts.index":
          router.reload({ only: ["post"] });
          break;
        case "home":
          homeFeedStore.editContent(response.data);
          break;
        case "bizcards.content.get":
          router.reload({ only: ["content"] });
          break;
        case "user.profile.index":
          bizcardStore.editContent(response.data);
          break;
        default:
          break;
      }

      // Check if we're editing or creating, and handle featured count
      if (content.value) {
        if (content.value?.is_featured && !response.data.is_featured) {
          authStore.editFeaturedCount("decrement");
        } else if (!content.value?.is_featured && response.data.is_featured) {
          authStore.editFeaturedCount("increment");
        }
        success("Content updated successfully!");
        emit("update", response.data);
      } else {
        if (form.is_featured) {
          authStore.editFeaturedCount("increment");
        }
        success("Content uploaded successfully!");
      }

      // Additional handling if creating new content
      if (!form.id) {
        dialogStore.show(DialogType.CONTENT_SHARE_POST, response.data.id, {
          id: response.data.id,
        });
      }

      emit("close", response.data.id);
    }
  } catch (err) {
    console.error(err);
    error(err.message || "Something went wrong!");
  } finally {
    isUploading.value = false;
  }
};

const uploadImagesToImageKit = async () => {
  try {
    // ITEMS THUMBNAILS
    for (const item of form.items) {
      let thumbnail = item.thumbnail.default;
      if (isCorsLink(thumbnail)) {
        thumbnail = await convertToBase64(thumbnail);
      }
      if (isBase64(thumbnail)) {
        let res = await upload(thumbnail, "ITEM_THUMBNAIL");
        if (res && res.error) throw new Error(res.error);
        // CHECK IF THE COVERS MATCH SO WE DON'T UPLOAD MULTIPLE TIME
        if (form.thumbnail.default === thumbnail)
          form.thumbnail.default = res.url;
        if (form.thumbnail.square === thumbnail)
          form.thumbnail.square = res.url;
        if (form.thumbnail.video === thumbnail) form.thumbnail.video = res.url;

        // update the item thumbnail
        item.thumbnail.default = res.url;
      }
    }

    // DEFAULT THUMBNAIL
    if (isCorsLink(form.thumbnail.default)) {
      form.thumbnail.default = await convertToBase64(form.thumbnail.default);
    }
    if (isBase64(form.thumbnail.default)) {
      let res_default = await upload(
        form.thumbnail.default,
        "CONTENT_DEFAULT_THUMBNAIL",
      );
      if (res_default && res_default.error) throw new Error(res_default.error);
      form.thumbnail.default = res_default.url;
    }

    // SQUARE THUMBNAIL
    if (isCorsLink(form.thumbnail.square)) {
      form.thumbnail.square = await convertToBase64(form.thumbnail.square);
    }
    if (isBase64(form.thumbnail.square)) {
      let res_square = await upload(
        form.thumbnail.square,
        "CONTENT_SQUARE_THUMBNAIL",
      );
      if (res_square && res_square.error) throw new Error(res_square.error);
      form.thumbnail.square = res_square.url;
    }

    // VIDEO THUMBNAIL
    if (isCorsLink(form.thumbnail.video)) {
      form.thumbnail.video = await convertToBase64(form.thumbnail.video);
    }
    if (isBase64(form.thumbnail.video)) {
      let res_video = await upload(
        form.thumbnail.video,
        "CONTENT_VIDEO_THUMBNAIL",
      );
      if (res_video && res_video.error) throw new Error(res_video.error);
      form.thumbnail.video = res_video.url;
    }
  } catch (err) {
    console.error(err);
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
