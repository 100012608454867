<template>
  <Chip
    :label="label"
    :removable="removable"
    :pt="preset"
    @remove="handleRemove">
    <h1>{{ label }}</h1>
    <template #removeicon="slotProps">
      <i
        :class="cn('far fa-times', preset.removeIcon.class)"
        @click="slotProps.removeCallback"
        @keydown.esc="slotProps.keydownCallback"></i>
    </template>
    <slot />
  </Chip>
</template>

<script lang="ts" setup>
import CustomChipPreset from "@/assets/tailwind-preset/chip/custom-chip";
import { cn } from "@/utils/cn";

const emit = defineEmits(["remove"]);
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  removable: {
    type: Boolean,
    default: false,
  },
  rootClass: {
    type: String,
  },
});

const preset = {
  ...CustomChipPreset,
  root: {
    ...CustomChipPreset.root,
    class: cn(CustomChipPreset.root.class, props.rootClass),
  },
};

const handleRemove = (e: any) => {
  emit("remove", e);
};
</script>
