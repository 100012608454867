<template>
  <Menu
    id="overlay_menu"
    :model="MENU"
    class="w-[21rem] content_menu overflow-hidden shadow-[0_2px_15px_0_rgba(0,0,0,0.3)]"
    ref="cropperAspectMenuRef"
    :popup="true"
    :pt="{
      content: 'hover:!bg-transparent',
      root: 'opacity-90 max-w-[200px]',
      menuItem: 'hover:!bg-red-500',
    }"
    :ptOptions="{ mergeProps: true }">
    <template #item="{ item }">
      <div
        class="flex gap-2 items-center text-white p-2 bg-black bg-opacity-80 cursor-pointer opacity-90"
        @click="item.clickEvent">
        <p>{{ item.title }}</p>
        <i :class="item.icon"></i>
      </div>
    </template>
  </Menu>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
const props = defineProps(["default_aspect"]);
const cropperAspectMenuRef = ref();
const emit = defineEmits(["update"]);
const update = (aspect) => {
  emit("update", aspect);
};
const MENU = [
  {
    title: "Original",
    icon: "fa-light fa-image",
    clickEvent: () => update(props.default_aspect),
  },
  {
    title: "1:1",
    icon: "fa-light fa-square",
    clickEvent: () => update(1 / 1),
  },
  {
    title: "9:16",
    icon: "fa-regular fa-rectangle-vertical",
    clickEvent: () => update(9 / 16),
  },
  {
    title: "4:5",
    icon: "fa-sharp fa-thin fa-rectangle-wide fa-rotate-90",
    clickEvent: () => update(4 / 5),
  },
  {
    title: "16:9",
    icon: "fa-sharp fa-light fa-rectangle-wide",
    clickEvent: () => update(16 / 9),
  },
];
const toggle = (event) => {
  cropperAspectMenuRef.value.toggle(event);
};

defineExpose({
  toggle,
});
</script>
