<template>
  <Layout>
    <div
      class="block justify-between items-center p-4 mx-4 mt-4 mb-6 bg-white rounded-2xl shadow-xl shadow-gray-200 lg:p-5 sm:flex h-[100%]"
    >
      <div class="mb-1 w-full">
        <div class="mb-4">
          <nav class="flex mb-5" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-2">
              <li>
                <div class="flex items-center">
                  <i class="fad fa-sign-posts text-primary-500"></i>
                  <Link
                    :href="route('admin.social_posts.index')"
                    class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2"
                    >Social Posts
                  </Link>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <svg
                    class="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span
                    class="ml-1 text-sm font-medium text-gray-400 md:ml-2"
                    aria-current="page"
                    >List</span
                  >
                </div>
              </li>
            </ol>
          </nav>
          <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl">
            All Social Posts
          </h1>
        </div>
        <div class="sm:flex">
          <div
            class="hidden items-center mb-3 sm:flex sm:divide-x sm:divide-gray-100 sm:mb-0"
          >
            <form class="lg:pr-3" action="#" method="GET">
              <label for="social-posts-search" class="sr-only">Search</label>
              <div class="relative mt-1 lg:w-64 xl:w-96">
                <input
                  type="text"
                  name="name"
                  v-model="lazyParams.search"
                  id="social-posts-search"
                  @input="globalSearch"
                  class="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-2 focus:ring-fuchsia-50 focus:border-fuchsia-300 block w-full p-2.5"
                  placeholder="Search for social posts"
                />
              </div>
            </form>
            <label class="mr-2">Active Only</label>
            <Button
              type="button"
              @click="
                {
                  lazyParams.active_only = !lazyParams.active_only;
                  globalSearch();
                }
              "
              :icon="
                lazyParams.active_only
                  ? 'fa-solid fa-toggle-on fa-xl'
                  : 'fa-solid  fa-toggle-off fa-xl'
              "
              v-tooltip.top="'Show active records only'"
              rounded
              text
              :pt="{
                root: {
                  class: '!p-1 !w-8',
                },
              }"
              :ptOptions="{ mergeProps: true }"
            >
            </Button>
          </div>
          <div class="flex items-center ml-auto space-x-2 sm:space-x-3">
            <Button
              type="button"
              class="bg-red-500 hover:bg-red-600"
              @click="deleteRecords"
              v-if="selectedRows.length"
              :badge="selectedRows.length.toString()"
              badgeSeverity="danger-inverse"
              icon="fa-regular fa-trash-alt"
              label="Delete"
            >
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-6 mb-8 mx-4 rounded-2xl shadow-xl shadow-gray-200">
      <div class="overflow-x-auto rounded-2xl">
        <div class="inline-block min-w-full align-middle">
          <div class="overflow-hidden shadow-lg">
            <DataTable
              :value="rows"
              sortField="id"
              :sortOrder="-1"
              lazy
              paginator
              :first="first"
              :rows="10"
              :rowsPerPageOptions="[5, 10, 20, 50]"
              ref="dt"
              dataKey="id"
              :totalRecords="totalRecords"
              :loading="loading"
              @page="onPage($event)"
              @sort="onSort($event)"
              v-model:selection="selectedRows"
              :selectAll="selectAll"
              @select-all-change="onSelectAllChange"
              @row-select="onRowSelect"
              @row-unselect="onRowUnselect"
              tableStyle="min-width: 75rem"
              resizableColumns
              columnResizeMode="fit"
              :pt="AdminDatatablePreset"
            >
              <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
              <Column field="id" header="# ID" sortable> </Column>
              <Column field="title" header="Title" sortable> </Column>
              <Column field="active" header="Active" sortable>
                <template #body="slotProps">
                  <Button
                    type="button"
                    @click="
                      toggleSocialPostActive(slotProps.data.id, !slotProps.data.is_active)
                    "
                    :icon="
                      slotProps.data.is_active
                        ? 'fa-solid fa-toggle-on fa-xl'
                        : 'fa-solid  fa-toggle-off fa-xl'
                    "
                    v-tooltip.top="'Activate/Deactivate Social Post'"
                    rounded
                    text
                    :pt="{
                      root: {
                        class: '!p-1 !w-8',
                      },
                    }"
                    :ptOptions="{ mergeProps: true }"
                  >
                  </Button>
                </template>
              </Column>
              <Column field="actions" header="Actions">
                <template #body="slotProps">
                  <Link
                    :href="route('admin.social_posts.edit', slotProps.data.id)"
                    class="font-semibold pl-1 hover:text-switcherBlue-500"
                  >
                    <i class="fa-regular fa-pencil"></i>
                  </Link>
                  <Link
                    :href="route('admin.social_posts.preview', slotProps.data.id)"
                    class="font-semibold pl-1 hover:text-switcherBlue-500"
                  >
                    <i class="fa-regular fa-eye ml-3"></i>
                  </Link>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script lang="ts" setup>
import NoProfilePic from "@/assets/images/nouser.svg";
import AdminDatatablePreset from "@/assets/tailwind-preset/datatable/admin-datatable";
import Layout from "@/components/layout/admin/Layout.vue";
import { API } from "@/core/api";
import { onMounted, ref } from "vue";
import { route } from "ziggy-js";
import { Link, router } from "@inertiajs/vue3";
import _debounce from "lodash/debounce";
import { cn } from "@/utils/cn";

onMounted(() => {
  loading.value = true;

  lazyParams.value = {
    first: 0,
    rows: 10,
    sortField: null,
    sortOrder: null,
    search: null,
    active_only: true,
  };

  loadLazyData();
});

const SocialPostsAPI = new API.AdminSocialPosts();
const dt = ref();
const loading = ref(false);
const totalRecords = ref(0);
const rows = ref();
const selectedRows = ref([]);
const selectAll = ref(false);
const first = ref(0);

const lazyParams = ref<any>({});
const loadLazyData = (event: any | null = null) => {
  resetSelectAll();
  loading.value = true;
  lazyParams.value = { ...lazyParams.value, first: event?.first || first.value };

  SocialPostsAPI.get(lazyParams.value).then((res) => {
    rows.value = res.data;
    totalRecords.value = res.meta.total;
    loading.value = false;
  });
};
const onPage = (event: any) => {
  lazyParams.value = event;
  loadLazyData(event);
};
const onSort = (event: any) => {
  lazyParams.value = event;
  loadLazyData(event);
  console.log(event);
};

const globalSearch = _debounce(async () => {
  loadLazyData();
}, 500);

const resetSelectAll = () => {
  selectAll.value = false;
  selectedRows.value = [];
};

const onSelectAllChange = (event: any) => {
  selectAll.value = event.checked;

  if (selectAll.value) selectedRows.value = rows.value;
  else {
    selectAll.value = false;
    selectedRows.value = [];
  }
};
const onRowSelect = () => {
  selectAll.value = selectedRows.value.length === totalRecords.value;
};
const onRowUnselect = () => {
  selectAll.value = false;
};

const toggleSocialPostActive = (post: number, active: boolean) => {
  router.post(
    route("admin.social_posts.toggle_active", { post }),
    { active: active },
    {
      onSuccess: (res) => {
        loadLazyData();
      },
      onError: (error) => {
        console.log(
          "[DEBUG] :: social_posts/Index.vue :: toggleSocialPostActive :: ",
          error
        );
      },
    }
  );
};

const deleteRecords = () => {
  if (selectedRows.value.length) {
    router.post(
      route("admin.social_posts.delete.selected"),
      { ids: selectedRows.value.map((post: any) => post.id) },
      {
        onSuccess: (res) => {
          loadLazyData();
          resetSelectAll();
        },
        onError: (error) => {
          console.log("[DEBUG] :: social_posts/Index.vue :: deleteRecords :: ", error);
        },
      }
    );
  }
};
</script>
