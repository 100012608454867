<template>
  <ConfirmDialog
    color="danger"
    @confirm="$emit('confirm')"
    @close="$emit('close')"
    action="Continue"
    cancel="cancel">
    <template #title>Re-send collaboration invite</template>
    <template #description>
      {{ message[action] }}
    </template>
  </ConfirmDialog>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch, PropType } from "vue";
import type { UserInterface } from "@/core/interfaces";
import ConfirmDialog from "@/components/dialogs/Confirm.vue";
const props = defineProps({
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
  action: {
    required: true,
    type: String,
  },
});
const emit = defineEmits(["close", "confirm"]);
const message = computed(() => ({
  accepted:
    "${NAME} has already accepted their invite to be a collaborator. Changing the roles will remove them and send a new invite.".replace(
      "${NAME}",
      props.user.full_name,
    ),
  pending:
    "Changing the roles will send a new invite to ${NAME} to be a collaborator.".replace(
      "${NAME}",
      props.user.full_name,
    ),
}));
</script>
