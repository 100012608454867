<template>
  <CustomCard :pt="cardPt" customClass="relative">
    <template #title>
      <div class="flex justify-between">
        <span class="font-semibold text-lg">Opportunities</span>
        <div class="flex gap-2" v-if="form.isDirty">
          <div v-tooltip.top="'Cancel'" @click="form.reset()">
            <i class="fa fa-times text-lg cursor-pointer text-primary-200"></i>
          </div>
          <div v-tooltip.top="'Save'" @click="handleSave">
            <i class="fa fa-check text-lg cursor-pointer text-primary-500"></i>
            <div
              v-if="form.processing"
              class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center bg-white bg-opacity-50 w-full h-full z-50">
              <i class="fas fa-spinner-third fa-spin text-primary-500"></i>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #subtitle>
      <span class="text-sm text-main-secondary">
        What type of opportunities are you interested in?
      </span>
    </template>
    <template #content>
      <div class="flex flex-col gap-3">
        <SelectButton
          v-model="form.opportunities"
          :multiple="true"
          :options="OPPORTUNITY_OPTIONS"
          aria-labelledby="basic"
          :pt="opportunitiesPreset" />
      </div>
    </template>
  </CustomCard>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { PropType } from "vue";
import CustomCard from "@/components/general/CustomCard.vue";
import servicesOptionsPreset from "@/assets/tailwind-preset/selectbutton/services";
import { cn } from "@/utils/cn";
import { router, useForm } from "@inertiajs/vue3";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";

/* -------- PROPS -------- */
const props = defineProps({
  card: {
    type: Object as PropType<BizCardInterface>,
    required: true,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});
/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();
const bizCardServicesAPI = new API.BizcardServices();
const { success, error } = useToastr();

/* -------- STATE -------- */
const isEditing = computed(() => bizcardStore.getIsEditing);

const form = useForm({
  opportunities: props.card.opportunity_settings,
});

const cardPt = computed(() => ({
  root: {
    "border border-primary-200": form.isDirty,
  },
}));

const OPPORTUNITY_OPTIONS = [
  "Freelance",
  "Full-time",
  "Part-time",
  "Seasonal",
  "Internship",
  "Contract",
];

const opportunitiesPreset = computed(() => ({
  ...servicesOptionsPreset,
  root: (options: any) => ({
    class: cn(
      servicesOptionsPreset.root(options).class,
      "grid grid-cols-3 gap-2",
    ),
  }),
}));

/* -------- METHODS -------- */
const handleSave = async () => {
  if (form.processing) return;
  form.processing = true;
  try {
    const response = await bizCardServicesAPI.updateOpportunities(form);
    if (response.error) {
      error(response.error);
    } else {
      success("Opportunities updated successfully");
      form.defaults();
      form.reset();
      router.reload();
    }
  } catch (e: any) {
    console.error(e);
    error("Error: " + e.message);
  } finally {
    form.processing = false;
  }
};
</script>
