import { ExploreService } from "./Explore";
import { BizcardServicesService } from "./BizcardServices";
import { SkillsService } from "./Skills";
import { BizcardInfoService } from "./BizcardInfo";
import { ConnectionsService } from "./Connections";
import { LocationsService } from "./Locations";
import { SocialPostCommentsService as AdminSocialPostCommentsService } from "./Admin/SocialPostComments";
import { SocialPostsService as AdminSocialPostsService } from "./Admin/SocialPosts";
import { CompaniesService } from "./Admin/Companies";
import { IndustriesService } from "./Admin/Industries";
import { RolesService } from "./Admin/Roles";
import { OTPManagerService } from "./Admin/OTPManager";
import { UsersService as AdminUserService } from "./Admin/Users";
import { UsersService } from "./Users";
import { SocialPostCommentsService } from "./SocialPostComments";
import { SocialPostLikesService } from "./SocialPostLikes";
import { SocialPostService } from "./SocialPosts";
import { NotificationService } from "./Notifications";
import { InvitationService } from "./Invitations";
import { GlobalService } from "./Global";
import { TagsService } from "./Tags";
import { RolesService as ClientRolesService } from "./Roles";
import { IndustriesService as ClientIndustriesService } from "./Industries";
import { ProjectTypesService } from "./ProjectTypes";
import { RelatedServicesService } from "./RelatedServices";
import { ContentService } from "./Content";
import { CompaniesService as ClientCompaniesService } from "./Companies";
import { CategoriesService } from "./Categories";
import { MiscellaneousService } from "./Miscellaneous";
import { ContactGroupsService } from "./ContactGroups";
import { ContactsService } from "./Contacts";
import { BizcardService } from "./Bizcard";
import { ServicesService } from "./Services";
import { ImageKitService } from "./ImageKit";
import { ReportsService as AdminReportsService } from "./Admin/Reports";
import { ReportsService } from "./Reports";

export namespace API {
  export const Notifications = NotificationService;
  export const Invitations = InvitationService;
  export const Global = GlobalService;
  export const SocialPost = SocialPostService;
  export const SocialPostComments = SocialPostCommentsService;
  export const SocialPostLikes = SocialPostLikesService;
  export const Users = UsersService;
  export const AdminUsers = AdminUserService;
  export const OTPManager = OTPManagerService;
  export const Roles = RolesService;
  export const Industries = IndustriesService;
  export const RelatedServices = RelatedServicesService;
  export const Content = ContentService;
  export const ClientIndustries = ClientIndustriesService;
  export const Companies = CompaniesService;
  export const ClientCompanies = ClientCompaniesService;
  export const Tags = TagsService;
  export const ClientRoles = ClientRolesService;
  export const ProjectTypes = ProjectTypesService;
  export const AdminSocialPosts = AdminSocialPostsService;
  export const AdminSocialPostComments = AdminSocialPostCommentsService;
  export const Locations = LocationsService;
  export const Categories = CategoriesService;
  export const Miscellaneous = MiscellaneousService;
  export const ContactGroups = ContactGroupsService;
  export const Contacts = ContactsService;
  export const Bizcard = BizcardService;
  export const BizcardInfo = BizcardInfoService;
  export const BizcardServices = BizcardServicesService;
  export const Connections = ConnectionsService;
  export const Skills = SkillsService;
  export const Services = ServicesService;
  export const Explore = ExploreService;
  export const imageKit = ImageKitService;
  export const AdminReports = AdminReportsService;
  export const Reports = ReportsService;
}
