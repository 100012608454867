<template>
  <div class="space-y-2">
    <h1 class="font-medium">Profile completion progress</h1>
    <p class="text-xs">
      you are
      <span class="font-medium">{{ progress }}%</span>
      of the way there!
    </p>
    <ProgressBar
      :value="progress"
      class="!h-2.5"
      :showValue="false"></ProgressBar>
    <div class="bg-switcherBlue-500 text-white rounded-md p-4 space-y-1">
      <h1 class="text-semibold">Intro</h1>
      <p class="text-xs text-white/90">Tell us about yourself</p>
      <button
        class="w-full bg-white text-switcherBlue-500 py-1.5 rounded-md font-light text-sm"
        @click="dialogStore.show(DialogType.MANAGE_BIZCARD, user.id, { user })">
        Finish to be discoverable
      </button>
    </div>
    <hr class="border-main-divider" />
    <section id="steps" class="">
      <div
        v-for="step in STEPS"
        :key="step.title"
        class="flex items-center justify-between py-1.5"
        :class="{
          'border-b border-main-divider': step.title !== STEPS.at(-1).title,
        }">
        <div class="flex flex-col gap-0.5">
          <h1 class="font-semibold">{{ step.title }}</h1>
          <p class="text-primary/80 text-xs">{{ step.description }}</p>
        </div>
        <div class="flex gap-2">
          <button
            v-if="!step.is_finished && step.is_skippable"
            class="text-primary bg-main-disabled/50 text-sm py-1.5 px-4 rounded-md"
            @click="step.skipAction">
            Skip
          </button>
          <button
            v-if="!step.is_finished"
            class="text-primary bg-switcherBlue-500/20 text-switcherBlue-800 text-sm py-1.5 px-4 rounded-md"
            @click="step.finishAction">
            Finish
          </button>
          <button
            v-else
            class="pointer-events-none text-primary bg-green-500 text-white text-sm py-1.5 px-4 rounded-md">
            Complete
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import { DialogType, useDialogStore } from "@/store/Dialog";
import { useAuthStore } from "@/store/Auth";
const dialogStore = useDialogStore();
const user = computed(() => useAuthStore().getUser);
const STEPS = computed(() => [
  {
    is_finished: true,
    is_skippable: false,
    title: "Info",
    description: "Add at least on social link.",
    finishAction: () => {},
  },
  {
    is_finished: true,
    is_skippable: false,
    title: "First connection",
    description: "Start your networking journey.",
    finishAction: () => {},
  },
  {
    is_finished: false,
    is_skippable: true,
    title: "Kickstart your portfolio",
    description: "Upload a piece of content.",
    skipAction: () => {},
    finishAction: () => {},
  },
  {
    is_finished: false,
    is_skippable: true,
    title: "Get to work",
    description: "List at least one service.",
    skipAction: () => {},
    finishAction: () => {},
  },
  {
    is_finished: true,
    is_skippable: true,
    title: "Build your first group",
    description: "Organize your contacts.",
    skipAction: () => {},
    finishAction: () => {},
  },
]);
const progress = computed(
  () =>
    (STEPS.value.filter((s) => s.is_finished).length / STEPS.value.length) *
    100,
);
</script>
