<template>
  <div
    v-if="list.length"
    id="clients / organizations"
    class="p-4 space-y-2 w-full bg-white rounded-md shadow-default">
    <h1 class="text-lg text-main-primary font-medium">Project Details</h1>
    <div class="divide-y space-y-2">
      <div class="space-y-2 py-2" v-for="item in list">
        <h2 class="text-xs text-main-secondary uppercase">
          {{ item.title }}
        </h2>
        <div class="flex gap-2 items-center flex-wrap">
          <span
            v-for="child in item.children"
            :class="
              cn(
                'flex rounded-[4px] gap-[10px] h-[30px] text-primaryBlue-500 text-[13px] items-center justify-center py-2 px-4',
                item.class,
                {
                  'bg-[#EFF1F5]': item.itemBg,
                },
              )
            ">
            <img
              v-if="child.image"
              :src="child.image"
              class="size-[20px] rounded-full" />
            {{ child.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { cn } from "@/utils/cn";
import { reactive, ref, computed, onMounted, watch } from "vue";
const props = defineProps({
  content: {
    required: true,
    type: Object,
  },
});

const list = computed(() => {
  const list = [];
  if (props.content.organizations.length)
    list.push({
      itemBg: true,
      title: "CLIENT / ORGANIZATION",
      children: props.content.organizations,
    });
  if (props.content.project_types.length)
    list.push({
      itemBg: true,
      title: "PROJECT TYPE",
      children: props.content.project_types.map((i) => ({
        id: i.id,
        name: i.title,
      })),
    });
  if (props.content.completion_year)
    list.push({
      itemBg: false,
      class: "!text-sm !p-0",
      title: "COMPLETED ON",
      children: [{ name: props.content.completion_year }],
    });
  return list;
});
</script>
