<template>
  <div class="w-full flex flex-col gap-4 bg-main-bg">
    <Header />
    <div
      style="scroll-behavior: auto"
      class="mt-14 h-[calc(100vh-57px)] max-w-[1920px] flex items-center justify-center flex-wrap xl:flex-row xl:gap-[30px] md:gap-[15px] w-full overflow-y-auto box-border mx-auto relative scroll-hidden p-4 lg:p-0"
      scroll-region>
      <slot />
    </div>
  </div>

  <BlockUI :blocked="dialogStore[DialogType.UI_BLOCK_FULL].show" fullScreen>
    <template #default>
      <div
        class="fixed inset-0 flex items-center justify-center z-[9999]"
        v-if="dialogStore[DialogType.UI_BLOCK_FULL].show">
        <div class="flex flex-col items-center justify-center">
          <i class="fad fa-spinner-third fa-spin fa-3x text-white"></i>
          <div class="text-white mt-4">Loading...</div>
        </div>
      </div>
    </template>
  </BlockUI>
</template>

<script lang="ts" setup>
import { DialogType, useDialogStore } from "@/store/Dialog";
import Header from "./Header.vue";

const dialogStore = useDialogStore();
</script>
