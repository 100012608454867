<template>
  <div
    class="w-full flex justify-center items-center"
    :class="{
      'bg-main-lightBg': isLoggedIn(),
      'bg-white': !isLoggedIn(),
    }">
    <Navbar v-if="isLoggedIn()" />
    <GuestNavbar v-else />
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import { useAuthStore } from "@/store/Auth";
import Navbar from "./Navbar.vue";
import GuestNavbar from "./GuestNavbar.vue";
const authStore = useAuthStore();
const isLoggedIn = computed(() => authStore.isLoggedIn);
const props = defineProps({
  search: {
    type: Boolean,
    default: true,
  },
  plusIcon: {
    type: Boolean,
    default: true,
  },
});
</script>
