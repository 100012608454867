<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full flex-1 flex flex-col relative',
      root: '!bg-main-primary max-w-[800px] h-[765px]  w-full rounded-[16px] ',
    }"
    :visible="true"
    modal
    :closable="false"
  >
    <section
      id="header"
      class="p-2 bg-main-primary text-white border-b border-surface-500 sticky top-0 inset-x-0 z-50 rounded-t-[16px]"
    >
      <div class="flex justify-between items-center">
        <CircleIcon class="bg-main-secondary" @click="$emit('close')">
          <i class="fa fa-arrow-left text-white"></i>
        </CircleIcon>
        <h1 class="font-poppins text-xl flex-1 text-center">Manage Cover</h1>
        <br />
      </div>
    </section>
    <div id="previewWrapper" class="scroll-hidden !overflow-y-auto max-h-[675px]">
      <div class="flex justify-center items-center gap-1 text-white">
        <button
          @click="selectedAspect = 'square'"
          :class="{ 'border-b border-white': selectedAspect === 'square' }"
          class="w-[100px] p-1"
        >
          1:1
        </button>
        <button
          @click="selectedAspect = 'video'"
          :class="{ 'border-b border-white': selectedAspect === 'video' }"
          class="w-[100px] p-1"
        >
          16:9
        </button>
      </div>
      <section
        id="subheader"
        class="text-white h-[36px] bg-main-lightBg w-full text-center flex items-center justify-center gap-2 px-2"
      >
        <i class="text-xs fa fa-circle-exclamation"></i>
        <p class="text-xs">For grid layouts and featured spots</p>
      </section>
      <section
        id="content"
        class="h-[437px] flex items-center justify-center overflow-y-auto scroll-hidden bg-title"
      >
        <div
          class="relative"
          v-if="
            !editing &&
            (modelValue.thumbnail[selectedAspect] || modelValue.thumbnail['default'])
          "
        >
          <img
            :src="modelValue.thumbnail[selectedAspect] ?? modelValue.thumbnail['default']"
            class="h-[419px] rounded-[8px] object-cover"
            :class="{
              'aspect-square': selectedAspect === 'square',
              'aspect-video': selectedAspect === 'video',
            }"
          />
          <CircleIcon
            @click="
              () => {
                editing = true;
                cropperLoading = true;
              }
            "
            size="1.8rem"
            class="bg-main-secondary absolute top-2.5 right-2.5 cursor-pointer"
          >
            <i class="text-white text-sm fa fa-crop-simple"></i>
          </CircleIcon>

          <span
            class="text-white text-xs px-[9px] py-[5px] rounded-full absolute top-2.5 left-2.5 bg-main-success pointer-events-none"
          >
            Selected cover
          </span>
        </div>
        <LinkNoImage
          v-else-if="
            !editing &&
            !(modelValue.thumbnail[selectedAspect] || modelValue.thumbnail['default'])
          "
          class="max-w-[500px]"
          @upload="(e) => customBase64Uploader(e, index)"
        />
        <div class="relative" v-if="editing">
          <div
            class="absolute z-50 inset-0 flex items-center justify-center h-[419px] rounded-[8px] object-cover text-white"
            v-if="cropperLoading"
          >
            <i
              class="fa-sharp-duotone fa-solid fa-spinner-third animate-spin text-xl"
            ></i>
          </div>
          <cropper
            @ready="cropperLoading = false"
            ref="cropperEl"
            class="cropper h-[419px] rounded-[8px] object-cover"
            :src="modelValue.thumbnail[selectedAspect] ?? modelValue.thumbnail['default']"
            :stencil-props="{
              aspectRatio: selectedAspect === 'square' ? 1 / 1 : 16 / 9,
            }"
          ></cropper>
        </div>
      </section>

      <h1
        class="bg-black h-[36px] min-h-[36px] flex items-center justify-center text-main-disabled text-sm"
      >
        SELECT FROM EXISTING GALLERY
      </h1>
      <section id="thumbnail list" class="relative">
        <div
          class="absolute inset-0 z-50 bg-black opacity-50"
          id="overlay"
          v-if="editing"
        ></div>
        <div class="flex gap-2 items-center bg-title flex-wrap">
          <div
            class="relative"
            v-if="Object.keys(customThumbnail).length && customThumbnail[selectedAspect]"
          >
            <img
              :class="{
                'border-2 border-main-success': 999 === selectedIdx[selectedAspect],
                'aspect-square': selectedAspect === 'square',
                'aspect-video': selectedAspect === 'video',
              }"
              class="object-cover cursor-pointer h-[189px]"
              :src="customThumbnail[selectedAspect]"
              alt=""
              @click="() => handleSelectThumbnail(customThumbnail[selectedAspect], 999)"
            />
            <i
              class="absolute top-1 right-1 text-main-success fa fa-circle-check text-xl"
              v-if="999 === selectedIdx[selectedAspect]"
            ></i>
            <p
              class="absolute top-1 left-1 rounded-full px-2 py-0.5 text-white bg-switcherBlue-500 text-xs leading-tight tracking-tighter"
            >
              Custom
            </p>
          </div>
          <div class="relative" v-for="(item, index) in modelValue.items">
            <img
              :class="{
                'border-2 border-main-success': selectedIdx[selectedAspect] === index,
                'aspect-square': selectedAspect === 'square',
                'aspect-video': selectedAspect === 'video',
              }"
              class="object-cover cursor-pointer h-[189px]"
              :src="item.thumbnail.default || NoImageBg"
              alt=""
              @click="() => handleSelectThumbnail(item.thumbnail.default, index)"
            />

            <i
              class="absolute top-1 right-1 text-main-success fa fa-circle-check text-xl"
              v-if="selectedIdx[selectedAspect] === index"
            ></i>
          </div>
        </div>
      </section>
    </div>
    <section
      id="footer"
      class="!bg-main-primary sticky bottom-0 inset-x-0 border-t border-surface-500 z-50 rounded-b-[16px]"
    >
      <div class="flex justify-end items-center py-2.5 px-4 gap-2">
        <button
          @click="reset"
          v-if="!editing && modelValue.thumbnail[selectedAspect]"
          class="border-white text-white rounded text-xs w-[150px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent bg-red-600 disabled:hover:border-[1px] border-0 disabled:border-main-disabled"
        >
          Reset
        </button>
        <button
          @click="handleCrop"
          v-if="editing"
          class="border-white text-white rounded text-xs w-[220px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent bg-switcherBlue-500 disabled:hover:border-[1px] border-0 disabled:border-main-disabled"
        >
          Save
        </button>
        <FileUpload
          v-if="!editing"
          chooseLabel="Upload custom cover"
          mode="basic"
          accept="image/*"
          customUpload
          :auto="true"
          @uploader="customBase64Uploader"
          class="!font-normal !border-[1px] !border-white text-white rounded text-xs w-[220px] h-[40px] !bg-transparent !hover:bg-transparent hover:bg-switcherBlue-500 disabled:hover:border-[1px] hover:border-0 disabled:border-main-disabled"
        />
        <button
          @click="$emit('close')"
          v-if="!editing"
          class="border-white text-white rounded text-xs w-[220px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent bg-switcherBlue-500 disabled:hover:border-[1px] border-0 disabled:border-main-disabled"
        >
          Done
        </button>
      </div>
    </section>
  </Dialog>
</template>
<script setup>
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { ref, watch, computed, onMounted, reactive } from "vue";
import LinkNoImage from "@/components/dialogs/content/components/LinkNoImage.vue";
import NoImageBg from "@/assets/images/upload_content_icon/bg-img.png";
const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(["indexChange"]);
const cropperEl = ref();
const cropperLoading = ref(false);
const editing = ref(false);
const selectedIdx = reactive({ default: 0, square: 0, video: 0 });
const selectedItemIdx = ref();
const selectedAspect = ref("square");
const customThumbnail = reactive({});
onMounted(() => {
  currentThumbnail();
});

const handleSelectThumbnail = (thumbnail, index) => {
  if (selectedIdx[selectedAspect.value] !== index) {
    if (!!!thumbnail) {
      props.modelValue.thumbnail = {
        square: null,
        video: null,
        default: null,
      };
    } else {
      props.modelValue.thumbnail[selectedAspect.value] = thumbnail;
    }
    selectedIdx[selectedAspect.value] = index;
    selectedItemIdx.value = index;
  }
};
watch(selectedAspect, () => {
  editing.value = false;
  currentThumbnail();
});
const currentThumbnail = () => {
  const current = props.modelValue.thumbnail[selectedAspect.value];
  const currentIndex = props.modelValue.items.findIndex(
    (u) => u.thumbnail.default === current
  );
  if (currentIndex === -1) {
    customThumbnail[selectedAspect.value] = current;
    selectedIdx[selectedAspect.value] = 999;
  } else {
    selectedIdx[selectedAspect.value] = currentIndex;
    selectedItemIdx.value = currentIndex;
  }
};
const handleCrop = () => {
  const { canvas } = cropperEl.value.getResult();
  if (canvas) {
    const base64 = canvas.toDataURL();
    props.modelValue.thumbnail[selectedAspect.value] = base64;
    if (selectedIdx[selectedAspect.value] === 999) {
      customThumbnail[selectedAspect.value] = base64;
    } else {
      props.modelValue.items[selectedIdx[selectedAspect.value]].thumbnail[
        selectedAspect.value
      ] = base64;
    }
  }
  editing.value = false;
};

const reset = () => {
  if (selectedIdx[selectedAspect.value] === 999) {
    props.modelValue.thumbnail[selectedAspect.value] =
      customThumbnail[selectedAspect.value];
  } else {
    const item = props.modelValue.items[selectedIdx[selectedAspect.value]];
    item.thumbnail[selectedAspect.value] = item.thumbnail.default;
    props.modelValue.thumbnail[selectedAspect.value] = item.thumbnail.default;
  }
  currentThumbnail();
};

const customBase64Uploader = async (event, idx) => {
  const file = event.files[0];
  const reader = new FileReader();

  reader.onload = function (event) {
    const base64data = event.target.result;
    if (!itemHasThumbnail(props.modelValue)) {
      props.modelValue.thumbnail = {
        video: base64data,
        square: base64data,
        default: base64data,
      };
      props.modelValue.items[selectedItemIdx.value].thumbnail = {
        video: base64data,
        square: base64data,
        default: base64data,
      };
    } else {
      props.modelValue.thumbnail[selectedAspect.value] = base64data;
      selectedIdx[selectedAspect.value] = 999;
      customThumbnail[selectedAspect.value] = base64data;
    }
  };
  reader.onerror = function (error) {
    console.error(error);
  };

  reader.readAsDataURL(file);
};

const itemHasThumbnail = (item) => {
  return !!item.thumbnail.video && !!item.thumbnail.square && !!item.thumbnail.default;
};

watch(selectedItemIdx, (value) => {
  emit("indexChange", selectedAspect.value, props.modelValue.items[value].thumbnail.uuid);
});
</script>
