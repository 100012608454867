<template>
  <div class="bg-white rounded-lg gap-4 flex flex-col shadow-default">
    <div class="p-4">
      <UserCard
        :key="user?.id"
        :user="user"
        :showLocation="true"
        :showRelationship="false">
        <template #actions>
          <div class="flex gap-2 items-center justify-end">
            <CircleIcon
              class="bg-switcherBlue-500 hover:bg-switcherBlue-600 text-white"
              size="2.5rem"
              @click="handleMobileDialog"
              v-if="is_friend">
              <i class="fa fa-comment text-lg"></i>
            </CircleIcon>
            <CircleIcon
              class="bg-switcherBlue-500/20 text-switcherBlue-500 group"
              size="2.5rem"
              @click="() => toggleFavorite(user)"
              v-if="is_friend">
              <i
                class="fal fa-star text-lg group-hover:font-bold"
                :class="{ fas: is_favorite }"></i>
            </CircleIcon>
            <CircleIcon
              class="bg-switcherBlue-500/20 text-switcherBlue-500 group"
              size="2.5rem"
              @click="sendConnectionRequest"
              v-if="
                !is_friend &&
                !userStore.hasReceivedRequest(props.user) &&
                !userStore.hasSentRequest(props.user)
              ">
              <i class="fa fa-user-plus text-lg group-hover:font-bold"></i>
            </CircleIcon>
            <CircleIcon
              class="bg-[#ff9900] text-white group"
              size="2.5rem"
              @click="withdrawConnectionRequest"
              v-if="!is_friend && userStore.hasSentRequest(props.user)">
              <i class="fa fa-user-minus text-lg group-hover:font-bold"></i>
            </CircleIcon>
            <CircleIcon
              class="bg-transparent hover:bg-switcherBlue-500/20 text-main-lightGray"
              size="2.5rem"
              @click="toggle">
              <i class="fa fa-ellipsis-vertical text-xl"></i>
            </CircleIcon>
            <Menu
              id="overlay_menu"
              :model="ellipsesMenu.filter((v) => v.active)"
              class="w-[21rem] content_menu overflow-hidden shadow-[0_2px_15px_0_rgba(0,0,0,0.3)]"
              ref="menu"
              :popup="true"
              :pt="{ content: 'hover:!bg-transparent' }"
              :ptOptions="{ mergeProps: true }">
              <template #item="{ item }">
                <div
                  :class="{
                    'pointer-events-none text-main-disabled hover:bg-white':
                      item.disabled,
                  }">
                  <a
                    class="flex items-center gap-4 py-3 px-4 w-full"
                    :class="{
                      'border-b border-main-divider': item.border,
                      'hover:bg-main-lightGray/20': !item.disabled,
                    }"
                    v-if="item.type == 'external_link'"
                    :href="item.href"
                    target="_blank">
                    <CircleIcon>
                      <i :class="item.icon"></i>
                    </CircleIcon>

                    <div
                      class="flex flex-col gap-[2px] items-start justify-start">
                      <p
                        class="mb-0 w-max text-[16px] font-medium leading-5"
                        :class="{
                          'text-[#ff3b30]': item.danger,
                        }">
                        {{ item?.label }}
                      </p>
                      <small
                        class="text-xs font-normal leading-4 text-main-secondary text-left"
                        v-if="item.description">
                        {{ item?.description }}
                      </small>
                    </div>
                  </a>
                  <Link
                    v-else-if="item.type === 'inertia_link'"
                    :href="item.href"
                    class="flex items-center gap-4 py-3 px-4 w-full"
                    :class="{
                      'border-b border-main-divider': item.border,
                      'hover:bg-main-lightGray/20': !item.disabled,
                    }">
                    <CircleIcon :class="{ 'bg-[#ff3b30]/30': item.danger }">
                      <i :class="item.icon"></i>
                    </CircleIcon>

                    <div
                      class="flex flex-col gap-[2px] items-start justify-start">
                      <p
                        class="mb-0 w-max text-[16px] font-medium leading-5"
                        :class="{
                          'text-[#ff3b30]': item.danger,
                        }">
                        {{ item?.label }}
                      </p>
                      <small
                        class="text-xs font-normal leading-4 text-main-secondary text-left"
                        v-if="item.description">
                        {{ item?.description }}
                      </small>
                    </div>
                  </Link>
                  <button
                    v-else-if="item.type === 'button'"
                    @click="item.clickEvent"
                    class="flex items-center gap-[12px] py-3 px-4 w-full"
                    :class="{
                      'border-b border-main-divider': item.border,
                      'hover:bg-main-lightGray/20': !item.disabled,
                    }">
                    <CircleIcon size="2rem">
                      <i :class="item.icon"></i>
                    </CircleIcon>
                    <div
                      class="flex flex-col gap-[2px] items-start justify-start">
                      <p
                        class="mb-0 w-max text-[16px] font-medium leading-5"
                        :class="{
                          'text-[#ff3b30]': item.danger,
                        }">
                        {{ item?.label }}
                      </p>
                      <small
                        class="text-xs font-normal leading-4 text-main-secondary text-left"
                        v-if="item.description">
                        {{ item?.description }}
                      </small>
                    </div>
                  </button>
                </div>
              </template>
            </Menu>
          </div>
        </template>
      </UserCard>
    </div>
    <div class="pb-4" v-if="user.bizcard.bizcard_content.length">
      <ContentSlider
        :content="user.bizcard.bizcard_content"
        :user="user"
        :total="user.bizcard.bizcard_content_total_count"
        :max="10" />
    </div>
    <div
      v-else
      class="flex items-center justify-center bg-lightBg-500 text-black size-full rounded-b-lg min-h-[190px]">
      <span class="font-bold">{{ user.full_name }}</span>
      &nbsp;
      <span>hasn't uploaded any content yet.</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import UserCard from "@/components/general/UserCard.vue";
import "swiper/css";
import "swiper/css/navigation";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { API } from "@/core/api";
import Menu from "primevue/menu";
import { useDialogStore, DialogType } from "@/store/Dialog";
import { useContactsStore } from "@/store/Contacts";
import { Link } from "@inertiajs/vue3";
import _debounce from "lodash/debounce";
import { useToastr } from "@/composables/useToastr";
import type { MenuButtonInterface, UserInterface } from "@/core/interfaces";
import { useUserStore } from "@/store/User";
import type { PropType } from "vue";
import ContentSlider from "@/components/general/ContentSlider.vue";

const { success, error } = useToastr();
const contactsStore = useContactsStore();
const userStore = useUserStore();
const dialogStore = useDialogStore();

const emit = defineEmits(["reload"]);
const props = defineProps({
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});
const sendConnectionRequest = () => {
  try {
    userStore.connectUser(props.user.id);
    emit("reload");
  } catch (err: any) {
    error(err.message);
  }
};
const withdrawConnectionRequest = () => {
  {
    try {
      const connection = userStore.getConnectionRequest(props.user.id, "sent");
      if (!connection) throw "Connection request could not be found";
      userStore.withdrawConnectionRequest(connection);
      emit("reload");
    } catch (err: any) {
      error(err.message);
    }
  }
};
const menu = ref();
const ellipsesMenu = computed<MenuButtonInterface[]>(() => {
  return [
    {
      disabled: false,
      active: is_friend.value,
      label:
        contactsStore.isFavorite(props.user.id) !== -1
          ? "Remove from favorites"
          : "Add to favorites",
      border: true,
      type: "button",
      icon: "fa-sharp fa-regular fa-star text-primaryBlue-500",
      clickEvent: () => {
        toggleFavorite(props.user);
      },
    },
    {
      disabled: true,
      active: is_friend.value,
      label: "Send on bizlyMessenger",
      border: true,
      type: "button",
      icon: "fa-sharp fa-regular fa-comment text-primaryBlue-500",
    },

    /** Friend connections */
    {
      disabled: false,
      active:
        !is_friend.value &&
        !userStore.hasReceivedRequest(props.user) &&
        !userStore.hasSentRequest(props.user),
      label: `Connect with ${props.user.first_name}`,
      border: true,
      type: "button",
      icon: "fa-regular fa-user-plus text-primaryBlue-500",
      clickEvent: () => sendConnectionRequest(),
    },
    {
      disabled: false,
      active: is_friend.value,
      label: "Remove connection",
      border: true,
      type: "button",
      icon: "fa-sharp fa-regular fa-trash-can text-primaryBlue-500",
      clickEvent: async () => {
        try {
          userStore.removeConnection(props.user.id);
          emit("reload");
        } catch (err: any) {
          error(err.message);
        }
      },
    },
    /** Received Connection Request */
    {
      disabled: false,
      active: userStore.hasReceivedRequest(props.user),
      label: `Accept Connection`,
      border: false,
      type: "button",
      icon: "fa-regular fa-user-check text-primaryBlue-500",
      clickEvent: async () => {
        try {
          const connection = userStore.getConnectionRequest(
            props.user.id,
            "received",
          );
          if (!connection) throw "Connection request could not be found";
          userStore.acceptConnectionRequest(connection);
          emit("reload");
        } catch (err: any) {
          error(err.message);
        }
      },
    },
    {
      disabled: false,
      active: userStore.hasReceivedRequest(props.user),
      label: `Decline Connection`,
      border: true,
      type: "button",
      icon: "fa-regular fa-user-times text-primaryBlue-500",
      clickEvent: async () => {
        try {
          const connection = userStore.getConnectionRequest(
            props.user.id,
            "received",
          );
          if (!connection) throw "Connection request could not be found";
          userStore.declineConnectionRequest(connection);
          emit("reload");
        } catch (err: any) {
          error(err.message);
        }
      },
    },
    /**
     * Sent Connection Request
     */
    {
      disabled: false,
      active: userStore.hasSentRequest(props.user),
      label: `Withdraw Connection`,
      border: true,
      type: "button",
      icon: "fa-regular fa-user-times text-primaryBlue-500",
      clickEvent: () => withdrawConnectionRequest(),
    },
    {
      disabled: false,
      active: true,
      label: `Block ${props.user.full_name}`,
      border: true,
      type: "button",
      danger: true,
      icon: "fa-sharp fa-regular fa-shield text-primaryBlue-500",
      clickEvent: async () => {
        try {
          await userStore.blockUser(props.user.id);
          emit("reload");
        } catch (err: any) {
          error(err.message);
        }
      },
    },
  ];
});
const contactsAPI = new API.Contacts();
const favorites = computed(() => contactsStore.favorites || []);
const is_friend = computed(() => userStore.isFriend(props.user));
const toggleFavorite = _debounce(async (user: any) => {
  contactsStore.toggleFavorite(user);
}, 250);

const toggle = (event) => {
  menu.value.toggle(event);
};
const is_favorite = computed(
  () => favorites.value.findIndex((f) => f.id === props.user.id) !== -1,
);
const handleMobileDialog = () => {
  dialogStore.show(DialogType.MOBILE_APP);
  dialogStore.setEvents(DialogType.MOBILE_APP, {
    close: () => dialogStore.hide(DialogType.MOBILE_APP),
  });
};
</script>
<style lang="scss">
#content-slider {
  padding-left: 15px;
  padding-right: 15px;

  .swiper-wrapper {
    padding-bottom: 15px;
  }

  .swiper-slide {
    margin-right: 10px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    cursor: pointer;
    height: 175px;
    min-height: 175px;
    min-width: 175px;
    width: 175px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    align-items: center;
    background: #f7f8fb !important;
    border-radius: 50% !important;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.7);
    height: 30px;
    justify-content: center;
    padding: 10px;
    width: 30px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    color: #39537d !important;
    font-size: 12px !important;
    font-weight: 900 !important;
  }

  .swiper-button-disabled {
    display: none !important;
  }
}
</style>
