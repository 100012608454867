<template>
  <div class="px-[10px] py-[15px] relative">
    <div class="flex flex-col gap-[20px] mb-2">
      <div class="flex gap-[5px] items-center">
        <img
          :src="user.avatar_image"
          class="h-[40px] w-[40px] rounded-full object-cover" />
        <div
          class="bg-primaryBlue-500 border-b-primaryBlue-500 bg-opacity-[7%] rounded-[12px] w-[413px] h-auto max-h-[180px] relative flex items-center scroll-smooth overflow-hidden overscroll-contain">
          <Mentions
            v-model:charCount="charCount"
            v-model="form.comment"
            placeholder="Add a comment"
            class="w-[85%]" />
          <div
            class="flex flex-col gap-2 items-center justify-center p-2 cursor-pointer w-[15%]"
            :class="{
              'pointer-events-none ': charCount > MAX_CHARS,
            }"
            @click="submitComment">
            <CircleIcon
              size="1.8rem"
              class="bg-switcherBlue-500"
              :class="{ 'bg-main-disabled': charCount > MAX_CHARS }">
              <i
                :class="
                  cn('fa-solid text-white text-xs leading-tight', {
                    'fa-spin fa-spinner-third': false,
                    'fa-paper-plane-top': true,
                  })
                "></i>
            </CircleIcon>
            <p
              class="text-xs text-main-disabled"
              :class="{ 'text-red-500': charCount >= MAX_CHARS }">
              {{ charCount }}/{{ MAX_CHARS }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col items-center justify-center h-full min-h-[200px]"
      v-if="data.comments_count == 0">
      <span class="text-lg font-bold">No Comments yet.</span>
      <span class="text-sm">Be the first to comment</span>
    </div>
    <div class="space-y-4 mt-6" v-else>
      <h1 class="text-main-secondary/80">
        Most recent
        <i class="fa-solid fa-arrow-down-wide-short"></i>
      </h1>
      <div
        class="overflow-y-scroll max-h-[500px] space-y-4 scroll-hidden px-[10px] py-[10px]"
        v-infinite-scroll="getComments">
        <div
          class="bg-white p-4 rounded-2xl !rounded-tl-[0.25rem] custom-shadow"
          v-for="comment in comments">
          <div class="flex gap-2 mb-3 relative">
            <div class="absolute top-[-5px] right-[-5px] gap-2 p-0.5 flex">
              <p class="text-xs text-main-secondary">
                {{ usePostCreationTime(comment.created_on) }}
              </p>
              <button
                class="fa fa-ellipsis-vertical text-main-secondary text-xs cursor-pointer"
                @click.stop="(e) => toggle(e, comment)"
                aria-haspopup="true"
                aria-controls="overlay_menu"></button>
            </div>
            <Link
              :href="
                route('user.profile.index', comment?.owner?.public_username)
              ">
              <img
                :src="comment?.owner?.avatar_image"
                class="w-12 aspect-square rounded-full object-cover"
                alt="" />
            </Link>
            <Link
              :href="
                route('user.profile.index', comment?.owner?.public_username)
              "
              class="flex flex-col w-full justify-center cursor-pointer">
              <h1 class="font-semibold cursor-pointer">
                {{ comment?.owner?.full_name }}
              </h1>
              <Roles
                v-if="
                  comment?.owner?.roles && comment?.owner?.roles?.length > 0
                "
                :items="comment.owner?.roles?.map((v) => v.name)"
                :fontSize="'12px'"
                :textColor="'#000'"
                :separatorWidth="'4px'"
                :separatorHeight="'4px'"
                :separatorColor="'#8c8c8c'" />
            </Link>
          </div>
          <div class="rounded-2xl !rounded-tl-[0.25rem] bg-lightBg-500">
            <p class="text-[#212529] text-sm break-words">
              <TruncateTextFeed
                :limit="500"
                :text="comment.comment"
                :mentions="comment.mentions" />
            </p>
          </div>
        </div>
      </div>
      <Menu
        id="overlay_menu"
        :model="ellipsesMenu"
        class="w-[21rem] content_menu overflow-hidden shadow-[0_2px_15px_0_rgba(0,0,0,0.3)]"
        ref="commentMenu"
        :popup="true">
        <template #item="{ item }">
          <div class="" v-if="item.active">
            <a
              class="flex items-center gap-[16px] py-[20px] px-[16px] w-full hover:bg-switcherBlue-500-transparent"
              :class="{ 'border-t border-main-divider': item.border }"
              v-if="item.type == 'external_link'"
              :href="item.href"
              target="_blank">
              <CircleIcon>
                <i :class="item.icon"></i>
              </CircleIcon>

              <div class="flex flex-col gap-[2px] items-start justify-start">
                <p
                  class="mb-0 w-max text-[16px] font-[500] leading-[20px]"
                  :class="{
                    'text-[#ff3b30]': item.type == 'block',
                    ' text-black': item.type !== 'block',
                  }">
                  {{ item?.label }}
                </p>
                <small
                  class="text-[12px] font-[400] leading-[16px] text-main-secondary text-left"
                  v-if="item.description">
                  {{ item?.description }}
                </small>
              </div>
            </a>
            <Link
              v-else-if="item.type === 'inertia_link'"
              :href="item.href"
              class="flex items-center gap-[16px] py-[20px] px-[16px] w-full hover:bg-switcherBlue-500-transparent"
              :class="{ 'border-t border-main-divider': item.border }">
              <CircleIcon>
                <i :class="item.icon"></i>
              </CircleIcon>

              <div class="flex flex-col gap-[2px] items-start justify-start">
                <p
                  class="mb-0 w-max text-[16px] font-[500] leading-[20px]"
                  :class="{
                    'text-[#ff3b30]': item.type == 'block',
                    ' text-black': item.type !== 'block',
                  }">
                  {{ item?.label }}
                </p>
                <small
                  class="text-[12px] font-[400] leading-[16px] text-main-secondary text-left"
                  v-if="item.description">
                  {{ item?.description }}
                </small>
              </div>
            </Link>
            <button
              v-else-if="item.type === 'button'"
              @click="item.clickEvent"
              class="flex items-center gap-[12px] py-[20px] px-[16px] w-full hover:bg-switcherBlue-500-transparent"
              :class="{ 'border-t border-main-divider': item.border }">
              <CircleIcon size="2.5rem">
                <i :class="item.icon"></i>
              </CircleIcon>
              <div class="flex flex-col gap-[2px] items-start justify-start">
                <p
                  class="mb-0 w-max text-[16px] font-[500] leading-[20px]"
                  :class="{
                    'text-[#ff3b30]': item.type == 'block',
                    ' text-black': item.type !== 'block',
                  }">
                  {{ item?.label }}
                </p>
                <small
                  class="text-[12px] font-[400] leading-[16px] text-main-secondary text-left"
                  v-if="item.description">
                  {{ item?.description }}
                </small>
              </div>
            </button>
          </div>
        </template>
      </Menu>
    </div>
    <ConfirmDangerDialog
      class="max-w-[450px]"
      v-if="DeleteCommentDialogVisible"
      @confirm="handleRemoveComment"
      @close="DeleteCommentDialogVisible = false"
      title="Delete comment"
      icon="fa fa-trash"
      action="Yes, delete">
      <div>
        <div
          class="text-main-secondaryBlue text-xs md:text-sm font-normal text-center mt-2.5 leading-5">
          Deleting this comment will permanently remove it, and this action
          cannot be undone. Are you sure you want to do so?
        </div>
      </div>
    </ConfirmDangerDialog>
  </div>
</template>
<script setup lang="ts">
import { vInfiniteScroll } from "@vueuse/components";
import ConfirmDangerDialog from "@/components/dialogs/ConfirmDanger.vue";
import Roles from "@/components/general/Roles.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { usePostCreationTime } from "@/utils/commonFunctions";
import { Link, useForm } from "@inertiajs/vue3";
import Menu from "primevue/menu";
import {
  computed,
  ref,
  reactive,
  onMounted,
  inject,
  watch,
  onUnmounted,
} from "vue";
import { API } from "@/core/api";
import { cn } from "@/utils/cn";
import Mentions from "@/components/mentions/Mentions.vue";
import TruncateTextFeed from "@/pages/social-posts/parts/TruncateTextFeed.vue";
import { useToastr } from "@/composables/useToastr";
import { useWSStore } from "@/store/ws/WebSocket";
import { useDialogStore, DialogType } from "@/store/Dialog";
const props = defineProps({
  data: { type: Object, required: true },
  user: { type: Object, required: true },
});

/* --------------- REACTIVE DATA --------------- */
const wsStore = useWSStore();
const socket = computed(() => wsStore.socket);
const { error } = useToastr();
const commentsAPI = new API.SocialPostComments();
const comments = ref([]);
const MAX_CHARS = 500;
const charCount = ref(0);
const form = useForm({
  comment: "",
});
const lazyLoad = reactive({
  start: props.data.comments.length,
  size: 10,
});
const loading = reactive({
  comments: false,
  send_comment: false,
});

const comments_no_more_results = ref<Boolean>(comments.value.length < 10);
const commentMenu = ref();
const DeleteCommentDialogVisible = ref(false);
const selectedComment = ref<any>(null);
const ellipsesMenu = computed(() => {
  return [
    {
      active: selectedComment?.value?.owner?.id !== props.user.id,
      label: "Report Comment",
      border: false,
      description:
        "This will send a report to an administrator who will then review the comment.",
      type: "button",
      clickEvent: () => {
        useDialogStore().show(
          DialogType.REPORT_CONTENT,
          selectedComment.value.id,
          { type: "comment", data: selectedComment.value.id },
        );
      },
      icon: "fa-sharp fa-regular fa-brake-warning text-primaryBlue-500",
    },
    {
      active:
        props.data.owner.id === props.user.id ||
        selectedComment?.value?.owner?.id === props.user.id,
      label: "Delete comment",
      border: false,
      description:
        "This will permanently delete this comment for everyone, including you.",
      type: "button",
      clickEvent: () => (DeleteCommentDialogVisible.value = true),
      icon: "fa-sharp fa-regular fa-trash text-primaryBlue-500",
    },
  ];
});

/* --------------- FUNCTIONS --------------- */

onMounted(() => {
  comments.value = props.data.comments;
});

const submitComment = async () => {
  if (loading.send_comment) return;
  let response;
  try {
    loading.send_comment = true;
    response = await commentsAPI.add(props.data.id, {
      comment: form.comment,
    });
    if (response.error) throw new Error(response.error);
    if (response.code === "comment_added") {
      form.reset();
    }
  } catch (err: any) {
    console.error(err);
    error(err.message || "An error has occurred!");
  }
  loading.send_comment = false;
};

const handleRemoveComment = async () => {
  try {
    const commentId = selectedComment.value.id;
    const response = await commentsAPI.remove(props.data.id, commentId);

    /* REMOVE DELETED COMMENT FROM PROPS ARRAY */
    if (response.error) throw new Error(response.error);
    if (response.code === "comment_removed") {
      form.reset();
      DeleteCommentDialogVisible.value = false;
    }
  } catch (err: any) {
    console.error(err);
    error(err.message || "An error has occurred!");
  }
};
const toggle = (event, comment) => {
  selectedComment.value = comment;
  commentMenu.value.toggle(event);
};

/* ----- INFINITE SCROLL -----*/

const getComments = async () => {
  if (!loading.comments) {
    loading.comments = true;
    if (!comments_no_more_results.value) {
      const response = await commentsAPI.get(props.data.id, { ...lazyLoad });
      if (response.length) {
        comments.value.push(...response);
      }

      if (response.length < (lazyLoad.size ?? 0)) {
        comments_no_more_results.value = true;
      }
      lazyLoad.start = comments.value.length;

      lazyLoad.size = 10;
      if (loading.comments === true) {
        loading.comments = false;
      }
    }
  }
};
</script>
<style scoped>
.custom-shadow {
  box-shadow: 0 2px 15px #0000001a;
}
</style>
