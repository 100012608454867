<template>
  <InfoCard
    :pt="{
      body: 'p-4',
    }">
    <template #title>
      <div class="flex justify-between items-center">
        <h3 class="text-lg font-medium">General Info</h3>
        <i
          v-if="is_editing && is_own_profile"
          class="fal fa-pencil text-primary-500 cursor-pointer text-base"
          @click="() => (manageGeneralInfoVisible = true)"></i>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col divide-y divider-surface-200">
        <div
          class="flex flex-col gap-2 py-3"
          v-if="user.show_current_occupation">
          <p class="uppercase text-surface-500 text-xs leading-none">
            Current Occupation
          </p>

          <div
            class="flex gap-2 items-center"
            v-if="user.is_student && user.degree_name && user.school">
            <Avatar
              :image="user.school?.image ?? NoCompanyImage"
              shape="circle" />
            <span class="text-xs flex gap-1">
              <b>{{ user.degree_name }}</b>
              at
              <b>{{ user.school?.name }}</b>
            </span>
          </div>
          <div
            v-else-if="
              user.is_unemployed &&
              user.occupation?.company &&
              user.occupation?.role
            "
            class="flex gap-2 items-center">
            <span class="text-sm flex gap-1 font-semibold">Freelancer</span>
          </div>
          <div v-else class="flex gap-2 items-center">
            <Avatar
              :image="user?.occupation?.company?.image ?? NoCompanyImage"
              shape="circle" />
            <span class="text-xs flex gap-1">
              <b>{{ user?.occupation?.role?.name }}</b>
              at
              <b>{{ user?.occupation?.company?.name }}</b>
            </span>
          </div>
        </div>
        <div class="flex flex-col gap-2 py-3" v-if="user.industries.length">
          <p class="uppercase text-surface-500 text-xs leading-none">
            Industries
          </p>
          <Roles
            :key="user?.industries?.join(' ')"
            :items="user?.industries?.map((v) => v.name)"
            :fontSize="'14px'"
            :textColor="'#000'"
            :separatorWidth="'4px'"
            :separatorHeight="'4px'"
            :separatorColor="'#8c8c8c'" />
        </div>
      </div>
    </template>
  </InfoCard>
  <ManageGeneralInfo
    v-bind="props"
    v-if="manageGeneralInfoVisible"
    @close="manageGeneralInfoVisible = false" />
</template>

<script lang="ts" setup>
import {
  reactive,
  ref,
  computed,
  onMounted,
  watch,
  PropType,
  onUpdated,
} from "vue";
import InfoCard from "@/components/general/CustomCard.vue";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import NoCompanyImage from "@/assets/images/new_icons/company_dummy.svg";
import Roles from "@/components/general/Roles.vue";
import ManageGeneralInfo from "@/components/dialogs/bizcard/info/ManageGeneralInfo.vue";
import { useUserBizcardStore } from "@/store/UserBizcard";
/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});
onUpdated(() => {
  console.log("UPDATED :: ", props.user.industries);
});
/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();

/* -------- STATE -------- */
const is_editing = computed(() => bizcardStore.getIsEditing);
const is_own_profile = computed(() => bizcardStore.own_profile);

const manageGeneralInfoVisible = ref(false);
/* -------- METHODS -------- */

/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
