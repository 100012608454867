<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full flex flex-col relative',
      root: '!bg-white max-w-[600px] w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section
      id="header"
      class="p-2 bg-surface-50 border-b-[1px] border-surface-300 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">General Info</h1>
        <CircleIcon
          class="bg-surface-100 text-primary-500 hover:bg-surface-200"
          @click="$emit('close')">
          <i class="far fa-times"></i>
        </CircleIcon>
      </div>
    </section>
    <section
      id="content"
      class="px-6 py-4 flex flex-col gap-4 max-h-[80vh] overflow-y-auto scroll-hidden">
      <div class="flex flex-col gap-1">
        <span class="text-lg font-medium">Current Occupation</span>
        <span class="text-sm font-normal text-main-secondary">
          Where do you currently work and what is your job title? If you are
          currently a student, feel free to write that in here!
        </span>
      </div>
      <div class="flex flex-col gap-2">
        <SelectButton
          v-model="form.occupation_status"
          :options="occupations"
          optionLabel="label"
          optionValue="value"
          :pt="OccupationTabsPreset" />

        <Transition
          v-if="activeComponent && activeComponent.component"
          :enter-active-class="'animate-in slide-in-from-right-full'"
          :leave-active-class="'animate-out slide-out-to-left-full'"
          mode="out-in">
          <component :is="activeComponent.component" v-model="form" />
        </Transition>
      </div>

      <div class="flex flex-col gap-1">
        <span class="text-lg font-medium">Industries</span>
        <span class="text-sm font-normal text-main-secondary">
          Select up to two industries you are associated with. This will help
          others find you on the app.
        </span>
      </div>
      <div class="flex flex-col gap-2">
        <IndustriesSelectGroup
          :selected="form.industries"
          v-for="index in 2"
          :placeholder="`Industry # ${index} ${
            index == 1 ? '*' : '(Optional)'
          }`"
          v-model="form.industries[index - 1]"
          :clearCallback="clearIndustry"
          :key="`industries_${index}`" />
        <ValidationError :errors="form.errors" name="industries" />
      </div>
    </section>
    <section
      id="footer"
      class="bg-surface-50 border-t border-surface-300 sticky bottom-0 z-20 rounded-b-[16px]">
      <div class="flex justify-end items-center py-2.5 px-4 gap-2">
        <Button
          label="Save"
          :disabled="!canSave"
          :loading="form.processing"
          class="min-w-[150px]"
          @click="handleSave"></Button>
      </div>
    </section>
  </Dialog>
</template>

<script lang="ts" setup>
import { router, useForm } from "@inertiajs/vue3";
import { ref } from "vue";
import { markRaw } from "vue";
import { computed } from "vue";
import type { PropType } from "vue";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import { onMounted } from "vue";
import { watch } from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import OccupationTabsPreset from "@/assets/tailwind-preset/selectbutton/occupation-tabs";
import CategoriesPreset from "@/assets/tailwind-preset/selectbutton/categories-multiple";
import Employed from "./components/Employed.vue";
import Student from "./components/Student.vue";
import IndustriesSelectGroup from "@/components/forms/SelectGroups/Industries.vue";
import ValidationError from "@/components/forms/validationError.vue";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";

/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});

const emit = defineEmits(["close"]);

/* -------- COMPOSABLE -------- */
const bizCardInfoAPI = new API.BizcardInfo();
const { success, error } = useToastr();

/* -------- STATE -------- */
const occupations = [
  { label: "Employed", value: "employed", component: markRaw(Employed) },
  { label: "Student", value: "student", component: markRaw(Student) },
  { label: "Freelancer", value: "freelancer" },
  { label: "Don't show", value: "hidden" },
];

const categories = [
  {
    value: "creative",
    icon: "fa fa-lightbulb-on text-xl",
    title: "Creative",
    description:
      "Filmmakers, Writers, Directors, Photographers, Designers, Architects, Culinary Chefs, Florists, Artists, etc.",
  },
  {
    value: "talent",
    icon: "fa fa-stars text-xl",
    title: "Talent",
    description:
      "Performers, Actors, Singers, Athletes, Comedians, Musicians, Dancers, Influencers, etc.",
  },
  {
    value: "professional",
    icon: "fa fa-briefcase text-xl",
    title: "Professional",
    description:
      "Agents, Managers, Entrepreneurs, Event Planners, Marketing, Finance, Real Estate, etc.",
  },
];
const canSave = computed(
  () =>
    form.isDirty &&
    !form.processing &&
    form.industries.length >= 1 &&
    ((form.occupation_status === "student" && form.college && form.degree) ||
      (form.occupation_status === "employed" &&
        form.company &&
        form.role &&
        form.employment_type) ||
      form.occupation_status === "freelancer" ||
      !form.show_current_occupation),
);

const form = useForm<any>({
  occupation_status: occupations.at(0).value,
  categories: props.user.onboarding_fields ?? [],
  degree: props.user.degree_name,
  college: props.user.school,
  company: props.user.occupation?.company,
  role: props.user.occupation?.role,
  employment_type: props.user.occupation?.employment_type,
  is_unemployed: props.user.is_unemployed,
  is_student: props.user.is_student,
  show_current_occupation: props.user.show_current_occupation,
  industries:
    props.user.industries.map((el: any) => ({ id: el.id, name: el.name })) ??
    [],
});
const activeComponent = computed(() => {
  return occupations.find((v) => v.value === form.occupation_status);
});
/* -------- METHODS -------- */
const handleSave = async () => {
  form.processing = true;
  try {
    const response = await bizCardInfoAPI.updateGeneralInfo(form);
    if (response.data) {
      success("General Info updated successfully");
      router.reload({ only: ["user"] });
      console.log(props.user.industries);
      emit("close");
    } else if (response.error) {
      error(response.error);
    } else {
      error("Failed to update general info: Unknown error");
    }
  } catch (e: any) {
    console.error(e);
  } finally {
    form.processing = false;
  }
};

const clearIndustry = (value: any) => {
  form.industries = form.industries.filter((el: any) => el !== value);
};

/* -------- HOOKS -------- */
onMounted(() => {
  if (!form.show_current_occupation) {
    form.occupation_status = "hidden";
  } else if (form.is_student) {
    form.occupation_status = "student";
  } else if (form.is_unemployed) {
    form.occupation_status = "freelancer";
  } else {
    form.occupation_status = "employed";
  }
});
/* -------- WATCHERS -------- */
watch(
  () => form.occupation_status,
  (newVal) => {
    if (newVal === "hidden") {
      form.show_current_occupation = false;
      form.is_student = false;
      form.is_unemployed = false;
    } else if (newVal === "student") {
      form.show_current_occupation = true;
      form.is_student = true;
      form.is_unemployed = false;
    } else if (newVal === "freelancer") {
      form.show_current_occupation = true;
      form.is_student = false;
      form.is_unemployed = true;
    } else {
      form.show_current_occupation = true;
      form.is_student = false;
      form.is_unemployed = false;
    }
  },
);
</script>
