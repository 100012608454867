<template>
  <InfoCard
    v-if="(is_editing && is_own_profile) || card.representations.length">
    <template #title>
      <div class="flex justify-between items-center">
        <h3 class="text-lg font-medium">Representation</h3>
        <i
          v-if="is_editing && is_own_profile"
          class="fal fa-pencil text-primary-500 cursor-pointer text-base"
          @click="() => (manageRepsVisible = true)"></i>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col gap-4 min-w-[400px]">
        <Accordion v-model:activeIndex="activeRepresentation">
          <template #collapseicon>
            <i
              class="fal fa-chevron-up text-primary-500 text-lg absolute right-4 top-6"></i>
          </template>
          <template #expandicon>
            <i
              class="fal fa-chevron-down text-primary-500 text-lg absolute right-4 top-6"></i>
          </template>
          <AccordionTab
            v-for="(value, index) in representations"
            :key="value.title"
            :headerClass="
              cn('flex flex-col gap-2 bg-surface-100 p-4', {
                'rounded-md': activeRepresentation != index,
                'rounded-t-md': activeRepresentation == index,
              })
            "
            :contentClass="
              cn('flex flex-col gap-2 bg-surface-100 p-4', {
                'rounded-b-md': activeRepresentation == index,
              })
            ">
            <template #header>
              <div class="flex gap-2 items-center">
                <Avatar
                  :image="
                    value.contact_information.company.image ?? NoCompanyImage
                  "
                  @error="handleCompanyError"
                  class="!size-10 self-start"
                  shape="circle" />
                <div class="flex flex-col">
                  <p class="uppercase text-surface-500 text-xs leading-none">
                    {{ value.title }}
                  </p>
                  <div class="text-sm font-medium leading-tight">
                    {{ value.contact_information.company.name }}
                  </div>
                  <div
                    v-if="activeRepresentation == index"
                    class="flex flex-col gap-0.5 border-l-2 border-l-surface-300 pl-2 text-sm">
                    <a v-if="value.contact_information.website">
                      {{ value.contact_information.website }}
                    </a>
                    <a v-if="value.contact_information.phone">
                      {{ value.contact_information.phone }}
                    </a>
                    <a v-if="value.contact_information.email">
                      {{ value.contact_information.email }}
                    </a>
                    <a v-if="value.contact_information.location">
                      {{ value.contact_information.location }}
                    </a>
                  </div>
                </div>
              </div>
            </template>
            <div
              v-if="value.representatives.length"
              class="flex flex-col gap-2">
              <div
                class="bg-primary-100 rounded w-full px-2 py-1 text-xs text-primary-500">
                {{ value.representatives.length }} Representative{{
                  value.representatives.length > 1 ? "s" : ""
                }}
              </div>
              <div
                class="flex flex-col gap-1"
                v-for="person in value.representatives">
                <div class="text-sm font-medium leading-tight">
                  {{ person.first_name }}
                </div>
                <div
                  v-if="activeRepresentation == index"
                  class="flex flex-col gap-0.5 border-l-2 border-l-surface-300 pl-2 text-sm">
                  <a v-if="person.phone">
                    {{ person.phone }}
                  </a>
                  <a v-if="person.email">
                    {{ person.email }}
                  </a>
                  <a v-if="person.location">
                    {{ person.location }}
                  </a>
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    </template>
  </InfoCard>
  <ManageRepresentations
    :reps="representations"
    v-if="manageRepsVisible"
    @close="() => (manageRepsVisible = false)" />
</template>

<script lang="ts" setup>
import type { PropType } from "vue";
import InfoCard from "@/components/general/CustomCard.vue";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import NoCompanyImage from "@/assets/images/new_icons/company_dummy.svg";
import { ref } from "vue";
import { cn } from "@/utils/cn";
import ManageRepresentations from "@/components/dialogs/bizcard/info/ManageRepresentations.vue";
import { computed } from "vue";
import { useUserBizcardStore } from "@/store/UserBizcard";
/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});

/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();

/* -------- STATE -------- */
const activeRepresentation = ref();
const manageRepsVisible = ref(false);
const is_editing = computed(() => bizcardStore.getIsEditing);
const is_own_profile = computed(() => bizcardStore.own_profile);

const representations = computed(() =>
  props.card.representations.map((rep) => ({
    ...rep,
    contact_information: {
      ...rep.contact_information,
      company:
        typeof rep.contact_information.company === "object"
          ? {
              ...rep.contact_information.company,
            }
          : {
              brandId: null,
              name: rep.company_name,
              image: rep.contact_information.image || NoCompanyImage,
            },
    },
  })),
);

/* -------- METHODS -------- */
const handleCompanyError = (event: any) => {
  console.log("[DEBUG] :: CompanyImageError :: ", event);
  event.target.src = NoCompanyImage;
};
/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
