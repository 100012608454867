<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'w-full flex flex-col relative gap-4',
      root: 'bg-lightBg-400 max-w-[600px] max-h-screen w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="mainDialogVisible"
    modal
    :closable="false">
    <section id="header" class="rounded-t-[16px] space-y-4">
      <div
        class="py-2 rounded-t-2xl bg-lightBg-500 flex justify-between items-center border-b border-main-divider">
        <h1 class="font-poppins text-xl flex-1 text-center">
          Report {{ user?.full_name }}
        </h1>
        <CircleIcon
          class="bg-lightBg-500 mr-2"
          @click="dialogStore.hide(DialogType.REPORT_USER)">
          <i class="fa fa-x text-primary"></i>
        </CircleIcon>
      </div>

      <div class="flex flex-col gap-2 items-center justify-center">
        <CircleIcon size="4rem">
          <i class="fa-regular fa-brake-warning text-primary text-4xl"></i>
        </CircleIcon>
        <h1 class="font-medium">Report {{ user?.full_name }}</h1>
        <p class="text-sm text-primary/80 text-center max-w-[75%]">
          Help us maintain a positive community by reporting users who violate
          our guidelines or engage in harmful behavior.
        </p>
      </div>
    </section>
    <section
      id="content"
      class="p-6 flex-1 overflow-y-auto scroll-hidden my-2 max-h-[60vh]">
      <h1 class="font-medium">Why are you reporting {{ user?.full_name }}?</h1>
      <!-- REASONS -->
      <div class="flex flex-col gap-2">
        <div
          v-for="type in REASONS"
          :key="type.value"
          class="py-4"
          :class="{
            'border-b border-main-divider': type.value !== REASONS.at(-1).value,
          }">
          <div class="flex w-full">
            <RadioButton
              v-model="form.type"
              :inputId="type.value"
              name="dynamic"
              :value="type.value" />
            <div class="flex flex-col gap-1 ml-2 w-full">
              <label :for="type.value" class="font-semibold">
                {{ type.title }}
              </label>

              <p v-if="type.description" class="text-sm text-primary/80">
                {{ type.description }}
              </p>
              <Textarea
                v-if="type.hasOwnProperty('model')"
                v-model="form[type.model]"
                :disabled="form.type !== 'other'"
                placeholder="Please specify"
                class="!w-full hover:!border-[#3578F8] border-[2px] resize-none text-[#000bf] border-[#8F9FB8] p-[10px] rounded-[5px] focus:outline-[#3578F8] placeholder:text-[#8F9FB8] mx-auto focus:!ring-0 focus:!outline-0"
                rows="3"></Textarea>
            </div>
          </div>
        </div>
      </div>
      <!-- Additional information -->
      <div class="space-y-2">
        <h1 class="font-medium">
          Additional information
          <span class="text-red-500">*</span>
        </h1>
        <p class="text-sm text-primary/80">
          Please provide more context or specifics about your report. This helps
          our team understand and address the issue better.
        </p>
        <Textarea
          v-model="form.additional_information"
          placeholder="Type here"
          class="!w-full hover:!border-[#3578F8] border-[2px] resize-none text-[#000bf] border-[#8F9FB8] p-[10px] rounded-[5px] focus:outline-[#3578F8] placeholder:text-[#8F9FB8] mx-auto focus:!ring-0 focus:!outline-0"
          rows="3"></Textarea>
      </div>
      <!-- YOUR PRIVACY STATEMENT -->
      <div class="mt-2">
        <h1 class="font-semibold">Your privacy</h1>
        <p class="text-sm text-primary/80">
          Your report is confidential. {{ user?.full_name }} will not be
          informed about who reported them.
        </p>
      </div>
    </section>
    <section
      id="footer"
      class="mb-2 border-t border-main-divider flex justify-end items-center p-2 rounded-b-[16px] gap-2 w-full">
      <button
        class="rounded !text-primary text-sm font-medium min-h-[40px] py-1.5 !px-12 text-center disabled:bg-[#bfbfbf] border border-primary bg-transparent w-1/4"
        @click="dialogStore.hide(DialogType.REPORT_USER)">
        Cancel
      </button>
      <button
        :disabled="isSubmitDisabled"
        class="mr-4 rounded text-white text-sm font-medium min-h-[40px] py-1.5 !px-12 text-center disabled:bg-[#bfbfbf] bg-primary w-1/4"
        @click="handleSubmit">
        Submit
      </button>
    </section>
  </Dialog>
  <UserReportSubmitted v-if="UserReportSubmittedVisible" @close="handleClose" />
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { useDialogStore, DialogType } from "@/store/Dialog";
import { useForm } from "@inertiajs/vue3";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";
import UserReportSubmitted from "./UserReportSubmitted.vue";
const { success, error } = useToastr();
const reportsApi = new API.Reports();
const dialogStore = useDialogStore();
const UserReportSubmittedVisible = ref(false);
const mainDialogVisible = ref(true);
const user = computed(() => dialogStore.getData(DialogType.REPORT_USER));
const form = useForm({
  reportable_type: "user",
  reportable_id: user.value?.id,
  type: null,
  other_description: null,
  additional_information: null,
});
const isSubmitDisabled = computed(
  () =>
    !form.type ||
    (form.type === "other" && !!!form.other_description) ||
    !form.additional_information,
);
const REASONS = [
  {
    title: "Spam or Misleading Activities",
    description: "This user posts spam or tries to deceive others.",
    value: "spam_or_misleading_activities",
  },
  {
    title: "Hate Speech or Discrimination",
    description: "This user promotes hate or discriminates against a group.",
    value: "hate_speech_or_discrimination",
  },
  {
    title: "Harassment or Bullying",
    description: "I'm being harassed or bullied by this user.",
    value: "harassment_or_bullying",
  },
  {
    title: "Inappropriate Content",
    description: "This user shares sexually explicit or violent content.",
    value: "inappropriate_content",
  },
  {
    title: "Impersonation",
    description: "This user is pretending to be someone they're not.",
    value: "impersonation",
  },
  {
    title: "Other",
    value: "other",
    model: "other_description",
  },
];
const handleSubmit = async () => {
  try {
    const response = await reportsApi.report(form);
    if (response.error) throw new Error(response.error);
    mainDialogVisible.value = false;
    UserReportSubmittedVisible.value = true;
  } catch (err) {
    console.log(err);
    error(err.message);
  }
};

const handleClose = () => {
  dialogStore.hide(DialogType.REPORT_USER);
};
</script>
