<template>
  <component
    :pt="{ content: '!p-0' }"
    position="full"
    :ptOptions="{ mergeProps: true }"
    v-model:visible="isOpen"
    :is="width < 768 ? Sidebar : 'aside'"
    :class="{
      'transition-all hidden md:block w-[350px] lg:w-[400px] min-h-[calc(100vh-200px)] xl:min-h-[calc(100vh-56px)] bg-white fixed top-[56px] left-0 bottom-0 border-r-[1px] border-surface-150':
        width >= 768,
    }">
    <div
      id="wrapper"
      class="w-full text-black space-y-2.5 flex flex-col h-full">
      <section
        id="header"
        class="flex justify-between items-center px-5 py-2.5">
        <div class="flex gap-4">
          <i
            class="p-1.5 fa-light fa-rectangle-history text-main-secondary rounded-full cursor-pointer"
            :class="{
              ' bg-primaryBlue-500 text-white': layout === 'full',
            }"
            @click="exploreStore.setLayout('full')"></i>
          <i
            class="p-1.5 fa-light fa-grid-2 text-main-secondary rounded-full cursor-pointer"
            :class="{
              ' bg-primaryBlue-500 text-white': layout === 'compact',
            }"
            @click="exploreStore.setLayout('compact')"></i>
        </div>
        <p class="text-xs" v-if="count">Users found: {{ count }}</p>
      </section>
      <section id="content" class="flex-1 overflow-y-auto px-5 scroll-hidden">
        <InputText
          v-model="form.keyword"
          class="w-full my-2 focus:!ring-0"
          placeholder="Search by name or keyword" />
        <Accordion
          v-model:activeIndex="activeIdx"
          :pt="{
            root: '!mb-0 border-y divide-y border-main-divider divide-main-divider ',
          }"
          :ptOptions="{ mergeProps: true }"
          :multiple="true">
          <AccordionTab id="relation">
            <template #header>
              <AccordionHeader
                title="Relation"
                icon="fal fa-user-group"
                :active="activeIdx.includes(1)"
                :clear="form.relation !== 'any'"
                @clear="form.relation = 'any'" />
            </template>
            <SelectButton
              v-model="form.relation"
              :options="RELATION_OPTIONS"
              optionValue="value"
              optionLabel="label"
              aria-labelledby="basic"
              :pt="relationPreset" />
          </AccordionTab>

          <AccordionTab id="location">
            <template #header>
              <AccordionHeader
                title="Location"
                icon="fal fa-location-dot"
                :active="activeIdx.includes(2)"
                :clear="form.location !== 'any' && form.locations.length > 0"
                @clear="
                  () => {
                    form.locations = [];
                  }
                " />
            </template>
            <div class="flex flex-col gap-2">
              <SelectGroup
                v-model="form.locations"
                SelectType="locations"
                placeholder="Add a location" />
            </div>
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <AccordionHeader
                title="Role"
                icon="fal fa-user"
                :active="activeIdx.includes(3)"
                @clear="form.roles = []"
                :clear="form.roles.length > 0" />
            </template>
            <SelectGroup
              v-model="form.roles"
              SelectType="roles"
              placeholder="Add a role" />
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <AccordionHeader
                title="Industry"
                icon="fal fa-industry-windows"
                :active="activeIdx.includes(4)"
                @clear="form.industries = []"
                :clear="form.industries.length > 0" />
            </template>
            <SelectGroup
              v-model="form.industries"
              SelectType="industries"
              placeholder="Add an industry" />
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <AccordionHeader
                title="Skills"
                icon="fal fa-lightbulb-on"
                :active="activeIdx.includes(5)"
                :clear="form.skills.length > 0"
                @clear="form.skills = []" />
            </template>
            <SelectGroup
              v-model="form.skills"
              SelectType="skills"
              placeholder="Add a professional skill" />
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <AccordionHeader
                title="Services"
                icon="fal fa-screwdriver-wrench"
                :active="activeIdx.includes(6)"
                :clear="form.services.length > 0"
                @clear="form.services = []" />
            </template>
            <SelectGroup
              v-model="form.services"
              SelectType="services"
              placeholder="Add a service" />
          </AccordionTab>
          <AccordionTab id="workplace">
            <template #header>
              <AccordionHeader
                title="Workplace"
                icon="fal fa-computer"
                :active="activeIdx.includes(7)"
                :clear="form.workplace !== 'any'" />
            </template>
            <SelectButton
              v-model="form.workplace"
              :options="WORKPLACE_OPTIONS"
              optionValue="value"
              optionLabel="label"
              aria-labelledby="basic"
              :pt="relationPreset" />
          </AccordionTab>
          <AccordionTab id="employment_type">
            <template #header>
              <AccordionHeader
                title="Employment Type"
                icon="fal fa-clock"
                :active="activeIdx.includes(8)"
                :clear="form.employment_types.length > 0"
                @clear="form.employment_types = []" />
            </template>
            <SelectButton
              multiple
              v-model="form.employment_types"
              :options="EMPLOYMENT_OPTIONS"
              optionValue="value"
              optionLabel="label"
              aria-labelledby="basic"
              :pt="relationPreset" />
          </AccordionTab>
        </Accordion>
      </section>
      <section
        id="footer"
        class="border-[1px] border-surface-200 flex justify-end items-center p-2 gap-2 sticky bottom-0 z-50 bg-white">
        <Button
          v-if="form.isDirty"
          label="Clear All"
          :disabled="!form.isDirty || loading"
          class="basis-1/2 text-center py-2 px-4 bg-switcherBlue-500"
          @click="form.reset()"></Button>
        <Button
          class="basis-1/2 ms-auto text-center py-2 px-4 disabled:bg-[#d6d6d7] bg-switcherBlue-500"
          :disabled="!canSearch"
          label="Show results"
          :loading="loading"
          @click="handleShowResults"></Button>
      </section>
    </div>
  </component>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch, inject } from "vue";
import { useExploreStore } from "@/store/Explore";
import { useForm } from "@inertiajs/vue3";
import AccordionHeader from "./AccordionHeader.vue";
import servicesOptionsPreset from "@/assets/tailwind-preset/selectbutton/services";
import { cn } from "@/utils/cn";
import SelectGroup from "@/components/explore/MultiSelect.vue";
import Sidebar from "primevue/sidebar";
import { useWindowSize } from "@vueuse/core";
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  count: {
    type: Number,
    default: null,
  },
});
const emit = defineEmits(["search"]);
const { width } = useWindowSize();
const isOpen = inject("isOpen");
const activeIdx = ref([]);
const exploreStore = useExploreStore();
const layout = computed(() => exploreStore.layout ?? "full");
const form = useForm({
  keyword: "",
  relation: "any",
  locations: [],
  roles: [],
  industries: [],
  skills: [],
  services: [],
  workplace: "any",
  employment_types: [],
});
const default_values = {
  keyword: "",
  relation: "any",
  location: "any",
  locations: [],
  roles: [],
  industries: [],
  skills: [],
  services: [],
  workplace: "any",
  employment_types: [],
};
const canSearch = computed(() => {
  return JSON.stringify(oldSearch.value) !== JSON.stringify(form.data());
});
const oldSearch = ref(JSON.parse(JSON.stringify(form.data())));

/* ----- PRESETS ----- */
const relationPreset = computed(() => ({
  ...servicesOptionsPreset,
  button: (options: any) => ({
    class: cn(
      servicesOptionsPreset.button(options).class,
      "px-1.5 py-2.5 flex-1  leading-[10px] min-w-fit",
    ),
  }),
  root: (options: any) => ({
    class: cn(
      servicesOptionsPreset.root(options).class,
      "gap-[0.3rem] box-border",
    ),
  }),
}));

/* ----- OPTIONS ----- */
const RELATION_OPTIONS = [
  { value: "any", label: "Any" },
  { value: "in-network", label: "In network" },
  { value: "out-of-network", label: "Out of network" },
];
const LOCATION_OPTIONS = [
  { value: "any", label: "Any" },
  { value: "location", label: "Location" },
  { value: "distance", label: "Distance" },
];
const WORKPLACE_OPTIONS = [
  { value: "any", label: "Any" },
  { value: "on-site", label: "On-site" },
  { value: "remote", label: "Remote" },
  { value: "hybrid", label: "Hybrid" },
];
const EMPLOYMENT_OPTIONS = [
  { value: "any", label: "Any" },
  { value: "full-time", label: "Full-time" },
  { value: "part-time", label: "Part-time" },
  { value: "freelance", label: "Freelance" },
  { value: "contract", label: "Contract" },
  { value: "seasonal", label: "Seasonal" },
  { value: "internship", label: "Internship" },
  { value: "apprenticeship", label: "Apprenticeship" },
];

const handleShowResults = () => {
  if (JSON.stringify(default_values) === JSON.stringify(form.data())) {
    emit("search", []);
  } else {
    emit("search", form.data());
  }
  oldSearch.value = JSON.parse(JSON.stringify(form.data()));
};
</script>
