<template>
  <div class="flex flex-col gap-4">
    <div
      class="sticky top-0 p-4 bg-title w-full z-10 shadow-md flex flex-col gap-2">
      <p
        class="uppercase text-xs text-surface-400/95"
        v-if="collaborators.length">
        <strong>{{ model.length }}/{{ max }}</strong>
        role{{ model.length > 1 || model.length == 0 ? "s" : "" }}
        selected
      </p>
      <IconField iconPosition="left" class="relative">
        <i v-if="loading" class="fa fa-spinner-third fa-spin !text-white"></i>
        <i v-else class="far fa-magnifying-glass !text-surface-400"></i>
        <InputText
          @input="handleSearch"
          v-model="lazyLoad.keyword"
          placeholder="Search a role by name"
          class="bg-white/10 w-full focus:!outline-none focus:!border-0 focus:!ring-0 hidden md:block transition-all duration-150 ease-in-out text-white" />
      </IconField>
    </div>
    <div class="space-y-x px-4">
      <i
        class="fa fa-circle-arrow-left fa-xl text-white cursor-pointer"
        @click="handleBack"
        v-if="rolesList.length"></i>
      <div class="h-[calc(70vh-50px)] overflow-y-auto scroll-hidden">
        <div class="mt-2">
          <AddItem
            :value="lazyLoad.keyword"
            :disabled="user.roles.length >= max"
            :callback="handleAddCustomRole"
            v-if="!lazyLoad.hasData && lazyLoad.keyword !== null && !loading" />
        </div>
        <InfiniteScroll :callback="getRolesData" v-if="rolesList.length">
          <div
            class="flex justify-between items-center mt-2 hover:bg-surface-500/50 cursor-pointer rounded-md"
            :key="item.id"
            :for="`roles_${item.id}`"
            v-for="item in rolesList">
            <label
              class="text-sm text-white flex-1 py-2 cursor-pointer"
              :for="`roles_${item.id}`">
              {{ item.name }}
            </label>

            <Checkbox
              v-model="model"
              :value="item.id"
              :inputId="`roles_${item.id}`"
              :outlined="true"
              color="lightBlue"
              size="lg"
              :disabled="isCheckboxDisabled(item).value"
              @change="handleToggleRole" />
          </div>
        </InfiniteScroll>
        <div
          v-else-if="loading && !rolesList.length && lazyLoad.hasData"
          class="px-2.5 pb-2.5 flex items-center justify-center">
          <Skeleton
            width="full"
            height="4rem"
            v-for="i in 3"
            :key="i"></Skeleton>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import type { RolesInterface, UserInterface } from "@/core/interfaces";
import _debounce from "lodash/debounce";
import { API } from "@/core/api";
import AddItem from "@/components/general/AddItem.vue";
import { vInfiniteScroll } from "@vueuse/components";
import _ from "lodash";
import { useAuthStore } from "@/store/Auth";
import InfiniteScroll from "@/components/infinite-scroll/InfiniteScroll.vue";
const props = defineProps({
  collaborators: {
    type: Array as () => UserInterface[],
    required: true,
  },
  modelValue: {
    type: Array as () => number[],
    required: true,
  },
});

const emit = defineEmits(["back"]);
const authStore = useAuthStore();
const rolesAPI = new API.ClientRoles();
const model: any = defineModel({ required: true });
const max = ref(3);

const user = defineModel("user", {
  type: Object as () => UserInterface,
  required: true,
});
const is_owner = computed(() => authStore.getUser.id === user.value.id);
const isCheckboxDisabled = (item) => {
  if (is_owner.value) {
    return computed(
      () =>
        user.value.roles.length >= max.value &&
        user.value.roles.findIndex((i) => i.id === item.id) === -1,
    );
  } else {
    return computed(
      () =>
        (user.value.roles.length >= max.value &&
          user.value.roles.findIndex((i) => i.id === item.id) === -1) ||
        (user.value.roles.length === 1 &&
          user.value.roles.findIndex((i) => i.id === item.id) !== -1),
    );
  }
};
const rolesList = ref<any>([]);
const lazyLoad = reactive<any>({
  start: 0,
  size: 25,
  keyword: null,
  initialValues: [],
  hasData: true,
});
const loading = ref(false);

onMounted(() => {
  console.log(user.value.roles);
  lazyLoad.start = 0;
  lazyLoad.size = 25;
  lazyLoad.initialValues = user.value.roles.map((role: RolesInterface) => ({
    id: role.id,
    name: role.name,
  }));
  lazyLoad.hasData = true;
  getRolesData();
});

const RolesSearch = () => {
  lazyLoad.start = 0;
  rolesList.value = [];
  loading.value = false;
  getRolesData();
};

const getRolesData = async () => {
  console.log("[DEBUG] :: getRolesData");
  loading.value = true;
  let response = await rolesAPI.get(lazyLoad);
  response = response.data;
  rolesList.value.push(...response);
  lazyLoad.hasData = response.length >= lazyLoad.size;
  lazyLoad.start = rolesList.value.length;
  loading.value = false;
};

const handleToggleRole = (e: any) => {
  const role = rolesList.value.find(
    (v: RolesInterface) => v.id == e.target.value,
  );
  if (!role || !user.value) return; // idk if this is correct, i guess so
  if (e.target.checked) {
    user.value.roles.push(role);
  } else {
    user.value.roles = user.value?.roles.filter((v: any) => v.id !== role.id);
  }
};

const handleAddCustomRole = () => {
  if (!user.value) return;
  if (user.value.roles.length >= max.value) return;
  const roleId = -model.value.length;
  const role: RolesInterface = {
    id: roleId,
    name: lazyLoad.keyword,
  };
  user.value.roles.push(role);
  model.value.push(roleId);
  lazyLoad.initialValues.push(role);
  lazyLoad.keyword = null;
  getRolesData();
};

const handleSearch = _debounce(() => {
  console.log("[DEBUG] :: handleSearch");
  RolesSearch();
}, 250);

const handleBack = () => {
  emit("back");
};
</script>
