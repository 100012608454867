<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-2 size-full flex-1 flex flex-col relative',
      root: ' !bg-main-primary max-w-[650px] min-h-[600px]  max-h-[90vh] w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section
      id="header"
      class="p-2 !bg-main-primary text-white border-b-[1px] border-surface-500 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">
          Manage collaborations
        </h1>
        <CircleIcon class="bg-main-secondary" @click="$emit('close')">
          <i class="fa fa-x"></i>
        </CircleIcon>
      </div>
    </section>
    <section
      id="content"
      class="p-4 flex-1 overflow-y-auto scroll-hidden max-h-[80vh] flex flex-col gap-2"
      :class="{
        'flex items-center justify-center':
          collaborations.length === 0 || loading,
      }">
      <div
        v-if="collaborations.length === 0 && !loading"
        class="text-main-disabled max-w-[75%] text-center">
        You currently have no collaborations that can be added to your
        portfolio.
      </div>
      <div
        v-else-if="collaborations.length !== 0 && !loading"
        class="space-y-4">
        <CollaborationCard
          v-for="collab in collaborations.filter((c) => c?.show_on_portfolio)"
          :key="collab.id"
          v-model="collab.show_on_portfolio"
          :collaboration="collab" />
        <div v-if="collaborations.filter((c) => !c?.show_on_portfolio)">
          <div class="mt-8 mb-4 space-y-2 text-white">
            <p>HIDDEN</p>
            <hr class="border-main-divider/70" />
          </div>
          <div class="space-y-4">
            <CollaborationCard
              v-for="collab in collaborations.filter(
                (c) => !c?.show_on_portfolio,
              )"
              :key="collab.id"
              v-model="collab.show_on_portfolio"
              :collaboration="collab" />
          </div>
        </div>
      </div>
      <div v-if="loading">
        <i
          class="fa-sharp-duotone fa-solid fa-spinner-third fa-spin text-3xl text-white"></i>
      </div>
    </section>
    <section
      v-if="collaborations.length"
      id="footer"
      class="!bg-main-primary sticky bottom-0 z-20 rounded-b-[16px]">
      <div class="flex justify-end items-center py-2.5 px-4 gap-2">
        <Button
          :loading="updating"
          label="Save"
          class="border-[1px] border-white text-white rounded text-xs w-[180px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent hover:bg-switcherBlue-500 disabled:hover:border-[1px] hover:border-0 disabled:border-main-disabled disabled:text-main-disabled"
          @click="handleSave"></Button>
      </div>
    </section>
    <div
      v-if="updating"
      id="overlay"
      class="absolute inset-0 bg-black/30 z-50"></div>
  </Dialog>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import CollaborationCard from "@/components/bizcard/portfolio/CollaborationCard.vue";
import { useUserBizcardStore } from "@/store/UserBizcard";
import RemoveCollabConfirm from "./RemoveCollabConfirm.vue";
import { FeedType } from "@/store/UserBizcard";
const userBizcard = useUserBizcardStore();
const emit = defineEmits(["close"]);

const loading = computed(() => userBizcard.CollaborationContent.loading);
const updating = computed(() => userBizcard.CollaborationContent.updating);
const collaborations = computed(() => userBizcard.CollaborationContent.data);

const handleSave = async () => {
  await userBizcard.massUpdateCollaborations();
  userBizcard.resetFeed(FeedType.CONTENT);
  await userBizcard.loadFeed(userBizcard.user.id, FeedType.CONTENT);
  emit("close");
};

onMounted(() => {
  userBizcard.setCollaborationContent();
});
</script>
