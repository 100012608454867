<template>
  <div
    :class="
      cn(
        'w-full rounded-lg shadow-default relative flex flex-col overflow-hidden cursor-pointer',
        props.class
      )
    "
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
    @mousemove="onMouseMove"
    @touchstart="onTouchStart"
    @touchend="onTouchEnd"
    @touchmove="onTouchMove"
    @click="onClick"
  >
    <slot></slot>
    <div class="absolute top-0 left-0 w-full h-full">
      <InfoBallon>
        <template #icon>
          <i class="fal fa-link"></i>
        </template>
        <template #count>{{ count }}/{{ max }}</template>
      </InfoBallon>
    </div>
    <div
      class="bg-lightBg-500 flex flex-col gap-2 !items-start p-4 rounded-b-lg border-t border-[#434343] max-h-[150px]"
    >
      <p class="text-start text-xs uppercase text-slate-500 leading-3 line-clamp-1">
        {{ url }}
      </p>
      <h2 class="text-start text-black font-medium leading-tight line-clamp-1">
        {{ title }}
      </h2>
      <p class="text-start text-sm text-zinc-600 text-left line-clamp-3">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import InfoBallon from "../parts/InfoBallon.vue";
import { cn } from "@/utils/cn";

const props = defineProps({
  count: {
    type: String,
    required: true,
  },
  max: {
    type: String,
    required: false,
    default: "2",
  },
  url: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: false,
    default: "",
  },
  text: {
    type: String,
    required: false,
    default: "",
  },
  class: {
    type: String || Object,
    required: false,
    default: "",
  },
});
const emit = defineEmits(["click"]);

const isDragging = ref(false);

const onMouseDown = () => {
  isDragging.value = false;
};

const onMouseMove = () => {
  isDragging.value = true;
};

const onMouseUp = () => {
  if (!isDragging.value) {
    emit("click", props.url);
  }
};

const onTouchStart = () => {
  isDragging.value = false;
};

const onTouchMove = () => {
  isDragging.value = true;
};

const onTouchEnd = () => {
  if (!isDragging.value) {
    emit("click", props.url);
  }
};

const onClick = (event: Event) => {
  if (isDragging.value) {
    event.preventDefault(); // Prevent default click behavior if dragging
    isDragging.value = false; // Reset dragging status
  }
};
</script>

<style scoped>
/* No additional styles needed as Tailwind CSS classes handle the hover effect */
</style>
