<template>
  <InfoCard customClass="!w-full">
    <template #title>
      <div class="flex justify-between items-center">
        <h3 class="text-lg font-medium">Bio</h3>
        <i
          v-if="is_editing && is_own_profile"
          class="fal fa-pencil text-primary-500 cursor-pointer text-base"
          @click="manageBioVisible = true"></i>
      </div>
    </template>
    <template #content>
      <div
        class="flex gap-7 py-4 transition-all"
        :class="{ 'items-center': toggleReadmore }">
        <div
          class="aspect-[2/2.5] bg-center bg-cover rounded-2xl w-[200px] max-h-[250px] min-w-[200px] transition-all"
          :style="'background-image: url(' + user.avatar_image + ')'"></div>
        <div
          ref="clamped"
          class="text-sm text-surface-500 leading-normal transition-all"
          :class="{
            'line-clamp-none': toggleReadmore,
            'line-clamp-5': !toggleReadmore,
          }">
          <span ref="full">{{ card.about }}</span>
        </div>
      </div>
      <div
        v-if="showReadMore"
        class="text-blue-400 cursor-pointer w-full text-center"
        @click="toggleReadmore = !toggleReadmore">
        Read {{ toggleReadmore ? "less" : "more" }} ...
      </div>
    </template>
  </InfoCard>
  <ManageBio
    :about="card.about"
    v-if="manageBioVisible"
    @close="manageBioVisible = false" />
</template>

<script lang="ts" setup>
import { reactive, ref, computed, onMounted, watch, PropType } from "vue";
import InfoCard from "@/components/general/CustomCard.vue";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import ManageBio from "@/components/dialogs/bizcard/info/ManageBio.vue";
import { useUserBizcardStore } from "@/store/UserBizcard";

/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});
/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();
/* -------- STATE -------- */
const manageBioVisible = ref(false);
const is_editing = computed(() => bizcardStore.getIsEditing);
const is_own_profile = computed(() => bizcardStore.own_profile);
const toggleReadmore = ref(false);
const clamped = ref();
const full = ref();
const showReadMore = ref(false);
/* -------- METHODS -------- */

/* -------- HOOKS -------- */
onMounted(() => {
  if (clamped.value && clamped.value.offsetHeight < full.value.offsetHeight) {
    showReadMore.value = true;
  }
});
/* -------- WATCHERS -------- */
</script>
