<template>
  <div class="">
    <div
      class="flex items-center overflow-hidden flex-nowrap shrink-0"
      ref="full">
      <div
        class="flex items-center flex-nowrap shrink-0"
        v-for="item in visibleItems"
        :key="item">
        <p :style="{ fontSize: fontSize, color: textColor }">{{ item }}</p>
        <span
          v-if="item !== items.at(-1)"
          class="separator"
          :style="{
            backgroundColor: separatorColor,
            height: separatorHeight,
            width: separatorWidth,
          }"></span>
      </div>
      <p v-if="clampedCount" :style="{ fontSize: fontSize, color: textColor }">
        +{{ clampedCount }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onUpdated, watch, onUnmounted } from "vue";
const clamped = ref();
const full = ref();
/* ---------- REACTIVE DATA ----------*/
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  fontSize: {
    type: String,
    default: "inherit",
  },
  textColor: {
    type: String,
    default: "white",
  },
  separatorColor: {
    type: String,
    default: "white",
  },
  separatorHeight: {
    type: String,
    default: "3px",
  },
  separatorWidth: {
    type: String,
    default: "3px",
  },
});

const visibleItems = ref([...props.items]);
const clampedCount = ref(0);
const previousWidth = ref(null);
/* ---------- FUNCTIONS ----------*/
const computeItemsSize = () => {
  visibleItems.value = [...props.items];

  if (full.value.offsetWidth !== full.value.scrollWidth) {
    visibleItems.value.pop();
    console.log(visibleItems.value);
    clampedCount.value = props.items.length - visibleItems.value.length;
  } else {
    clampedCount.value = 0;
  }
};
onMounted(() => {
  computeItemsSize();
  window.addEventListener("resize", computeItemsSize);
});
onUnmounted(() => {
  window.removeEventListener("resize", computeItemsSize);
});
</script>

<style scoped lang="scss">
.container {
  position: relative;
  overflow: hidden;
}

.overflow-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
}

.item {
  white-space: nowrap;
  overflow: hidden;

  &:last-of-type {
    & + .separator {
      display: none;
    }
  }
}

.plus-one-plus-two {
  white-space: nowrap;
  overflow: hidden;
}

.separator {
  margin: auto 5px;
  /* Adjust margin as needed */
  border-radius: 50%;
  display: inline-block;
}

.hidden {
  position: absolute;
  visibility: hidden;
}
li::marker {
  color: var(--marker-color);
  margin: 0 16px;
}
</style>
