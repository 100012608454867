<template>
  <div
    class="max-w-[1000px] mx-auto w-full flex flex-col items-center gap-[30px] px-0 max-[640px]:px-3 mt-[30px]"
  >
    <h1 class="w-full text-start text-xl font-bold mb-2">
      {{ content.title }}
    </h1>
    <p
      v-if="content.description"
      ref="clamped"
      class="w-full text-sm line-clamp-3 whitespace-pre-line"
      :class="{ 'line-clamp-none': ReadmoreToggle }"
    >
      <span v-text="content.description" ref="full"></span>
    </p>
    <span
      v-if="Readmore"
      class="text-blue-400 cursor-pointer"
      @click="ReadmoreToggle = !ReadmoreToggle"
    >
      Read {{ ReadmoreToggle ? "less" : "more" }} ...
    </span>
    <hr class="bg-main-divider h-[1px] w-full" />
    <section
      v-if="content.content_type === 'video_gallery'"
      id="videos"
      class="flex gap-8 items-start flex-wrap w-full"
    >
      <div
        v-for="(child, index) in content.children"
        :key="child.id"
        class="aspect-video max-w-[470px] relative flex flex-col gap-5 w-full sm:w-[calc(50%-16px)]"
        @click="() => handleSelectVideo(index)"
      >
        <div class="relative group cursor-pointer w-full">
          <InfoBallon class="!py-0.5">
            <template #icon>
              <i class="fal fa-clapperboard-play text-md"></i>
            </template>
            <template #count>
              {{ child?.external_url || child?.external_type }}
            </template>
          </InfoBallon>
          <img
            :src="
              child.image_kit_id_16_9 ??
              child.thumbnail_image_kit_id ??
              child.image_kit_ids?.[0] ??
              child.image_kit_id
            "
            :alt="child.title"
            class="w-full object-cover object-center rounded-lg aspect-video"
          />
          <div
            class="bg-transparent group-hover:bg-black/40 flex transition-all absolute inset-0 justify-center items-center rounded-lg group"
          >
            <i class="fa fa-play text-white text-5xl group-hover:block hidden"></i>
          </div>
        </div>
        <div class="">
          <h1
            class="font-semibold text-base text-black m-0 overflow-hidden text-ellipsis whitespace-nowrap"
          >
            {{ child.title }}
          </h1>
          <p
            class="font-normal text-sm text-[#4a4a4b] m-0 overflow-hidden text-ellipsis whitespace-nowrap"
          >
            {{ child.description }}
          </p>
        </div>
      </div>
    </section>
    <div v-else class="w-full">
      <div class="aspect-video relative cursor-pointer rounded-[16px] overflow-hidden">
        <div class="relative w-full h-full">
          <div
            v-if="!isThumbnailClicked"
            class="w-full relative group"
            @click="isThumbnailClicked = true"
          >
            <img
              class="w-full aspect-video"
              :src="
                content.image_kit_id_16_9 ??
                content.thumbnail_image_kit_id ??
                content.image_kit_ids?.[0] ??
                content.image_kit_id
              "
              alt=""
            />
            <div
              class="absolute inset-0 group-hover:opacity-100 opacity-0 transition-opacity flex items-center justify-center bg-black/30"
            >
              <i class="fa fa-play text-white text-4xl"></i>
            </div>
          </div>
          <iframe
            v-else
            autoplay
            width="100%"
            class="z-50 w-full h-full"
            :src="`${content.embed}?autoplay=1`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen=""
            __idm_id__="122881"
          ></iframe>
        </div>
      </div>
    </div>

    <div class="flex justify-between w-full gap-[40px] flex-wrap">
      <section
        v-if="showProjectDetails || content.tags.length"
        class="space-y-4 w-full lg:max-w-[530px]"
      >
        <ProjectDetails v-if="showProjectDetails" :content="content" />
        <Tags v-if="content.tags.length" :tags="content.tags" />
      </section>
      <section
        id="right"
        class="flex flex-col gap-4 w-full"
        :class="{
          'lg:max-w-[400px]': showProjectDetails || content.tags.length,
        }"
      >
        <Collaborators
          v-if="collaborators.length"
          :collaborators="collaborators"
          :ownerId="content.bizcard.user.id"
        />
        <Publisher
          :published="content.created_on"
          :publisher="content.bizcard.user"
          :full="!showProjectDetails && !content.tags.length"
        />
      </section>
    </div>
  </div>
  <VideoGalleryDialog
    v-if="VideoGalleryDialogVisible"
    :content="content"
    :selected="selectedVid"
    @close="VideoGalleryDialogVisible = false"
  />
</template>
<script setup lang="ts">
import VideoGalleryDialog from "@/components/dialogs/PostContent/VideoGallery.vue";
import InfoBallon from "@/components/parts/InfoBallon.vue";
import type { UserInterface } from "@/core/interfaces";
import { computed, inject, onMounted, ref } from "vue";
import Collaborators from "../parts/Collaborators.vue";
import ProjectDetails from "../parts/ProjectDetails.vue";
import Publisher from "../parts/Publisher.vue";
import Tags from "../parts/Tags.vue";

/* ---------- REACTIVE DATA ---------- */
const props = defineProps({ content: { required: true, type: Object } });
const collaborators = inject("collaborators") as UserInterface[];
const clamped = ref();
const full = ref();
const Readmore = ref(false);
const ReadmoreToggle = ref(false);
const isThumbnailClicked = ref(false);
const VideoGalleryDialogVisible = ref(false);
const selectedVid = ref();
const showProjectDetails = computed(
  () =>
    !!props.content.project_types?.length ||
    !!props.content.organizations?.length ||
    props.content.completion_year
);

/* ---------- FUNCTIONS ---------- */
onMounted(() => {
  if (clamped.value?.offsetHeight < full.value?.offsetHeight) {
    Readmore.value = true;
  }
});
const handleSelectVideo = (idx: number) => {
  selectedVid.value = idx;
  VideoGalleryDialogVisible.value = true;
};
</script>
