<template>
  <div class="w-[100%] xxl:w-[80%] m-auto flex flex-col gap-4">
    <div class="flex flex-col gap-0.5 mt-6">
      <h1 class="m-0 mb-2 text-black text-2xl font-medium px-2 text-center">
        Welcome to
        <em>bizly</em>
        , {{ user.first_name }}!
      </h1>
      <p
        class="m-0 mb-2 text-sm text-text-700 font-normal tracking-[.5px] text-center w-[80%] mx-auto"
      >
        Whether you're joining the waitlist, or creating your account, we'd love to learn
        a bit about you as we welcome you into our community.
      </p>
    </div>
    <TextSeparator :text="'User Info'" classNames="py-2" />

    <div class="space-y-6">
      <section class="flex flex-col gap-2">
        <div class="section-question-group w-full">
          <h5 class="mb-1 question text-[#000] font-normal text-sm">
            What best describes the reason you are here?
          </h5>
        </div>
        <div class="flex flex-wrap gap-2 mb-3 w-full">
          <button
            v-for="(option, index) in Interests"
            :key="index"
            :class="
              cn(
                'rounded-lg border-[1px] border-disabled-500 box-border w-[49%] lg:w-[48.8%] flex gap-2 items-center p-3 text-left text-[1rem] hover:border-switcherBlue-500 hover:text-switcherBlue-500 text-text-700',
                {
                  'text-switcherBlue-500 border-switcherBlue-100 hover:border-switcherBlue-100 bg-switcherBlue-100 hover:text-switcherBlue-500':
                    form.interests.indexOf(option.value) !== -1,
                }
              )
            "
            @click="SelectInterest(option.value)"
          >
            <i :class="cn('fas', option.icon)"></i>
            <span
              :class="
                cn('text-sm w-100 leading-4  text-inherit', {
                  'text-switcherBlue-500': form.interests.indexOf(option.value) !== -1,
                })
              "
            >
              {{ option.text }}
            </span>
          </button>
        </div>
        <ValidationError :errors="form.errors" name="interests" />
      </section>
      <section id="roles">
        <div class="w-full flex flex-col gap-2">
          <h5 class="text-[#000] font-normal text-sm">
            List up to three roles that you partake in:
          </h5>
          <div class="flex flex-col gap-2">
            <RolesSelectGroup
              :selected="form.roles"
              v-for="index in 3"
              :placeholder="`Role # ${index} ${index == 1 ? '*' : '(Optional)'}`"
              v-model="form.roles[index - 1]"
              :clearCallback="clearRole"
              :key="`roles_${index}`"
            />
            <ValidationError :errors="form.errors" name="roles" />
          </div>
        </div>
      </section>
      <section id="industries">
        <div class="section-question-group w-full flex flex-col gap-2">
          <h5 class="text-[#000] font-normal text-sm">
            What industries do you associate with most?
          </h5>
          <div class="flex flex-col gap-2">
            <IndustriesSelectGroup
              :selected="form.industries"
              v-for="index in 2"
              :placeholder="`Industry # ${index} ${index == 1 ? '*' : '(Optional)'}`"
              v-model="form.industries[index - 1]"
              :clearCallback="clearIndustry"
              :key="`industries_${index}`"
            />
            <ValidationError :errors="form.errors" name="industries" />
          </div>
        </div>
      </section>
      <section id="location">
        <div class="section-question-group w-full flex flex-col gap-2">
          <h5 class="text-[#000] font-normal text-sm">Where are you based?</h5>
          <LocationSelect v-model="form.location" />
          <ValidationError :errors="form.errors" name="location" />
        </div>
      </section>
    </div>

    <div class="flex justify-center items-center">
      <Button
        label="Done"
        @click="submit"
        class="!w-[25%] !bg-switcherBlue-500 focus:!ring-0"
      ></Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import TextSeparator from "@/components/general/TextSeparator.vue";
import RolesSelectGroup from "@/components/forms/SelectGroups/Roles.vue";
import IndustriesSelectGroup from "@/components/forms/SelectGroups/Industries.vue";
import LocationSelect from "@/components/forms/SelectGroups/Location.vue";
import ValidationError from "@/components/forms/validationError.vue";
import { useForm } from "@inertiajs/vue3";
import { Category_tabs, Interests } from "../menus";
import { computed } from "vue";
import { useToastr } from "@/composables/useToastr";
import { cn } from "@/utils/cn";
import _debounce from "lodash/debounce";
import { route } from "ziggy-js";
import type { UserInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
const authStore = useAuthStore();
const user = computed(() => authStore.getUser as UserInterface);

const props = defineProps(["roles", "industries", "nextCallback"]);
const { warn } = useToastr();

const form = useForm({
  interests: user?.value?.onboarding_intention ?? [],
  roles: user?.value?.roles?.map((el) => ({ id: el.id, name: el.name })) ?? [],
  onboarding_field: user?.value?.onboarding_field ?? Category_tabs.at(0).value,
  industries:
    user?.value?.industries?.map((el: any) => ({ id: el.id, name: el.name })) ?? [],
  location: user?.value?.location || undefined,
  referralCode: null,
});

const SelectInterest = (option: any) => {
  let idx = form.interests.indexOf(option);
  if (idx !== -1) {
    form.interests.splice(idx, 1);
  } else {
    form.interests.push(option);
  }
};
const submit = () => {
  form
    .transform((data) => ({
      ...data,
      industries: data.industries.filter((industry) => industry !== null),
      roles: data.roles.filter((role) => role !== null),
    }))
    .post(route("onboarding.general_info.submit"), {
      onSuccess: (res) => {
        if (res?.props?.flash_data?.error == "") props.nextCallback();
      },
      onError: () => {
        //console.log(errors);
        warn("Some fields are missing!");
      },
    });
};

const clearRole = (value: any) => {
  form.roles = form.roles.filter((el: any) => el !== value);
};
const clearIndustry = (value: any) => {
  form.industries = form.industries.filter((el: any) => el !== value);
};
</script>
