<template>
  <div
    class="flex justify-between flex-col h-full bg-[white] rounded-[8px] mt-[10px] mb-[20px] lg:mt-[56px]">
    <div class="pt-[16px] px-[16px] w-full">
      <div class="flex flex-col w-full">
        <span class="text-[24px] font-[600] text-[#1C2B33] mb-[10px]">
          Account & Preferences
        </span>
        <span class="text-[#1C2B33] text-[18px] font-[500]">Subscription</span>
        <p class="text-[14px] text-main-secondaryBlue mt-[7px]">
          Since
          <span class="font-bold italic">bizly</span>
          is in beta, we currently do not offer any subscriptions. Please check
          back often for the latest features and updates on when our
          subscriptions will become available.
        </p>
        <span class="text-[#141414] text-[18px] font-[500] mt-[3px]">
          Opt in to bizly's Marketing Materials
        </span>
        <p class="text-sm text-main-secondaryBlue mt-[9px]">
          By opting in to bizly's marketing materials, you'll have the chance to
          learn about new features and updates before anyone else. Stay ahead of
          the curve.
        </p>
        <div
          class="bg-[#F7F8FB] py-[10px] h-[56px] px-[18px] text-[#141414] text-sm font-normal mb-[20px] mt-[10px] flex items-center justify-between rounded-[8px]">
          <span class="text-main-secondaryBlue font-[400] text-[14px]">
            {{
              form.opt_in
                ? "You are currently opted in."
                : "I would like to opt in."
            }}
          </span>
          <label class="relative inline-flex items-center cursor-pointer">
            <input
              v-model="form.opt_in"
              type="checkbox"
              value=""
              class="sr-only peer"
              :checked="form.opt_in"
              @change="onChange" />
            <div
              class="w-[36px] h-[20px] bg-[#EFF1F5] rounded-full peer-focus:ring-none peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-[#34C759]"></div>
          </label>
        </div>
        <div class="mb-2">
          <label for="auto_collaborations">Auto collaborations</label>
          <Dropdown
            id="auto_collaborations"
            v-model="form.auto_collaborations"
            :options="autoCollaborations_options"
            placeholder="Please select an option"
            optionLabel="name"
            optionValue="value"
            class="w-full mb-1" />
          <p v-if="errors.auto_collaborations" class="text-red-500 text-xs">
            Auto collaboration field is required
          </p>
        </div>
      </div>
    </div>

    <div
      class="border-t-[1px] border-t-[#CDD5E1] mt-[0px] flex items-center justify-between py-[16px] px-[16px]">
      <button
        class="text-[#E51111] bg-[#F9C3C3] font-normal text-sm px-[15px] py-[12px] rounded-md hover:!bg-[red] hover:!text-white w-[166px]"
        @click="isDeleteDialogVisible = !isDeleteDialogVisible">
        Delete account
      </button>
      <Button
        :onClick="handleSaveAccount"
        class="text-[14px] text-white w-[166px] py-[12px] rounded-[8px] !border-0 bg-switcherBlue-500 hover:bg-[#1375c7] hover:text-white cursor-pointer disabled:bg-[#BFBFBF]"
        :disabled="!form.isDirty"
        :loading="form.processing">
        Save
      </Button>
    </div>
  </div>
  <ConfirmDangerDialog
    v-if="isDeleteDialogVisible"
    class="max-w-[570px] bg-white"
    title="Are you sure you want to delete your account?"
    icon="fa fa-trash"
    action="Delete Account"
    @close="isDeleteDialogVisible = false"
    @confirm="handleDeleteAccount">
    <div>
      <div
        class="text-main-secondaryBlue text-xs md:text-sm font-normal text-center mt-2.5 leading-5">
        This action cannot be undone and all your data will be lost. If you're
        sure,
        <br />
        click 'Delete Account'. Otherwise, click 'Cancel' to keep your account.
      </div>
    </div>
  </ConfirmDangerDialog>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import ConfirmDangerDialog from "@/components/dialogs/ConfirmDanger.vue";
import { router, useForm } from "@inertiajs/vue3";
const props = defineProps(["user"]);
const autoCollaborations_options = [
  { name: "Manual", value: "manual" },
  { name: "Show", value: "show" },
  { name: "Hide", value: "hide" },
];
const form = useForm({
  opt_in: props.user?.marketing_opt_in || false,
  auto_collaborations: props.user?.marketing_opt_in || null,
});
const errors = reactive({});
const isDeleteDialogVisible = ref(false);
const handleSaveAccount = () => {
  if (!form.auto_collaborations) {
    return (errors.auto_collaborations = true);
  }
  form.post(route("settings.account_and_preferences"));
};

const handleDeleteAccount = () => {
  router.post(
    route("settings.delete_account"),
    {},
    {
      onSuccess: () => {
        isDeleteDialogVisible.value = false;
      },
    },
  );
};
</script>
