<template>
  <Layout
    :header="{
      title: user.full_name,
      subtitle: user.roles.map((v) => v.name),
      user: user.id === authStore.getUser.id ? authStore.getUser : user,
      card,
    }"
  >
    <template #subheaderActions>
      <CircleIcon
        v-for="action in ACTIONS.filter((action) => action.show)"
        :key="action.icon"
        :class="
          cn('bg-main-secondary hover:bg-main-secondary/80 text-white', action.class)
        "
        @click="action.clickEvent"
        size="2.5rem"
      >
        <i :class="action.icon"></i>
      </CircleIcon>
      <CircleIcon
        v-if="!owned_profile"
        class="bg-main-secondary hover:bg-main-secondary/80 text-white"
        size="2.5rem"
      >
        <i class="fa fa-ellipsis-vertical"></i>
      </CircleIcon>
    </template>
    <div
      v-if="isEditing"
      class="bg-[#EFF1F5] flex items-center justify-center gap-2 py-2.5 px-1 text-sm text-main-secondaryBlue mb-4"
    >
      <i class="fa fa-circle-exclamation"></i>
      <p>
        Use this page to clearly list your preferences, skills, and services. This will
        help others identify and connect with you more easily!
      </p>
    </div>
    <section
      id="content"
      class="flex flex-wrap w-full lg:flex-nowrap gap-6 max-w-[1100px] mx-auto p-8 min-h-screen lg:px-0"
    >
      <section id="left" class="flex flex-col gap-6 flex-1 lg:basis-[65%]">
        <Services v-bind="props" v-if="card.services.length" />
        <AddServiceCard v-else-if="isEditing" />
      </section>
      <section id="right" class="flex flex-col gap-6 flex-1 lg:basis-[35%]">
        <component v-for="item in right" :is="item.component" v-bind="props" />
      </section>
    </section>
  </Layout>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, type PropType } from "vue";
import { cn } from "@/utils/cn";
import { type UserInterface, type BizCardInterface } from "@/core/interfaces";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { useToastr } from "@/composables/useToastr";
import { route } from "ziggy-js";
import { markRaw } from "vue";
import Layout from "@/components/bizcard/home/Layout.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import ContentDialog from "@/components/dialogs/content/Main.vue";
import Availability from "./components/Availability.vue";
import Locations from "./components/Locations.vue";
import Opportunities from "./components/Opportunities.vue";
import Workplace from "./components/Workplace.vue";
import ProfessionalSkills from "./components/ProfessionalSkills.vue";
import General from "./components/General.vue";
import Services from "./components/Services.vue";
import AddServiceCard from "./components/AddServiceCard.vue";
import { useAuthStore } from "@/store/Auth";
import { DialogType, useDialogStore } from "@/store/Dialog";
/* ---------- PROPS ----------*/
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});
/* ---------- COMPOSABLES ----------*/
const { success, error } = useToastr();
const bizcardStore = useUserBizcardStore();
const authStore = useAuthStore();
const dialogStore = useDialogStore();
/* ---------- STATE ----------*/
const isEditing = computed(() => bizcardStore.getIsEditing);
const owned_profile = computed(() => bizcardStore.own_profile);

const isGeneralInfoPopulated = computed(
  () =>
    !isEditing.value &&
    (props.card.services_locations.length ||
      props.card.opportunity_settings.length ||
      props.card.workplace_settings[0]),
);
const right = computed(() =>
  [
    {
      active: isGeneralInfoPopulated.value,
      component: markRaw(General),
    },
    {
      active: isEditing.value,
      component: markRaw(Availability),
    },
    {
      active: isEditing.value,
      component: markRaw(Locations),
    },
    {
      active: isEditing.value,
      component: markRaw(Opportunities),
    },
    {
      active: isEditing.value,
      component: markRaw(Workplace),
    },
    {
      active: isEditing.value || props.card.professional_skills.length,
      component: markRaw(ProfessionalSkills),
    },
  ].filter((i) => i.active),
);

/* ----- Menus ----- */
const ACTIONS = computed(() => [
  {
    icon: "fa-regular fa-circle-plus",
    show: isEditing.value,
    clickEvent: () => {

    dialogStore.show(DialogType.UPLOAD_CONTENT);
      dialogStore.setEvents(DialogType.UPLOAD_CONTENT, {
        close: () => {
          dialogStore.hide(DialogType.UPLOAD_CONTENT);
        },
      });

    },
  },
  {
    icon: "fa-regular fa-pen",
    class: isEditing.value
      ? "bg-switcherBlue-500 hover:bg-switcherBlue-600"
      : "",
    show: owned_profile.value,
    clickEvent: bizcardStore.toggleEditing,
  },
  {
    icon: "fa-regular fa-share",
    show: true,
    clickEvent: async () => {
      const permissionStatus = await navigator.permissions.query({
        name: "clipboard-write",
      });
      if (
        permissionStatus.state === "granted" ||
        permissionStatus.state === "prompt"
      ) {
        const url = route("user.profile.index", props.user?.public_username); // TODO route for services
        await navigator.clipboard.writeText(url);
        success(`Copied to clipboard: ${url}`);
      }
    },
  },
]);

const ContentDialogVisible = ref(false);

/* ---------- HOOKS ----------*/
onMounted(() => {
  bizcardStore.init(props.card, props.user);
});
</script>
