<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full flex flex-col relative',
      root: ' !bg-main-primary max-w-[800px] max-h-[90vh] w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section
      id="header"
      class="p-2 !bg-main-primary text-white border-b-[1px] border-surface-500 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">
          Manage Portfolio
        </h1>
        <CircleIcon class="bg-main-secondary" @click="$emit('close')">
          <i class="fa fa-x"></i>
        </CircleIcon>
      </div>
    </section>
    <section
      id="subheader"
      class="py-1 bg-main-lightBg flex gap-3 items-center justify-center text-main-disabled">
      <i class="fa-solid fa-layer-group"></i>
      <p>Click to edit or drag to rearrange.</p>
    </section>
    <section
      id="content"
      class="flex-1 overflow-y-auto scroll-hidden max-h-[80vh] flex flex-col gap-2">
      <InfiniteScroll :callback="getData">
        <div class="relative">
          <div
            class="absolute inset-0 z-50 bg-black/30"
            v-if="dragging && dragging !== 'featured'"></div>
          <div
            class="p-2 !bg-main-primary text-white border-b-[1px] border-surface-500 sticky top-0 z-20 rounded-t-[16px]">
            <h1 class="font-poppins text-lg flex-1 text-center">Featured</h1>
          </div>
          <draggable
            v-if="!loading"
            id="featured"
            @drag="dragging = 'featured'"
            @end="dragging = null"
            class="grid grid-cols-4 gap-[2px] p-5 overflow-visible"
            item-key="id"
            :component-data="{
              tag: 'div',
              type: 'transition-group',
              name: !dragging ? 'flip-list' : null,
            }"
            v-model="form.featured"
            v-bind="dragOptions">
            <template #item="{ element, index }">
              <div class="relative cursor-pointer overflow-visible">
                <div
                  class="relative h-full w-full transition ease-in-out overflow-visible"
                  :class="{
                    'hover:scale-[1.15] hover:z-10 hover:shadow-[0_0_25px_0px_rgba(0,0,0,0.3)]':
                      !dragging,
                  }">
                  <i
                    v-if="element.bizcard.user.id !== auth_user.id"
                    v-tooltip.bottom="{
                      escape: false,
                      value: getTooltipContent(element.bizcard.user),
                    }"
                    class="fa fa-user text-white absolute bottom-2 left-2 z-50 size-8 flex items-center justify-center bg-black/70 rounded-full text-sm leading-0"></i>

                  <img
                    alt="gallery"
                    class="size-[188px] relative object-cover"
                    :src="getImage(element)"
                    @error="handleImageError" />
                  <button
                    @click="() => ToggleFeatured(element)"
                    class="absolute z-[0.5] top-[8px] left-[8px] bg-[black] bg-opacity-25 flex gap-[5px] px-[10px] py-[5px] rounded-full items-center justify-center">
                    <i
                      class="fa-star size-4"
                      :class="{
                        'fa-solid': element.is_featured,
                        'fa-regular': !element.is_featured,
                      }"></i>
                    <span class="text-white text-[12px]">{{ index + 1 }}</span>
                  </button>
                  <div
                    class="absolute z-[0.5] top-[8px] right-[8px] bg-[black] bg-opacity-25 flex gap-[5px] rounded-full items-center justify-center"
                    :class="
                      element?.external_url ? 'px-[10px] py-[5px]' : 'p-[6px]'
                    ">
                    <i
                      :class="`size-4 ${
                        post_type_icons[element.content_type]
                      }`" />
                    <span
                      class="text-white text-[12px]"
                      v-if="element.content_type === 'video'">
                      {{ element?.external_url }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </draggable>
          <div class="grid grid-cols-4 gap-[2px] p-5 text-white" v-else>
            <Skeleton
              size="188px"
              class="mr-2"
              v-for="i in 4"
              :key="i"></Skeleton>
          </div>
        </div>
        <div class="relative">
          <div
            class="p-2 !bg-main-primary text-white border-b-[1px] border-surface-500 sticky top-0 z-20 rounded-t-[16px]">
            <h1 class="font-poppins text-lg flex-1 text-center">
              Non-Featured
            </h1>
          </div>
          <div
            v-if="loading && !form.unfeatured.length && !form.featured.length"
            class="grid grid-cols-4 gap-[2px] p-5">
            <Skeleton
              v-for="i in 4"
              :key="i"
              size="188px"
              class="mr-2"></Skeleton>
          </div>
          <div
            class="absolute inset-0 z-50 bg-black/30"
            v-if="dragging && dragging !== 'non-featured'"></div>
          <draggable
            v-if="form.unfeatured.length"
            id="non-featured"
            v-model="form.unfeatured"
            class="grid grid-cols-4 gap-[2px] p-5"
            item-key="id"
            :component-data="{
              tag: 'div',
              type: 'transition-group',
              name: !dragging ? 'flip-list' : null,
            }"
            v-bind="dragOptions"
            @drag="dragging = 'non-featured'"
            @end="dragging = null">
            <template #item="{ element, index }">
              <div class="relative cursor-pointer">
                <div
                  class="relative h-full w-full transition ease-in-out"
                  :class="{
                    'hover:scale-[1.15] hover:z-10 hover:shadow-[0_0_25px_0px_rgba(0,0,0,0.3)]':
                      !dragging,
                  }">
                  <i
                    v-if="element.bizcard.user.id !== auth_user.id"
                    v-tooltip.bottom="{
                      escape: false,
                      value: getTooltipContent(element.bizcard.user),
                    }"
                    class="fa fa-user text-white absolute bottom-2 left-2 z-50 size-8 flex items-center justify-center bg-black/70 rounded-full text-sm leading-0"></i>
                  <img
                    alt="gallery"
                    class="size-[188px] relative object-cover"
                    :src="getImage(element)"
                    @error="handleImageError" />
                  <button
                    @click="() => ToggleFeatured(element)"
                    class="absolute z-[0.5] top-[8px] left-[8px] bg-[black] bg-opacity-25 flex gap-[5px] px-[10px] py-[5px] rounded-full items-center justify-center">
                    <i
                      class="fa-star size-4"
                      :class="{
                        'fa-solid': element.is_featured,
                        'fa-regular': !element.is_featured,
                      }"></i>
                    <span class="text-white text-[12px]">{{ index + 1 }}</span>
                  </button>

                  <div
                    class="absolute z-[0.5] top-[8px] right-[8px] bg-[black] bg-opacity-25 flex gap-[5px] rounded-full items-center justify-center"
                    :class="
                      element?.external_url ? 'px-[10px] py-[5px]' : 'p-[6px]'
                    ">
                    <i
                      :class="`size-4 ${
                        post_type_icons[element.content_type]
                      }`" />
                    <span
                      class="text-white text-[12px]"
                      v-if="element.content_type === 'video'">
                      {{ element?.external_url }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </InfiniteScroll>
    </section>
    <section
      v-if="form.isDirty"
      id="footer"
      class="!bg-main-primary sticky bottom-0 z-20 rounded-b-[16px]">
      <div class="flex justify-end items-center py-2.5 px-4 gap-2">
        <Button
          @click="handleSave"
          label="Save"
          :disabled="!form.isDirty"
          :loading="form.loading"
          class="border-[1px] border-white text-white rounded text-xs w-[180px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent hover:bg-switcherBlue-500 disabled:hover:border-[1px] hover:border-0 disabled:border-main-disabled disabled:text-main-disabled"></Button>
      </div>
    </section>
  </Dialog>
</template>
<script setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import draggable from "vuedraggable";
import InfiniteScroll from "@/components/infinite-scroll/InfiniteScroll.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { API } from "@/core/api";
import { useAuthStore } from "@/store/Auth";
import { router, useForm } from "@inertiajs/vue3";
import { useToastr } from "@/composables/useToastr";
import { useUserBizcardStore } from "@/store/UserBizcard";
const { success, error } = useToastr();
const op = ref();
const emit = defineEmits(["close"]);
const authStore = useAuthStore();
const auth_user = computed(() => authStore.getUser);
const MAX_FEATURED = 5;
const lazyload = ref({
  start: 0,
  size: 15,
  loading: false,
  isMoreData: true,
});
const form = useForm({
  featured: [],
  unfeatured: [],
});
const loading = ref(false);
const dragging = ref(false);
const dragOptions = computed(() => ({
  animation: 200,
  group: "description",
  disabled: false,
}));

const disabledObjects = ref();
const BizcardAPI = new API.Bizcard();
const post_type_icons = {
  image_gallery: "fa fa-images",
  image: "fa fa-image",
  video: "fa fa-clapperboard-play",
  link: "fa fa-chain",
  link_gallery: "fa fa-chain",
  text: "fa fa-text",
  video_gallery: "fa fa-clapperboard-play",
  collection: "fa fa-rectangle-history",
};

const handleImageError = (event) => {
  event.target.src =
    "https://storage.googleapis.com/demo-api.bizly.net/static/app-templates/web-bg-min.jpg";
};
const ToggleFeatured = (content) => {
  if (form.featured.length >= MAX_FEATURED && content.is_featured === false) {
    return error("Feature maximum reached");
  }
  if (content.is_featured) {
    const idx = form.featured.findIndex((c) => c.id === content.id);
    if (idx !== -1) {
      const temp = form.featured.splice(idx, 1)[0];
      temp.is_featured = false;
      form.unfeatured.push(temp);
    }
  } else {
    const idx = form.unfeatured.findIndex((c) => c.id === content.id);
    if (idx !== -1) {
      const temp = form.unfeatured.splice(idx, 1)[0];
      temp.is_featured = true;
      form.featured.push(temp);
    }
  }
};

const handleSave = async () => {
  try {
    loading.value = true;
    let response = await BizcardAPI.updateManageContents(form);
    if (response.error) throw new Error("Something went wrong!");
    useUserBizcardStore().massUpdateFeatured(form);
    success(response.success);
    emit("close");
  } catch (err) {
    console.error(err);
    error(err.message);
  } finally {
    loading.value = false;
  }
};
const getData = async () => {
  try {
    if (!loading.value && lazyload.value.isMoreData) {
      loading.value = true;
      let response = await BizcardAPI.getManageContents(lazyload.value);
      form.featured.push(...response.data.filter((i) => i.is_featured));
      form.unfeatured.push(...response.data.filter((i) => !i.is_featured));
      lazyload.value.start += response.data.length;
      if (response.data.length < lazyload.value.size)
        lazyload.value.isMoreData = false;
      loading.value = false;
    }
  } catch (error) {
    console.error(error);
    loading.value = false;
  }
};

const getImage = (content) => {
  switch (content.content_type) {
    case "video":
      return content.image_kit_id_16_9;
    default:
      return (
        content.image_kit_id ||
        content.thumbnail_image_kit_id ||
        content.image_kit_ids?.[0] ||
        "https://storage.googleapis.com/demo-api.bizly.net/static/app-templates/web-bg-min.jpg"
      );
  }
};
onMounted(() => {
  getData();
});

const getTooltipContent = (user) => {
  return `<div class='flex items-center gap-2'><img class='size-8 rounded-full' src=${user.avatar_image}></img><p class='text-white font-semibold '>${user.full_name}</p></div>`;
};
</script>
<style scoped lang="scss">
i {
  color: white;
}
.flip-list-move {
  transition: transform 0.5s;
}

.ImageContainer {
  cursor: pointer;

  .imageContainerInner {
    height: 100%;
    width: 100%;
    transition: transform 0.2s ease-out;
  }

  .editDeleteDiv {
    opacity: 0;
    transition: transform 0.2s ease-out;
  }

  &:hover {
    .imageContainerInner {
      transform: scale(1.2);
      z-index: 2;
      transform-origin: center;
      box-shadow: 0px 0px 25px 0px #000000cc;
    }

    .editDeleteDiv {
      display: flex;
      opacity: 1;
    }
  }

  &:is(.disabled-class) {
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: #00000087;
      z-index: 2;
    }

    .imageContainerInner {
      transform: none;
      z-index: 0;
      box-shadow: none;
    }

    &:hover {
      cursor: not-allowed;

      .imageContainerInner {
        transform: none;
        z-index: 0;
        box-shadow: none;
      }

      .editDeleteDiv {
        display: none;
        opacity: 0;
      }
    }
  }
}
</style>
